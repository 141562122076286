function calculateAge(birthDateString) {
  const today = new Date();
  const birthDate = new Date(birthDateString);
  let years = today.getFullYear() - birthDate.getFullYear();
  let months = today.getMonth() - birthDate.getMonth();
  let days = today.getDate() - birthDate.getDate();

  if (days < 0) {
    months--;
    const lastMonth = new Date(today.getFullYear(), today.getMonth(), 0);
    days += lastMonth.getDate();
  }

  if (months < 0) {
    years--;
    months += 12;
  }

  return `${pluralize(years, 'Ano', 'Anos')} ${pluralize(months, 'mês', 'meses')} e ${pluralize(days, 'dia', 'dias')}`;
};

function pluralize(count, singular, plural) {
  return `${count} ${count === 1 ? singular : plural}`;
};

function formatDate(dateString) {
  if (!dateString) return 'Data inválida';
  const date = new Date(dateString);
  if (isNaN(date)) return 'Data inválida';
  const day = date.getDate().toString().padStart(2, '0');
  const month = (date.getMonth() + 1).toString().padStart(2, '0');
  const year = date.getFullYear();
  return `${day}/${month}/${year}`;
};

function formatTime(timeString) {
  if (!timeString) return 'Hora inválida';
  const timeParts = timeString.split(':');
  if (timeParts.length < 2) return 'Hora inválida';
  const [hour, minute] = timeParts;
  return `${hour.padStart(2, '0')}:${minute.padStart(2, '0')}`;
};

function formatGender(gender) {
  switch (gender) {
    case 'female':
      return 'Feminino';
    case 'male':
      return 'Masculino';
    case 'others':
      return 'Outros';
    default:
      return 'Não especificado';
  }
};

function formatPersonType(person_type) {
  switch (person_type) {
    case 'user':
      return 'Usuário';
    case 'nutritionist':
      return 'Nutricionista';
    case 'doctor':
      return 'Médico';
    case 'employee':
      return 'Colaborador';
    case 'supplier':
      return 'Fornecedor';
    case 'professional':
      return 'Profissional';
    case 'partner':
      return 'Parceiro';
    case 'owner':
      return 'Proprietário';
    case 'patient':
      return 'Paciente';
    case 'other':
      return 'Registro';
    default:
      return '';
  }
};

function formatSpeciality(speciality) {
  switch (speciality) {
    case 'nutritionist':
      return 'Nutrição';
    case 'general_doctor':
      return 'Clínico Geral';
    case 'physiotherapist':
      return 'Fisioterapia';
    default:
      return '';
  }
};

function formatMobileNumber(mobile) {
  if (!mobile) return '';
  return mobile.replace(/\D/g, '');
};

module.exports = {
  calculateAge,
  pluralize,
  formatDate,
  formatTime,
  formatGender,
  formatPersonType,
  formatSpeciality,
  formatMobileNumber,
};




