import React, { useContext } from 'react';
import { NavLink } from 'react-router-dom';
import { AuthContext } from '../../../context/auth';

import Logout from '../../Logout';

import '../styles/Nav.css';
import '../../../Mobile.css';

// import imageLogo from '../../../assets/img/logoTopo2.png';
import imageLogo from '../../../assets/img/logoTopo3.png';


const Nav = () => {
  const { loginUser } = useContext(AuthContext);


  const user = loginUser || {
    user: { first_name: "Usuário", last_name: "Desconhecido", email: "" }, roles: ""
  }; // Default values
  const initials = (user?.first_name ? user.first_name.charAt(0) : '') + (user?.last_name ? user.last_name.charAt(0) : '');
  const fullName = `${user.first_name} ${user.last_name}`;

  return (
    <>
      <div className="navbar">
        <div className='navbarBrand'>
          <NavLink className={'alonelink'} to="/home/dashboard" key="home">
            <img className='imgLogo' src={imageLogo} alt="logo - brand" />
          </NavLink>
        </div>
        <div className='navbarCenter'>
          <div className="initials">
            <div className="userInitials" data-tip={fullName}>
              {initials}
            </div>
          </div>
          <div className="user-email">{user.email}</div>
          <div className="roles">{user.roles}</div>
        </div>
        <div className="navbarRight">
          <NavLink className={'alonelink'} to="/" key="logout">
            <Logout />
          </NavLink>
        </div>
      </div>

      <div id="mobileMenu">
        <div className={"nav-links"}>
          <div className="nav-item">
            <a href="/home">Home</a>
          </div>
          <div className="nav-item dropdown">
            <button className="dropbtn">Users</button>
            <div className="dropdown-content">
              <a href="/home/user/get">Todos os usuários</a>
            </div>
          </div>
          <div className="nav-item">
            <a href="/about">About</a>
          </div>
          <div className="nav-item">
            <a href="http://localhost:3000/">Login</a>
          </div>
          <div className="nav-item">
            <a href="http://localhost:3000/">Login</a>
          </div>
          <div className="nav-item">
            <a href="http://localhost:3000/">Login</a>
          </div>
          <div className="nav-item">
            <a href="http://localhost:3000/">Login</a>
          </div>
          <div className="nav-item">
            <a href="http://localhost:3000/">Login</a>
          </div>
          <div className="nav-item">
            <a href="http://localhost:3000/">Login</a>
          </div>
        </div>
      </div>

    </>

  );
};

export default Nav;
