import React, { useEffect, useContext, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import { AuthContext } from '../../../context/auth';
import { fetchAppointmentById } from '../../../redux/actions/appointmentsActions';
import { MdEmail, MdPrint, MdExitToApp, MdEdit } from 'react-icons/md';

import '../styles/ShowAppointment.css';

function ShowAppointment() {
  const { loginUser } = useContext(AuthContext);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { appointment_id } = useParams();

  const { currentAppointment, loading, error } = useSelector((state) => state.appointments);
  const [appointmentData, setAppointmentData] = useState(null);

  useEffect(() => {
    if (appointment_id) {
      dispatch(fetchAppointmentById(appointment_id))
        .then((response) => {
          setAppointmentData(response.payload);
        })
        .catch((error) => {
          console.error('Error fetching appointment:', error);
        });
    }
  }, [dispatch, appointment_id]);

  const formatDate = (dateString) => {
    if (!dateString) return 'Data inválida';
    const date = new Date(dateString);
    if (isNaN(date)) return 'Data inválida';
    const day = date.getDate().toString().padStart(2, '0');
    const month = (date.getMonth() + 1).toString().padStart(2, '0');
    const year = date.getFullYear();
    return `${day}/${month}/${year}`;
  };

  const formatTime = (timeString) => {
    if (!timeString) return 'Hora inválida';
    const timeParts = timeString.split(':');
    if (timeParts.length < 2) return 'Hora inválida';
    const [hour, minute] = timeParts;
    return `${hour.padStart(2, '0')}:${minute.padStart(2, '0')}`;
  };

  const handleNavigate = (path) => {
    navigate(path);
  };

  const handleUpdate = (appointment_id) => {
    dispatch(fetchAppointmentById(appointment_id));
    navigate(`/home/appointment/update/${appointment_id}`);
  };

  const handleClickBack = (e) => {
    e.preventDefault();
    navigate(`/home/appointment/get`);
  };

  const hasRole = (role) => loginUser.roles?.includes(role);

  const formatStatus = (status) => {
    switch (status) {
      case 'confirmed':
        return 'Confirmado';
      case 'scheduled':
        return 'Agendado';
      case 'changed':
        return 'Alterado';
      case 'cancelled':
        return 'Cancelado';
      default:
        return '';
    }
  };

  if (loading) {
    return <div>Carregando...</div>;
  }

  if (error) {
    return <div>Erro ao carregar os dados: {error}</div>;
  }

  if (!appointmentData) {
    return <div>Nenhum compromisso encontrado.</div>;
  }

  return (
    <>
      <h3>Detalhes do Compromisso</h3>
      <form className="boxShowAppointment">
        <div className="box-line">
          <div className="boxCol1">
            <label htmlFor="company_name">Empresa:</label>
            <span className='spanData'>{appointmentData.company_name}</span>
          </div>
        </div>
        <div className="box-line">
          <div className="boxCol1">
            <label htmlFor="professional_name">Profissional:</label>
            <span className='spanData'>{appointmentData.professional_name}</span>
          </div>
        </div>
        <div className="box-line">
          <div className="boxCol1">
            <label htmlFor="patient_name">Paciente:</label>
            <span className='spanData'>{appointmentData.patient_name}</span>
          </div>
        </div>

        <h5>Data do Compromisso</h5>
        <div className="box-line">
          <div className="boxCol1">
            <label htmlFor="appointment_date">Data do Compromisso:</label>
            <span className='spanData'>{formatDate(appointmentData.appointment_date)}</span>
          </div>
        </div>
        <div className="box-line">
          <div className="boxCol1">
            <label htmlFor="appointment_time">Hora do Compromisso:</label>
            <span className='spanData'>{formatTime(appointmentData.appointment_time)}</span>
          </div>
        </div>
        <div className='box-line'>
          <div className="boxCol1">
            <label htmlFor="status">Status:</label>
            <span className='spanData'>{formatStatus(appointmentData.status)}</span>
          </div>
        </div>

        <h5>Observações</h5>
        <div className="box-line">
          <div className="boxCol1">
            <label htmlFor="notes">Notas:</label>
            <span className='spanData'>{appointmentData.notes}</span>
          </div>
        </div>

        <div className="boxButtons1">
          <button className='showBoxBtn' type="button" onClick={() => handleNavigate(alert('Sending Email'))}>
            <MdEmail className='sideIcoBtn' />
            <span className='sideBtnSpan'>Email</span>
          </button>
        </div>
        <div className="boxButtons2">
          <button className='showBoxBtn' type="button" onClick={() => handleNavigate(alert('Printing'))}>
            <MdPrint className='sideIcoBtn' />
            <span className='sideBtnSpan'>Imprimir</span>
          </button>
        </div>
        <div className="boxButtons3">
          {(hasRole('Manager') || hasRole('Admin')) && (
            <button className='showBoxBtn' type="button" onClick={() => handleUpdate(appointment_id)}>
              <MdEdit className='sideIcoBtn' />
              <span className='sideBtnSpan'>Editar</span>
            </button>
          )}
        </div>
        <div className="boxButtons4">
          <button className='showBoxBtn' type="button" onClick={handleClickBack}>
            <MdExitToApp className='sideIcoBtn' />
            <span className='sideBtnSpan'>Voltar</span>
          </button>
        </div>
      </form>
    </>
  );
}

export default ShowAppointment;
