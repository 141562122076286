import React, { useContext } from 'react'
import { useNavigate } from 'react-router-dom';
import { cardsConfig } from './components/cardsConfig';
import { AuthContext } from '../../context/auth';

import './css/Dashboard.css';
import '../../Mobile.css';

import CardMenu from './components/CardMenu';
import CalendarDashboard from '../../components/Calendar/Calendar';
import CountNewUser from '../../components/CountNewUser/CounterNewUser';




function Dashboard() {
  const { hasRole } = useContext(AuthContext);
  const navigate = useNavigate();


  // Filtra os cards com base na role do usuário

  const filteredCards = cardsConfig.filter(card =>
    card.requiredRole.some(role => hasRole(role))
  );

  const handleNavigateToAppointmentDetails = (appointment_id) => {
    navigate(`/home/appointment/show/${appointment_id}`);
  };


  return (
    <>
      <h3>Bem-vindo</h3>
      <div className="dashboard">

        <div className="cards-menu">
          {filteredCards.map((card, index) => (
            <CardMenu
              key={index}
              imgSrc={card.imgSrc}
              label={card.label}
              navigateTo={card.navigateTo}
            />
          ))}
        </div>

        <CountNewUser />

        <CalendarDashboard onNavigateToAppointmentDetails={handleNavigateToAppointmentDetails} />
      </div>
    </>

  )
}

export default Dashboard