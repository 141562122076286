import React, { useEffect, useContext } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { fetchUserById } from '../../../redux/actions/userActions';
import { useNavigate, useParams } from 'react-router-dom';
import { AuthContext } from '../../../context/auth';

import { MdEmail, MdPrint, MdExitToApp, MdEdit } from 'react-icons/md';
import '../styles/ShowUser.css';
import '../../Person/styles/Avatar.css';
import { setCurrentUser } from '../../../redux/slices/userSlice';

const ShowUser = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { user_id } = useParams();
  const { loginUser } = useContext(AuthContext);
  const { currentUser, loading, error } = useSelector(state => state.users);

  useEffect(() => {
    if (user_id) {
      console.log('Fetching user data for ID:', user_id);
      dispatch(fetchUserById(user_id));
    }
  }, [dispatch, user_id]);

  console.log(currentUser);

  const formatedUser = (roles) => {
    switch (roles) {
      case 'Admin':
        return 'Administrador';
      case 'Manager':
        return 'Gerente';
      case 'Coordinator':
        return 'Coordenador';
      case 'User':
        return 'Usuário';
    }
  };


  if (loading) {
    return <div>Loading...</div>;
  }

  if (error) {
    return <div>Error: {error}</div>;
  }

  if (!currentUser) {
    return <div>No data found for the specified user.</div>;
  }

  const handleUpdate = (e) => {
    dispatch(setCurrentUser(user_id))
    navigate(`/home/user/update/${user_id}`);
  };

  const handleEmail = (e) => {
    e.preventDefault();
    alert("Email");
  };

  const handlePrint = (e) => {
    e.preventDefault();
    alert("Imprimindo");
  };

  const handleClickBack = (e) => {
    e.preventDefault();
    navigate(`/home/user/get`);
  };
  const formatDate = (date) => {
    const d = new Date(date);
    return `${String(d.getDate()).padStart(2, '0')}/${String(d.getMonth() + 1).padStart(2, '0')}/${d.getFullYear()}`;
  };

  const hasRole = (role) => loginUser.roles?.includes(role);

  return (
    <>
      <h3>Detalhes do Usuário</h3>
      <form className="boxShowUser">
        <div className="boxTop">

          <div className="box-line">
            <div className="boxCol1">
              <label htmlFor="first_name">Nome Completo:</label>
              <span className='spanData'>{currentUser?.first_name} {currentUser?.last_name}</span>
            </div>
          </div>

          <div className="box-line">
            <div className="boxCol1">
              <label htmlFor="email">Email:</label>
              <span className='spanData'>
                <a className='spanData' href={`mailto:${currentUser?.email}`}>{currentUser?.email}</a>
              </span>
            </div>
          </div>
          <div className="box-line">
            <div className="boxCol1">
              <label htmlFor="roles">Permissão:</label>
              <span className='spanData'>{formatedUser(currentUser?.roles)}</span>
            </div>
          </div>
        </div>

        <h5>Outras Informações</h5>
        <div className="box-line">
          <div className="boxCol1">
            <label htmlFor="last_login">Último Login:</label>
            <span className='spanData'>{currentUser?.last_login ? formatDate(currentUser?.last_login) : 'N/A'}</span>
          </div>
        </div>

        <div className="boxButtons1">
          <button className='showBoxBtn' type="button" onClick={handleEmail}>
            <MdEmail className='sideIcoBtn' />
            <span className='sideBtnSpan'>Email</span>
          </button>
        </div>
        <div className="boxButtons2">
          <button className='showBoxBtn' type="button" onClick={handlePrint}>
            <MdPrint className='sideIcoBtn' />
            <span className='sideBtnSpan'>Imprimir</span>
          </button>
        </div>
        <div className="boxButtons3">
          {(hasRole('Manager') || hasRole('Admin')) && (
            <button className='showBoxBtn' type="button" onClick={handleUpdate}>
              <MdEdit className='sideIcoBtn' />
              <span className='sideBtnSpan'>Editar</span>
            </button>
          )}
        </div>
        <div className="boxButtons4">
          <button className='showBoxBtn' type="button" onClick={handleClickBack}>
            <MdExitToApp className='sideIcoBtn' />
            <span className='sideBtnSpan'>Voltar</span>
          </button>
        </div>
      </form>
    </>
  );
};

export default ShowUser;
