import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import { createUserAsync } from '../../../redux/actions/userActions';
import ModalForms from '../../../components/ConfirmationModal/ModalForms';

import '../styles/CreateUser.css';

function CreateUser() {
  const dispatch = useDispatch();
  const [showModal, setShowModal] = useState(false);
  const [message, setMessage] = useState('');
  const navigate = useNavigate();

  const initialValues = {
    email: '',
    first_name: '',
    last_name: '',
    roles: '',
    password: ''
  };

  const validationSchema = Yup.object({
    first_name: Yup.string().required('Primeiro nome é necessário'),
    last_name: Yup.string().required('Sobrenome é necessário'),
    email: Yup.string().email('Email inválido').required('Email é necessário'),
    roles: Yup.string().required('Permissão é necessária'),
    password: Yup.string().required('Senha é necessária')
  });

  const handleSubmit = async (values, { setSubmitting }) => {
    try {
      const formattedValues = {
        ...values,
        role: values.roles // Ensure the role is sent as a string directly
      };

      const resultAction = await dispatch(createUserAsync(formattedValues));

      if (createUserAsync.fulfilled.match(resultAction)) {
        const { message } = resultAction.payload;
        setMessage(message || 'Usuário criado com sucesso!');
        setShowModal(true);
        setTimeout(() => {
          setShowModal(false);
          navigate('/home/user/get');
        }, 2000);
      } else if (createUserAsync.rejected.match(resultAction)) {
        // Access the error message from resultAction.payload if it's a rejected action
        const errorMsg = resultAction.payload?.message || 'Erro ao criar usuário. Tente novamente mais tarde.';
        setMessage(errorMsg);
        setShowModal(true);
        setTimeout(() => {
          setShowModal(false);
        }, 2500);
      }
    } catch (error) {
      const errorMsg = error.message || 'Erro ao criar usuário. Tente novamente mais tarde.';
      setMessage(errorMsg);
      setShowModal(true);
      setTimeout(() => {
        setShowModal(false);
      }, 2500);
    } finally {
      setSubmitting(false);
    }
  };


  const handleBack = (e) => {
    e.preventDefault();
    navigate('/home/user/get');
  }

  return (
    <>
      <h3>Criar Usuário</h3>
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={handleSubmit}
      >
        {({ isSubmitting }) => (
          <Form className="userForm">

            <ErrorMessage name="email" component="div" className="error-message" />
            <ErrorMessage name="first_name" component="div" className="error-message" />
            <ErrorMessage name="last_name" component="div" className="error-message" />
            <ErrorMessage name="roles" component="div" className="error-message" />
            <ErrorMessage name="password" component="div" className="error-message" />
            <div className="user-form-group">
              <label htmlFor="email">Email</label>
              <Field
                type="email"
                name="email"
                id="email"
                placeholder="Digite o email"
              />

            </div>

            <div className="user-form-group">
              <label htmlFor="first_name">Primeiro Nome</label>
              <Field
                type="text"
                name="first_name"
                id="first_name"
                placeholder="Digite o primeiro nome"
              />

            </div>

            <div className="user-form-group">
              <label htmlFor="last_name">Sobrenome</label>
              <Field
                type="text"
                name="last_name"
                id="last_name"
                placeholder="Digite o sobrenome"
              />

            </div>

            <div className="user-form-group">
              <label htmlFor="roles">Permissão</label>
              <Field as="select" className="select" name="roles" id="roles">
                <option value="">Selecione uma permissão</option>
                <option value="Admin">Administrador</option>
                <option value="Manager">Gerente</option>
                <option value="Coordinator">Coordenador</option>
                <option value="User">Usuário</option>
              </Field>

            </div>

            <div className="user-form-group">
              <label htmlFor="password">Senha</label>
              <Field
                type="password"
                name="password"
                id="password"
                placeholder="Digite a senha"
              />

            </div>

            <div className="userButtons">
              <button type="submit" className="sendBtn" disabled={isSubmitting}>Criar</button>
              <button className='backBtn' onClick={handleBack}>Cancelar</button>
            </div>
          </Form>
        )}
      </Formik>
      {showModal && (
        <ModalForms
          message={message}
          onConfirm={() => setShowModal(false)}
        />
      )}
    </>
  );
}

export default CreateUser;
