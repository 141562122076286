import React, { useState } from 'react';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import { createCompany } from '../../../service/api';
import ModalForms from '../../../components/ConfirmationModal/ModalForms';
import { Tabs, Tab } from '../../../components/Tabs/Tabs';
import InputMask from 'react-input-mask';
import { useNavigate } from 'react-router-dom';

import '../styles/CreateCompany.css';

function CreateCompany() {
  const navigate = useNavigate();
  const [selectedLogo, setSelectedLogo] = useState('/avatar.png');
  const [file, setFile] = useState(null);
  const [message, setMessage] = useState('');
  const [showModal, setShowModal] = useState(false);
  const [errors, setErrors] = useState({});
  // let percentage = 0;

  const setImgFile = (e, setFieldValue) => {
    const file = e.target.files[0];
    setFile(file);
    setFieldValue(file);

    if (file) {
      const reader = new FileReader();
      reader.onloadend = () => {
        setSelectedLogo(reader.result);
      };
      reader.readAsDataURL(file);
    }
  };

  const initialValues = {
    company_name: '',
    fantasy_name: '',
    company_type: '',
    cnpj: '',
    insc_estadual: '',
    insc_municipal: '',
    contact: '',
    whatsapp: '',
    contact2: '',
    whatsapp2: '',
    phone: '',
    phone2: '',
    mobile: '',
    mobile2: '',
    website: '',
    email: '',
    email2: '',
    social_media1: '',
    social_media2: '',
    social_media3: '',
    social_media4: '',
    since_date: '',
    observations: '',
    address: {
      zipcode: '',
      street: '',
      number: '',
      neighborhood: '',
      city: '',
      uf: ''
    },
    avatar: null // Campo para o arquivo de avatar
  };

  const validationSchema = Yup.object({
    company_name: Yup.string().required('Razão Social é necessário'),
    fantasy_name: Yup.string().required('Nome Fantasia é necessário'),
    email: Yup.string().email('Email inválido').required('Email é necessário'),
    since_date: Yup.date()
      .max(new Date(), 'A data de fundação não pode ser futura.'),
    company_type: Yup.string().required('Tipo de empresa é necessário'),
  });

  const handleSubmit = async (values, { setSubmitting, resetForm }) => {
    try {
      const formData = new FormData();
      for (const key in values) {
        if (key === "address") {
          for (const addrKey in values[key]) {
            formData.append(`address[${addrKey}]`, values[key][addrKey]);
          }
        } else {
          formData.append(key, values[key]);
        }
      }
      if (file) {
        formData.append("avatar", file);
      }
      console.log("Form data:", formData);
      const response = await createCompany(formData);
      setMessage(response.message || "Empresa criada com sucesso!");
      setShowModal(true);
      setTimeout(() => {
        setShowModal(false);
        resetForm();
        navigate('/home/company/get');
      }, 2000);
    } catch (error) {
      console.error("Erro ao enviar formulário:", error);
      const errorMsg = (error.response && error.response.data && error.response.data.message)
        ? error.response.data.message
        : 'Erro ao enviar formulário. Tente novamente mais tarde.';
      setMessage(errorMsg);
      setShowModal(true);
      setTimeout(() => {
        setShowModal(false);
      }, 2500);
      setErrors({ submit: errorMsg });
    }

    setSubmitting(false);
  };

  const handleKeyDown = (e) => {
    if (e.key === 'Enter') {
      e.preventDefault();
    }
  };

  const handleBack = (e) => {
    e.preventDefault();
    navigate('/home/company/get');
  }

  return (
    <div>
      <h3>Nova Empresa</h3>
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={handleSubmit}
      >
        {({ setFieldValue, isSubmitting }) => (
          <Form className='companyForm' encType="multipart/form-data" onKeyDown={handleKeyDown}>
            <div className="companyMain">
              <div className="colA">
                <div className="silhuette">
                  <div className="row">
                    <div>
                      <input
                        id="avatar"
                        className="photo"
                        type="file"
                        name="avatar"
                        accept="image/*"
                        onChange={(e) => setImgFile(e, setFieldValue)}
                      />
                      <div>
                        <img id="avatarImg" className="cadphoto" src={selectedLogo} alt="Avatar" />
                      </div>
                    </div>
                    <div className="photo">
                      <label htmlFor="avatar" className="photo">
                        <img className="photo" src="/apple.ico" alt="Upload Avatar" />
                      </label>
                    </div>
                  </div>
                </div>
              </div>
              <div className="colB">
                <Tabs>
                  <Tab label="Pessoa Jurídica">
                    {/* Inserir componentes de formulário aqui */}
                    <div className="company-form-group">
                      <div className="trSelects">
                        <div className="col1">
                          <label htmlFor="company_type">Tipo de Empresa:</label>
                          <Field as="select"
                            name="company_type"
                            id="company_type"
                          >
                            <option value="">Selecione</option>
                            <option value="matriz">Matriz</option>
                            <option value="filial">Filial</option>
                            <option value="others">Outro</option>
                          </Field>
                          <ErrorMessage name="company_type" component="div" className="error-message" />
                        </div>
                      </div>
                    </div>

                    <div className="company-form-group">
                      <label htmlFor="company_name">Razão Social:</label>
                      <Field
                        type="text"
                        name="company_name"
                        id="company_name"
                        placeholder="Digite a Razão Social"
                      />
                      <ErrorMessage name="company_name" component="div" className="error-message" />
                    </div>


                    <div className="company-form-group">
                      <label htmlFor="fantasy_name">Nome Fantasia:</label>
                      <Field
                        type="text"
                        name="fantasy_name"
                        id="fantasy_name"
                        placeholder="Digite o Nome Fantasia"
                      />
                      <ErrorMessage name="last_name" component="div" className="error-message" />
                    </div>

                    <div className="company-form-group">
                      <label htmlFor="email">Email principal:</label>
                      <Field
                        type="text"
                        name="email"
                        id="email"
                        placeholder="Digite o email"
                      />
                      <ErrorMessage name="email" component="div" className="error-message" />
                    </div>

                    <div className="company-form-group">
                      <label htmlFor="since_date">Data de Fundação:</label>
                      <Field
                        type="date"
                        name="since_date"
                        id="since_date"
                      />
                      <ErrorMessage name="since_date" component="div" className="error-message" />
                    </div>

                    <div className="company-form-group">
                      <div className="trSelects">
                        <div className="pfgCol1">
                          <label htmlFor="cnpj">CNPJ:</label>
                          <Field name="cnpj">
                            {({ field }) => (
                              <InputMask
                                {...field}
                                mask="99.999.999/9999-99"
                                placeholder="Digite o CNPJ"
                                onChange={(e) => {
                                  setFieldValue("cnpj", e.target.value);
                                }}
                              />
                            )}
                          </Field>
                        </div>
                        <div className="pfgCol2">
                          <label htmlFor="insc_estadual">I.E:</label>
                          <Field
                            type="text"
                            name="insc_estadual"
                            id="insc_estadual"
                            placeholder="Inscrição Estadual"
                          />
                          <ErrorMessage name="insc_estadual" component="div" className="error-message" />
                        </div>
                        <div className="pfgCol3">
                          <label htmlFor="insc_municipal">I.M</label>
                          <Field
                            type="text"
                            name="insc_municipal"
                            id="insc_municipal"
                            placeholder="Inscrição Municipal"
                          />
                          <ErrorMessage name="insc_municipal" component="div" className="error-message" />
                        </div>
                      </div>
                    </div>



                    <div className="company-form-group">
                      <div className="trSelects">
                        <div className="pfgCol2">
                          <label htmlFor="segment">Segmento principal:</label>
                          <Field as="select"
                            name="segment"
                            id="segment"
                          >
                            <option value="">Selecione</option>
                            <option value="nutrition">Nutrição</option>
                            <option value="general_health">Saúde Geral</option>
                            <option value="aesthetic">Estética</option>
                            <option value="pharmaceutical">Farmaceutica</option>
                            <option value="foods">Alimentos</option>
                            <option value="suplements">Suplementos</option>
                            <option value="others">Outro</option>
                          </Field>
                          <ErrorMessage name="suplement" component="div" className="error-message" />
                        </div>
                        <div className="pfgCol3">
                        </div>
                      </div>
                    </div>
                  </Tab>

                  <Tab label="Contatos">
                    {/* Campos de contato */}
                    <div className="company-form-group">
                      <div className="trSelects">
                        <div className="pfgCol1">
                          <label htmlFor="contact">Contato:</label>
                          <Field
                            type="text"
                            name="contact"
                            id="contact"
                            placeholder="Digite o contato"
                          />
                          <ErrorMessage name="contact" component="div" className="error-message" />
                        </div>
                        <div className="pfgCol2">

                          <label htmlFor="contact2">Contato:</label>
                          <Field
                            type="text"
                            name="contact2"
                            id="contact2"
                            placeholder="Digite o contato"
                          />
                          <ErrorMessage name="contact2" component="div" className="error-message" />
                        </div>
                      </div>
                      <div className="pfgCol3">
                      </div>
                    </div>


                    <div className="company-form-group">
                      <div className="trSelects">
                        <div className="pfgCol1">
                          <label htmlFor="email2">Email 2:</label>
                          <Field
                            type="email"
                            name="email2"
                            id="email2"
                            placeholder="Digite o email 2"
                          />
                          <ErrorMessage name="email2" component="div" className="error-message" />
                        </div>
                        <div className="pfgCol2">
                          <label htmlFor="website">WebSite:</label>
                          <Field
                            type="text"
                            name="website"
                            id="website2"
                            placeholder="Digite o website"
                          />
                          <ErrorMessage name="website" component="div" className="error-message" />
                        </div>
                        <div className="pfgCol3">
                        </div>
                      </div>
                    </div>

                    <div className="company-form-group">
                      <div className="trSelects">
                        <div className="pfgCol1">
                          <label htmlFor="phone">Telefone:</label>
                          <Field name="phone">
                            {({ field }) => (
                              <InputMask
                                {...field}
                                mask="(99) 99999-9999"
                                placeholder="Digite o telefone"
                                onChange={(e) => {
                                  setFieldValue("phone", e.target.value);
                                }}
                              />
                            )}
                          </Field>
                          <ErrorMessage name="phone" component="div" className="error-message" />
                        </div>
                        <div className="pfgCol2">
                          <label htmlFor="phone2">Telefone 2:</label>
                          <Field name="phone2">
                            {({ field }) => (
                              <InputMask
                                {...field}
                                mask="(99) 99999-9999"
                                placeholder="Digite o telefone 2"
                                onChange={(e) => {
                                  setFieldValue("phone2", e.target.value);
                                }}
                              />
                            )}
                          </Field>
                          <ErrorMessage name="phone2" component="div" className="error-message" />
                        </div>
                        <div className="pfgCol3">
                        </div>
                      </div>
                    </div>

                    <div className="company-form-group">
                      <div className="trSelects">
                        <div className="pfgCol1">
                          <label htmlFor="mobile">Celular:</label>
                          <Field name="mobile">
                            {({ field }) => (
                              <InputMask
                                {...field}
                                mask="(99) 99999-9999"
                                placeholder="Digite o celular"
                                onChange={(e) => {
                                  setFieldValue("mobile", e.target.value);
                                }}
                              />
                            )}
                          </Field>
                          <ErrorMessage name="mobile" component="div" className="error-message" />
                        </div>
                        <div className="pfgCol2">
                          <label htmlFor="mobile2">Celular 2:</label>
                          <Field name="mobile2">
                            {({ field }) => (
                              <InputMask
                                {...field}
                                mask="(99) 99999-9999"
                                placeholder="Digite o celular 2"
                                onChange={(e) => {
                                  setFieldValue("mobile2", e.target.value);
                                }}
                              />
                            )}
                          </Field>
                          <ErrorMessage name="mobile2" component="div" className="error-message" />
                        </div>
                        <div className="pfgCol3">
                        </div>
                      </div>
                    </div>

                    <div className="company-form-group">
                      <div className="trSelects">
                        <div className="pfgCol1">
                          <label htmlFor="social_media1">Facebook</label>
                          <Field
                            type="text"
                            name="social_media1"
                            id="social_media1"
                            placeholder="Facebook"
                          />
                          <ErrorMessage name="social_media1" component="div" className="error-message" />
                        </div>
                        <div className="pfgCol2">
                          <label htmlFor="social_media2">Instagram</label>
                          <Field
                            type="text"
                            name="social_media2"
                            id="social_media2"
                            placeholder="Instagram"
                          />
                          <ErrorMessage name="social_media2" component="div" className="error-message" />
                        </div>
                        <div className="pfgCol3">
                          <label htmlFor="social_media3">X (twitter)</label>
                          <Field
                            type="text"
                            name="social_media3"
                            id="social_media3"
                            placeholder="X"
                          />
                          <ErrorMessage name="social_media3" component="div" className="error-message" />
                        </div>
                      </div>
                    </div>
                    <div className="company-form-group">
                      <Field as="textarea"
                        name="observations"
                        id="observations"
                        placeholder="Obs:"
                        rows={4} // Defina o número de linhas inicial do textarea
                      />
                      <ErrorMessage name="observations" component="div" className="error-message" />
                    </div>
                  </Tab>

                  <Tab label="Endereço">
                    {/* Campos de endereço */}
                    <div className="company-form-group">
                      <label htmlFor="address.zipcode">CEP:</label>
                      <Field name="address.zipcode">
                        {({ field }) => (
                          <InputMask
                            {...field}
                            id="address.zipcode"
                            name="address.zipcode"
                            mask="99999-999"
                            placeholder="Digite o CEP"
                            onChange={(e) => {
                              setFieldValue("address.zipcode", e.target.value);
                            }}
                          />
                        )}
                      </Field>
                    </div>

                    <div className="company-form-group">
                      <label htmlFor="address.street">Rua:</label>
                      <Field
                        type="text"
                        name="address.street"
                        id="address.street"
                        placeholder="Digite a rua"
                      />
                      <ErrorMessage name="address.street" component="div" className="error-message" />
                    </div>

                    <div className="company-form-group">
                      <label htmlFor="address.number">Número</label>
                      <Field type="text"
                        name="address.number"
                        id="address.number"
                        placeholder="Digite o número"
                      />
                      <ErrorMessage name="address.number" component="div" className="error-message" />
                    </div>

                    <div className="company-form-group">
                      <label htmlFor="address.complement">Complemento:</label>
                      <Field
                        type="text"
                        name="address.complement"
                        id="address.complement"
                        placeholder="Digite o complemento"
                      />
                    </div>

                    <div className="company-form-group">
                      <label htmlFor="address.neighborhood">Bairro:</label>
                      <Field
                        type="text"
                        name="address.neighborhood"
                        id="address.neighborhood"
                        placeholder="Digite o bairro"
                      />
                    </div>

                    <div className="company-form-group">
                      <label htmlFor="address.city">Cidade:</label>
                      <Field
                        type="text"
                        name="address.city"
                        id="address.city"
                        placeholder="Digite a cidade"
                      />
                    </div>

                    <div className="company-form-group">
                      <label htmlFor="address.uf">UF:</label>
                      <Field as="select"
                        name="address.uf"
                        id="address.uf"
                      >
                        <option value="">Selecione</option>
                        <option value="AC">Acre - AC</option>
                        <option value="AL">Alagoas - AL</option>
                        <option value="AM">Amazonas - AM</option>
                        <option value="AP">Amapá - AP</option>
                        <option value="BA">Bahia - BA</option>
                        <option value="CE">Ceará - CE</option>
                        <option value="DF">Distrito Federal - DF</option>
                        <option value="ES">Espírito Santo - ES</option>
                        <option value="GO">Goiás - GO</option>
                        <option value="MA">Maranhão - MA</option>
                        <option value="MG">Minas Gerais - MG</option>
                        <option value="MS">Mato Grosso do Sul - MS</option>
                        <option value="MT">Mato Grosso - MT</option>
                        <option value="PA">Pará - PA</option>
                        <option value="PB">Paraíba - PB</option>
                        <option value="PE">Pernambuco - PE</option>
                        <option value="PI">Piauí - PI</option>
                        <option value="PR">Paraná - PR</option>
                        <option value="RJ">Rio de Janeiro - RJ</option>
                        <option value="RN">Rio Grande do Norte - RN</option>
                        <option value="RO">Rondônia - RO</option>
                        <option value="RR">Roraima - RR</option>
                        <option value="RS">Rio Grande do Sul - RS</option>
                        <option value="SC">Santa Catarina - SC</option>
                        <option value="SE">Sergipe - SE</option>
                        <option value="SP">São Paulo - SP</option>
                        <option value="TO">Tocantins - TO</option>
                      </Field>
                    </div>
                  </Tab>
                </Tabs>
              </div>

              <div className="colC">
                {/* <p>Força do perfil</p>
                <p>{percentage}</p> */}
                <ErrorMessage name="company_type" component="div" className="spanMessage" />
                <ErrorMessage name="company_name" component="div" className="spanMessage" />
                <ErrorMessage name="fantasy_name" component="div" className="spanMessage" />
                <ErrorMessage name="email" component="div" className="spanMessage" />
                <ErrorMessage name="since_date" component="div" className="spanMessage" />
                {errors.company_name && <div className="spanMessage">{errors.company_name}</div>}
                <div className="companyButtons">
                  <div>
                    <button className='sendBtn' type='submit'>Enviar</button>
                  </div>
                  <div>
                    <button className='backBtn' onClick={handleBack}>Cancelar</button>
                  </div>
                </div>
              </div>
            </div>
          </Form>
        )}
      </Formik>
      {showModal && (
        <ModalForms
          message={message}
          onConfirm={() => setShowModal(false)}
        />
      )}
    </div>
  );
}

export default CreateCompany;
