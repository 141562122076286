import React, { useState, useEffect, useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { fetchCompanies, deleteCompanyById } from '../../../redux/actions/companyActions';
import { AuthContext } from '../../../context/auth';
import { MdDelete, MdCreate } from 'react-icons/md';

import ConfirmationModal from '../../../components/ConfirmationModal/ConfirmationModal';
import SearchBar from '../../../components/SearchBar/SearchBar';

import '../styles/GetCompany.css';
import { setCurrentCompany } from '../../../redux/slices/companySlice';

function GetCompany() {
  const { loginUser } = useContext(AuthContext);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [message, setMessage] = useState('');
  const [ellipsis, setEllipsis] = useState('.....');
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 10;
  const [searchText, setSearchText] = useState('');
  const [showModal, setShowModal] = useState(false);
  const [isDeleting, setIsDeleting] = useState(false);
  const [companyIdToDelete, setCompanyIdToDelete] = useState('');
  const icoUpdate = <MdCreate className='icon' key='icoUpdate' />;
  const icoTrash = <MdDelete className='icon' key='icoTrash' />;
  const [filteredCompany, setFilteredCompany] = useState([]);

  const { companies, loading, error } = useSelector((state) => state.companies);

  const updateBtn = 'Editar';
  const deleteBtn = 'Deletar';
  const detailsBtn = 'Clique para detalhes';

  useEffect(() => {
    dispatch(fetchCompanies());
  }, [dispatch]);

  useEffect(() => {
    if (companies) {
      setFilteredCompany(companies);
    }
  }, [companies]);

  const handleDelete = async (company_id, company_name, fantasy_name) => {
    setCompanyIdToDelete(company_id);
    setMessage(`Tem certeza que deseja remover o registro de: ${company_id} ${company_name} ${fantasy_name}`);
    setShowModal(true);
  };

  const handleConfirmDelete = async (company_id) => {
    setIsDeleting(true);
    try {
      await dispatch(deleteCompanyById(company_id)).unwrap();
      setShowModal(false);
      setMessage(`Removido o registro ID: ${company_id} com sucesso :)`);
      setIsDeleting(false);
    } catch (error) {
      setMessage(`Erro ao tentar deletar o registro: ${error.message}`);
      setIsDeleting(false);
    }
  };

  const handleCancelDelete = () => {
    setShowModal(false);
  };

  const handleUpdate = (company_id) => {
    dispatch(setCurrentCompany(company_id));
    navigate(`/home/company/update/${company_id}`);
  };

  const handleShowCompany = async (company_id) => {
    dispatch(setCurrentCompany(company_id));
    navigate(`/home/company/show/${company_id}`);
  };

  const handleSearch = (searchText) => {
    setSearchText(searchText);
    const filtered = getFilteredCompany(searchText);
    setFilteredCompany(filtered);
  };

  const getFilteredCompany = (searchText) => {
    if (!companies) return [];
    return companies.filter((company) => {
      const companyType = formatCompanyType(company.company_type);
      const companyInfo = `${company.company_id} ${company.company_name} ${company.fantasy_name} ${company.email} ${companyType} ${company.segment}`;
      const searchTextLowerCase = searchText.trim().toLowerCase();
      return companyInfo.toLowerCase().includes(searchTextLowerCase);
    });
  };

  useEffect(() => {
    let interval;
    if (loading) {
      let count = 5;
      interval = setInterval(() => {
        setEllipsis('.'.repeat(count));
        count = count === 1 ? 5 : count - 1;
      }, 500);
    } else {
      setEllipsis('.....');
    }

    return () => clearInterval(interval);
  }, [loading]);

  const formatCompanyType = (company_type) => {
    switch (company_type) {
      case 'matriz':
        return 'Matriz';
      case 'filial':
        return 'Filial';
      case 'clinic':
        return 'Clínica';
      case 'office':
        return 'Consultório';
      case 'hospital':
        return 'Hospital';
      case 'other':
        return 'Outro Tipo';
      default:
        return '';
    }
  };

  const formatCompanySegment = (companySegment) => {
    switch (companySegment) {
      case 'Nutrition':
        return 'Nutrição';
      case 'general_health':
        return 'Clinica Geral';
      case 'aesthetic':
        return 'Estética';
      case 'pharmaceutical':
        return 'Farmaceutica';
      case 'foods':
        return 'Alimentos';
      case 'suplements':
        return 'Suplementos';
      case 'services':
        return 'Serviços';
      case 'others':
        return 'Outros';
      default:
        return '';
    }
  };

  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentItems = filteredCompany.slice(indexOfFirstItem, indexOfLastItem);

  const paginate = (pageNumber) => setCurrentPage(pageNumber);
  const getPageButtonClass = (index) => (currentPage === index + 1 ? 'active' : '');

  const hasRole = (role) => loginUser.roles && loginUser.roles.includes(role);

  return (
    <>
      {showModal && (
        <ConfirmationModal
          message={message}
          onConfirm={handleConfirmDelete}
          onCancel={handleCancelDelete}
          isDeleting={isDeleting}
        />
      )}


      <div className="col-md-12">
        <h3>Empresas do Sistema</h3>
        <SearchBar onSearch={handleSearch} />
      </div>
      <div className="boxGetCompany">
        <table className='getCompanyTable'>
          <thead>
            <tr>
              <td>Logo</td>
              <td>Razão Social</td>
              <td>Nome Fantasia</td>
              <td>Email</td>
              <td>Celular</td>
              <td>Telefone</td>
              <td>Segmento</td>
              {hasRole('Manager') && <td>Editar</td>}
              {hasRole('Admin') && <td>Ações</td>}
            </tr>
          </thead>
          <tbody>
            {loading ? (
              <tr>
                <td className="rowMessage"> Aguarde{ellipsis}</td>
              </tr>
            ) : currentItems.length === 0 ? (
              <tr>
                <td className="rowMessage" colSpan="8">Não localizado nenhum registro correspondente</td>
              </tr>
            ) : (
              currentItems.map((company) => (
                <tr key={company.company_id}>
                  <td><button className='tdBtn' onClick={() => handleShowCompany(company.company_id)} data-tip={detailsBtn}><img src={company.file_path} alt="PessoaPic" /></button></td>
                  <td><button className='tdBtn' onClick={() => handleShowCompany(company.company_id)} data-tip={detailsBtn}>{company.company_name}</button></td>
                  <td><button className='tdBtn' onClick={() => handleShowCompany(company.company_id)} data-tip={detailsBtn}>{company.fantasy_name}</button></td>
                  <td><button className='tdBtn' onClick={() => handleShowCompany(company.company_id)} data-tip={detailsBtn}>{company.email}</button></td>
                  <td>{company.mobile}</td>
                  <td>{company.phone}</td>
                  <td>{formatCompanySegment(company.segment)}</td>
                  {hasRole('Manager') && (
                    <td className="update"><button className='icoBtn' onClick={() => handleUpdate(company.company_id)} data-tip={updateBtn}>{icoUpdate}</button></td>
                  )}
                  {hasRole('Admin') && (
                    <td className="delete">
                      <button className='icoBtn' onClick={() => handleUpdate(company.company_id)} data-tip={updateBtn}>{icoUpdate}</button>
                      <button className='icoBtn' onClick={() => handleDelete(company.company_id, company.company_name, company.fantasy_name)} data-tip={deleteBtn}>{icoTrash}</button>
                    </td>
                  )}
                </tr>
              ))
            )}
          </tbody>
        </table>
        <div className="pagination">
          {Array.from({ length: Math.ceil(filteredCompany.length / itemsPerPage) }, (_, index) => (
            <button key={index + 1} onClick={() => paginate(index + 1)} className={getPageButtonClass(index)} >
              {index + 1}
            </button>
          ))}
        </div>
      </div>
    </>
  );
}

export default GetCompany;
