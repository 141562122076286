import { combineReducers } from '@reduxjs/toolkit';
import appointmentSlice from './slices/appointmentSlice';
import personSlice from './slices/personSlice';
import relatedEntitiesSlice from './slices/relatedEntitiesSlice';
import companySlice from './slices/companySlice';
import userSlice from './slices/userSlice';
import nutridataSlice from './slices/nutridataSlice';
import anthropometrySlice from './slices/anthropometrySlice';

const rootReducer = combineReducers({
  appointments: appointmentSlice,
  persons: personSlice,
  relatedEntities: relatedEntitiesSlice,
  companies: companySlice,
  users: userSlice,
  nutridata: nutridataSlice,
  anthropometry: anthropometrySlice,
});

export default rootReducer;
