import React, { useState, useEffect, useContext } from 'react';
import { AuthContext } from '../../../context/auth';
import { getPatient, deletePatient } from '../../../service/api';
import { MdDelete, MdCreate } from 'react-icons/md';

import ConfirmationModal from '../../../components/ConfirmationModal/ConfirmationModal';
import SearchBar from '../../../components/SearchBar/SearchBar'

import '../styles/GetPatient.css';

function GetPatient({ onComponentClick, onComponentPatientClick, onComponentPatientToUpdate }) {
  const { loginUser } = useContext(AuthContext);
  const [loading, setLoading] = useState(true);
  const [message, setMessage] = useState('');
  const [ellipsis, setEllipsis] = useState('.....');
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 10;
  const [searchText, setSearchText] = useState("");
  const [showModal, setShowModal] = useState(false);
  const [isDeleting, setIsDeleting] = useState(false);
  const [patientIdToUpdate, setPatientIdToUpdate] = useState("");
  const [patientIdToShow, setPatientIdToShow] = useState("");
  const [patientIdToDelete, setPatientIdToDelete] = useState("");
  const icoUpdate = <MdCreate className='icon' key='icoUpdate' />;
  const icoTrash = <MdDelete className='icon' key='icoTrash' />;
  const [patients, setPatients] = useState([]);
  const [filteredPatient, setFilteredPatient] = useState([]);



  const updateBtn = "Editar";
  const deleteBtn = "Deletar";
  const detailsBtn = "Clique para detalhes";

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await getPatient();
        const data = response.data;
        setPatients(data);
        setFilteredPatient(data);
      } catch (error) {
        setMessage('Erro ao obter os registros.');
        console.error('Login error:', error);
      } finally {
        setLoading(false);
      }
    };
    fetchData();
  }, []);

  const handleDelete = async (patient_id, first_name, last_name) => {
    setPatientIdToDelete(patient_id);
    setMessage(`Tem certeza que deseja remover o registro de: ${first_name} ${last_name}`);
    setShowModal(true);
  };

  const handleConfirmDelete = async () => {
    setIsDeleting(true);
    try {
      await deletePatient(patientIdToDelete);
      setShowModal(true);
      setMessage(`Removido o registro ID: ${patientIdToDelete} com sucesso :)`);
      setPatients(patients.filter(patient => patient.patient_id !== patientIdToDelete));
      setTimeout(() => {
        setShowModal(false);
        setIsDeleting(false);
      }, 2000);
    } catch (error) {
      setMessage(`Erro ao tentar deletar o registro: ${error}`);
      setIsDeleting(false);
    }
  }

  const handleCancelDelete = () => {
    setShowModal(false);
  };

  const handleUpdate = (patient_id) => {
    const id = patient_id;
    setPatientIdToUpdate(id);
    onComponentPatientToUpdate(id);
  };

  const handleShowPatient = async (patient_id) => {
    const id = patient_id;
    setPatientIdToShow(id);
    onComponentPatientClick(id);
  };

  const handleSearch = (searchText) => {
    setSearchText(searchText);
    // Atualize os resultados filtrados quando o searchText for alterado
    const filteredPatient = getFilteredPatient();
    setFilteredPatient(filteredPatient);
  };

  // Lógica para calcular os índices dos itens a serem exibidos na página atual
  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  // Lógica para mudar de página
  const paginate = (pageNumber) => setCurrentPage(pageNumber);
  // Função para adicionar uma classe de estilo condicional ao botão da página atual
  const getPageButtonClass = (index) => (currentPage === index + 1 ? 'active' : '');

  const getFilteredPatient = () => {
    return patients.filter((patient) => {
      const patientType = formatPatientType(patient.patient_type);
      const patientInfo = `${patient.patient_id} ${patient.first_name} ${patient.last_name} ${patient.email} ${patient.roles} ${patientType}`;
      const searchTextLowerCase = searchText.trim().toLowerCase();
      return patientInfo.toLowerCase().includes(searchTextLowerCase);
    });
  };

  useEffect(() => {
    let interval;
    if (loading) {
      let count = 5;
      interval = setInterval(() => {
        setEllipsis('.'.repeat(count));
        count = count === 1 ? 5 : count - 1;
      }, 500); // Atualiza a cada 500ms
    } else {
      setEllipsis('.....'); // Reseta as reticências quando não está carregando
    }

    return () => clearInterval(interval); // Limpa o intervalo ao desmontar ou quando loading mudar
  }, [loading]);


  const formatPatientType = (patient_type) => {
    switch (patient_type) {
      case 'patient':
        return 'Paciente';
      case 'nutritionist':
        return 'Nutricionista';
      case 'doctor':
        return 'Médico';
      case 'professional':
        return 'Profissional';
      case 'user':
        return 'Usuário';
      case 'employee':
        return 'Colaborador';
      case 'supplier':
        return 'Fornecedor';
      case 'partner':
        return 'Parceiro';
      case 'owner':
        return 'Proprietário';
      case 'other':
        return 'Outro';
      default:
        return '';
    }
  };

  const currentItems = filteredPatient.slice((currentPage - 1) * itemsPerPage, currentPage * itemsPerPage);

  const hasRole = (role) => loginUser.roles && loginUser.roles.includes(role);

  return (
    <>
      {showModal && (
        <ConfirmationModal
          message={message}
          onConfirm={handleConfirmDelete}
          onCancel={handleCancelDelete}
          isDeleting={isDeleting}
        />
      )}
      <div className="patient">
        <div className="row">
          <div className="col-md-12">
            <h3>Pessoas do Sistema</h3>
            <SearchBar
              onSearch={handleSearch} />
          </div>
        </div>
        <div className="rowTable">
          <table className='getPatientTable'>
            <thead>
              <tr>
                <td></td>
                <td>Nome</td>
                <td>Email</td>
                <td>Celular</td>
                <td>Telefone</td>
                <td>Tipo</td>
                {hasRole('Manager') && <td>Editar</td>}
                {hasRole('Admin') && <td>Ações</td>}
              </tr>
            </thead>
            <tbody>
              {loading ? (
                <tr>
                  <td className="rowMessage"> Aguarde{ellipsis}</td>
                </tr>
              ) : currentItems.length === 0 ? (
                <tr>
                  <td className="rowMessage" colSpan="4">Não localizado nenhum registro correspondente</td>
                </tr>
              ) : (
                (searchText === '' ? patients : filteredPatient).slice(indexOfFirstItem, indexOfLastItem).map((patient) =>
                (

                  <tr key={patient.patient_id}>
                    <td><button className='tdBtn' onClick={() => handleShowPatient(patient.patient_id)} data-tip={detailsBtn}><img src={patient.file_path} alt="PessoaPic" /></button></td>
                    <td><button className='tdBtn' onClick={() => handleShowPatient(patient.patient_id)} data-tip={detailsBtn}>{patient.first_name} {patient.last_name}</button></td>
                    <td><button className='tdBtn' onClick={() => handleShowPatient(patient.patient_id)} data-tip={detailsBtn}>{patient.email}</button></td>
                    <td>{patient.mobile}</td>
                    <td>{patient.phone}</td>
                    <td>{formatPatientType(patient.patient_type)}</td>

                    {loginUser.roles === 'Manager' ? (
                      <td className="update"><button className='icoBtn' onClick={() => handleUpdate(patient.patient_id)} data-tip={updateBtn}>{icoUpdate}</button></td>
                    ) : null}
                    {hasRole('Admin') && <td className="delete">
                      <button className='icoBtn' onClick={() => handleUpdate(patient.patient_id)} data-tip={updateBtn}>{icoUpdate}</button>
                      <button className='icoBtn' onClick={() => handleDelete(patient.patient_id, patient.first_name, patient.last_name)} data-tip={deleteBtn}>{icoTrash}</button>
                    </td>}

                  </tr>
                )
                ))}
            </tbody>
          </table>
          <div className="pagination">
            {Array.from({ length: Math.ceil((searchText === '' ? patients.length : filteredPatient.length) / itemsPerPage) }, (_, index) => (
              <button key={index + 1} onClick={() => paginate(index + 1)} className={getPageButtonClass(index)} >
                {index + 1}
              </button>
            ))}
          </div>
        </div>
      </div >
    </>
  );
}

export default GetPatient;
