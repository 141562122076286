import React from 'react';
import { Outlet } from 'react-router-dom';

import '../../Mobile.css';
function Anthropometry() {

  return (
    <>
      <Outlet />
    </>
  );
}

export default Anthropometry