import React from 'react';

import Nav from '../../components/NavBar/components/Nav';


const About = () => {

  return (
    <>
      <Nav />
      <div>
        <h1>About</h1>

      </div>
    </>
  );
};

export default About;
