import { createAsyncThunk } from '@reduxjs/toolkit';
import { getCompany, getCompanyById, createCompany, updateCompany, deleteCompany } from '../../service/api';

export const fetchCompanies = createAsyncThunk(
  'company/fetchCompanies',
  async () => {
    const response = await getCompany();
    return response.data;
  }
);

export const fetchCompanyById = createAsyncThunk(
  'company/fetchCompanyById',
  async (company_id) => {
    const response = await getCompanyById(company_id);
    return response.data;
  }
);

export const createNewCompany = createAsyncThunk(
  'company/createNewCompany',
  async (formData) => {
    const response = await createCompany(formData);
    return response.data.data;
  }
);

export const updateCompanyById = createAsyncThunk(
  'company/updateCompanyById',
  async ({ company_id, formData }) => {
    const response = await updateCompany(company_id, formData);
    return response;
  }
);

export const deleteCompanyById = createAsyncThunk(
  'company/deleteCompany',
  async (company_id) => {
    await deleteCompany(company_id);
    return company_id;
  }
);
