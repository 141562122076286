import { createSlice } from '@reduxjs/toolkit';
import {
  fetchAllTacoFoods,
  fetchTacoFoodById,
  // deleteTacoFoodById, 
  // updateTacoFoodById, 
  // createNewTacoFood, 
} from '../actions/nutridataActions';

const nutridataSlice = createSlice({
  name: 'nutridata',
  initialState: {
    tacoFoods: [],         // Lista de todos os alimentos Taco
    currentTacoFood: null, // Alimento Taco atualmente selecionado
    loading: false,        // Estado de carregamento
    error: null,           // Estado de erro
  },
  reducers: {
    setCurrentTacoFood: (state, action) => {
      state.currentTacoFood = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchAllTacoFoods.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(fetchAllTacoFoods.fulfilled, (state, action) => {
        state.loading = false;
        state.tacoFoods = action.payload;
      })
      .addCase(fetchAllTacoFoods.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      })
      .addCase(fetchTacoFoodById.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(fetchTacoFoodById.fulfilled, (state, action) => {
        state.loading = false;
        state.currentTacoFood = action.payload;
      })
      .addCase(fetchTacoFoodById.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      })
      // .addCase(deleteTacoFoodById.pending, (state) => {
      //   state.loading = true;
      //   state.error = null;
      // })
      // .addCase(deleteTacoFoodById.fulfilled, (state, action) => {
      //   state.loading = false;
      //   state.tacoFoods = state.tacoFoods.filter(food => food.id !== action.payload);
      // })
      // .addCase(deleteTacoFoodById.rejected, (state, action) => {
      //   state.loading = false;
      //   state.error = action.error.message;
      // })
      // .addCase(updateTacoFoodById.pending, (state) => {
      //   state.loading = true;
      //   state.error = null;
      // })
      // .addCase(updateTacoFoodById.fulfilled, (state, action) => {
      //   state.loading = false;
      //   // Atualiza o alimento na lista de tacoFoods
      //   if (action.payload) {
      //     state.tacoFoods = state.tacoFoods.map(food => food.id === action.payload.id ? action.payload : food);
      //   }
      // })
      // .addCase(updateTacoFoodById.rejected, (state, action) => {
      //   state.loading = false;
      //   state.error = action.error.message;
      // })
      // .addCase(createNewTacoFood.pending, (state) => {
      //   state.loading = true;
      //   state.error = null;
      // })
      // .addCase(createNewTacoFood.fulfilled, (state, action) => {
      //   state.loading = false;
      //   state.tacoFoods.push(action.payload);
      // })
      // .addCase(createNewTacoFood.rejected, (state, action) => {
      //   state.loading = false;
      //   state.error = action.error.message;
      // });
      ;
  },
});

export const { setCurrentTacoFood } = nutridataSlice.actions;
export default nutridataSlice.reducer;
