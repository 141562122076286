import React, { useState, useEffect, createContext } from 'react';
import { useNavigate } from 'react-router-dom';
import { api, createSession, resetPassword as resetPasswordApi, singnUp } from '../service/api';
import Cookies from 'js-cookie';

import ModalForms from '../components/ConfirmationModal/ModalForms';

export const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
  const [message, setMessage] = useState('');
  const [showModal, setShowModal] = useState(false);
  const navigate = useNavigate();
  const [loginUser, setLoginUser] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const recoveredUser = Cookies.get('user');
    const token = Cookies.get('token');

    if (recoveredUser && token) {
      setLoginUser(JSON.parse(recoveredUser));
      api.defaults.headers.Authorization = `Bearer ${token}`;
      addAuthInterceptor(); // Adiciona o interceptador somente se o usuário estiver logado
    }
    setLoading(false);
  }, []);

  const addAuthInterceptor = () => {
    api.interceptors.response.use(
      (response) => response,
      (error) => {
        if (error.response && error.response.status === 401) {
          setMessage('Sua sessão expirou. Você será redirecionado para a página de login.');
          setShowModal(true);
          setTimeout(() => {
            setShowModal(false);
            logout();
            navigate('/');
          }, 4000);
        }
        return Promise.reject(error);
      }
    );
  };

  const login = async (email, password) => {
    setLoading(true);
    try {
      const response = await createSession(email, password);
      const { token, user } = response.data;

      Cookies.set('user', JSON.stringify(user));
      Cookies.set('token', token);
      api.defaults.headers.Authorization = `Bearer ${token}`;

      setLoginUser(user);
      addAuthInterceptor(); // Adiciona o interceptador após o login
      setLoading(false);
      navigate("/home/dashboard");
    } catch (error) {
      setLoading(false);
      console.error('Login error:', error);
      throw error; // Certifique-se de lançar o erro
    }
  };

  const hasRole = (role) => {
    return loginUser?.roles?.includes(role);
  };

  const logout = () => {
    Cookies.remove('user');
    Cookies.remove('token');
    api.defaults.headers.Authorization = null;
    setLoginUser(null);
    navigate("/");
  };

  const resetPassword = async (email, tempPassword, newPassword, confirmNewPassword) => {
    try {
      const response = await resetPasswordApi(email, tempPassword, newPassword, confirmNewPassword);
      return response.data;
    } catch (error) {
      console.error('Reset password error:', error);
      throw error;
    }
  };

  const signUp = async (email, first_name, last_name) => {
    try {
      const response = await singnUp(email, first_name, last_name);
      return response.data;
    } catch (error) {
      console.error('User error:', error);
      throw error;
    }
  };

  return (
    <AuthContext.Provider value={{ authenticated: !!loginUser, loginUser, loading, login, logout, resetPassword, hasRole, signUp, api }}>
      {children}
      {showModal && (
        <ModalForms show={showModal} message={message} onClose={() => setShowModal(false)} />
      )}
    </AuthContext.Provider>
  );
};

export default AuthProvider;
