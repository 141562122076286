import React, { useState, useEffect, useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { AuthContext } from '../../../context/auth';
import { fetchPersonList, deletePersonById } from '../../../redux/actions/personActions';
import { setCurrentPerson } from '../../../redux/slices/personSlice';

import { MdDelete, MdCreate } from 'react-icons/md';

import ConfirmationModal from '../../../components/ConfirmationModal/ConfirmationModal';
import SearchBar from '../../../components/SearchBar/SearchBar';
import Pagination from '../../../components/Pagination/Pagination';
import imgDefault from '../../../assets/img/avatar/avatar.png';

import '../styles/GetPerson.css';

function GetPersonList() {
  const { loginUser } = useContext(AuthContext);
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const { persons = [], loading, error } = useSelector((state) => state.persons);

  const [message, setMessage] = useState('');
  const [ellipsis, setEllipsis] = useState('.....');
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 10;
  const [searchText, setSearchText] = useState("");
  const [showModal, setShowModal] = useState(false);
  const [isDeleting, setIsDeleting] = useState(false);
  const [personIdToDelete, setPersonIdToDelete] = useState(null);

  const icoUpdate = <MdCreate className='icon' />;
  const icoTrash = <MdDelete className='icon' />;

  const filteredPerson = persons.filter((person) => {
    const personInfo = `${person.person_id} ${person.first_name} ${person.last_name} ${person.email} ${person.roles}`;
    return personInfo.toLowerCase().includes(searchText.trim().toLowerCase());
  });

  const currentItems = filteredPerson.slice((currentPage - 1) * itemsPerPage, currentPage * itemsPerPage);

  useEffect(() => {
    dispatch(fetchPersonList());
  }, [dispatch]);

  const handleDelete = async (person_id, first_name) => {
    setPersonIdToDelete(person_id);
    setMessage(`Tem certeza que deseja remover o registro de: ${first_name}`);
    setShowModal(true);
  };

  const handleConfirmDelete = async () => {
    setIsDeleting(true);
    try {
      await dispatch(deletePersonById(personIdToDelete)).unwrap();
      setShowModal(false);
      setMessage(`Removido o registro ID: ${personIdToDelete} com sucesso : )`);
      setIsDeleting(false);
    } catch (error) {
      setMessage(`Erro ao tentar deletar o registro: ${error.message} `);
      setIsDeleting(false);
    }
  };

  const handleCancelDelete = () => {
    setShowModal(false);
    setPersonIdToDelete(null);
  };


  const handleUpdatePerson = (person_id) => {
    dispatch(setCurrentPerson(person_id));
    navigate(`/home/person/update/${person_id}`);
  };

  const handleShowPerson = (person_id) => {
    dispatch(setCurrentPerson(person_id));
    navigate(`/home/person/show/${person_id}`);
  };


  const handleSearch = (text) => {
    setSearchText(text);
    setCurrentPage(1); // Reseta para a primeira página ao realizar a busca
  };

  const paginate = (pageNumber) => setCurrentPage(pageNumber);

  useEffect(() => {
    let interval;
    if (loading) {
      let count = 5;
      interval = setInterval(() => {
        setEllipsis('.'.repeat(count));
        count = count === 1 ? 5 : count - 1;
      }, 500);
    } else {
      setEllipsis('.....');
    }
    return () => clearInterval(interval);
  }, [loading]);

  const hasRole = (role) => loginUser?.roles?.includes(role);

  return (
    <>
      {showModal && (
        <ConfirmationModal
          message={message}
          onConfirm={handleConfirmDelete}
          onCancel={handleCancelDelete}
          isDeleting={isDeleting}
        />
      )}
      <div className="person">
        <div className="row">
          <div className="col-md-12">
            <h3>Pessoas do Sistema</h3>
            <SearchBar onSearch={handleSearch} />
          </div>
          <div className='countLines'>Total de Registros: {filteredPerson.length}</div>
        </div>
        <div className="rowTable">
          <table className='getPersonTable'>
            <thead>
              <tr>
                <td />
                <td>Nome</td>
                <td>Email</td>
                <td>Celular</td>
                <td>Telefone</td>
                {hasRole('Manager') && <td>Editar</td>}
                {hasRole('Admin') && <td>Ações</td>}
              </tr>
            </thead>
            <tbody>
              {loading ? (
                <tr>
                  <td className="rowMessage"> Aguarde{ellipsis}</td>
                </tr>
              ) : currentItems.length === 0 ? (
                <tr>
                  <td className="rowMessage" colSpan="8">Não localizado nenhum registro correspondente</td>
                </tr>
              ) : (
                currentItems.map((person) => (
                  <tr key={person.person_id}>
                    <td>
                      <button type='button' className='tdBtn' onClick={() => handleShowPerson(person.person_id)} data-tip="Clique para detalhes">
                        <img src={person.file_path || imgDefault} alt={`Imagem de ${person.first_name}`} />
                      </button>
                    </td>
                    <td>
                      <button type='button' className='tdBtn' onClick={() => handleShowPerson(person.person_id)} data-tip="Clique para detalhes">
                        {person.first_name} {person.last_name}
                      </button>
                    </td>
                    <td>{person.email}</td>
                    <td>{person.mobile}</td>
                    <td>{person.phone}</td>
                    {hasRole('Manager') && (
                      <td className="update">
                        <button type='button' className='icoBtn' onClick={() => handleUpdatePerson(person.person_id)} data-tip="Editar">
                          {icoUpdate}
                        </button>
                      </td>
                    )}
                    {hasRole('Admin') && (
                      <td className="delete">
                        <button type='button' className='icoBtn' onClick={() => handleUpdatePerson(person.person_id)} data-tip="Editar">
                          {icoUpdate}
                        </button>
                        <button type='button' className='icoBtn' onClick={() => handleDelete(person.person_id, person.first_name)} data-tip="Deletar">
                          {icoTrash}
                        </button>
                      </td>
                    )}
                  </tr>
                ))
              )}
            </tbody>
          </table>
          <Pagination
            totalItems={filteredPerson.length}
            itemsPerPage={itemsPerPage}
            currentPage={currentPage}
            paginate={paginate}
          />
        </div>
      </div>
    </>
  );
}

export default GetPersonList;
