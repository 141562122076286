import React, { useState, useEffect, useContext } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { AuthContext } from '../../../context/auth';
import { fetchCompanyById } from '../../../redux/actions/companyActions';
import { MdEmail, MdPrint, MdExitToApp, MdEdit } from 'react-icons/md';

import '../styles/ShowCompany.css';

function ShowCompany() {
  const { loginUser } = useContext(AuthContext);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { company_id } = useParams();

  // const [message, setMessage] = useState('');
  const addressDetails = "Clique para ver no mapa";
  const emailDetails = "Clique para enviar mensagem";
  const mobileDetails = "Clique para whatsapp";
  const socialDetails = "Clique para seguir";

  const { loading, error } = useSelector((state) => state.companies.currentCompany || {});
  const [currentCompany, setCurrentCompany] = useState(null);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await dispatch(fetchCompanyById(company_id)).unwrap();
        setCurrentCompany(response);
      } catch (error) {
        console.error('Error fetching company:', error);
      }
    };
    fetchData();
  }, [dispatch, company_id]);

  const formatDate = (dateString) => {
    const date = new Date(dateString);
    const day = date.getDate().toString().padStart(2, '0');
    const month = (date.getMonth() + 1).toString().padStart(2, '0'); // Janeiro é 0
    const year = date.getFullYear();
    return `${day}/${month}/${year}`;
  }

  const calculateAge = (sinceDateString) => {
    const today = new Date();
    const sinceDate = new Date(sinceDateString);
    let years = today.getFullYear() - sinceDate.getFullYear();
    let months = today.getMonth() - sinceDate.getMonth();
    let days = today.getDate() - sinceDate.getDate();

    if (days < 0) {
      months--;
      const lastMonth = new Date(today.getFullYear(), today.getMonth(), 0);
      days += lastMonth.getDate();
    }

    if (months < 0) {
      years--;
      months += 12;
    }

    return `${pluralize(years, 'Ano', 'Anos')} ${pluralize(months, 'mês', 'meses')} e ${pluralize(days, 'dia', 'dias')}`;
  }

  const pluralize = (count, singular, plural) => {
    return `${count} ${count === 1 ? singular : plural}`;
  }

  const formatCompanyStatus = (company_status) => {
    switch (company_status) {
      case 'active':
        return 'Ativo';
      case 'inactive':
        return 'Inativo';
      default:
        return 'Ativo';
    }
  }

  const formatCompanyType = (company_type) => {
    switch (company_type) {
      case 'matriz':
        return 'Matriz';
      case 'filial':
        return 'Filial';
      case 'clinic':
        return 'Clínica';
      case 'office':
        return 'Consultório';
      case 'hospital':
        return 'Hospital';
      case 'other':
        return 'Outro Tipo';
      default:
        return '';
    }
  }
  const formatCompanySegment = (company_segment) => {
    switch (company_segment) {
      case 'nutrition':
        return 'Nutrição';
      case 'general_health':
        return 'Saúde Geral';
      case 'aesthetic':
        return 'Estética';
      case 'pharmaceutical':
        return 'Farmaceutica';
      case 'foods':
        return 'Alimentos';
      case 'suplements':
        return 'Suplementos';
      case 'services':
        return 'Serviços';
      case 'others':
        return 'Outro Tipo';
      default:
        return '';
    }
  }

  const handleUpdate = (company_id) => {
    // Fetch company details and set currentCompany in Redux store
    dispatch(fetchCompanyById(company_id)).then(() => {
      navigate(`/home/company/update/${company_id}`);
    });
  };

  const handleClickBack = (e) => {
    e.preventDefault();
    navigate(`/home/company/get`);
  };

  const handleEmail = (e) => {
    e.preventDefault();
    alert("Email");

  };

  const handlePrint = (e) => {
    e.preventDefault();
    alert("Imprimindo");
  };

  function formatMobileNumber(mobile) {
    if (!mobile) return ''; // Retorna uma string vazia se mobile não estiver definido
    return mobile.replace(/\D/g, '');
  }


  const hasRole = (role) => loginUser.roles?.includes(role);

  if (loading) {
    return <div>Loading...</div>;
  }

  if (error) {
    return <div>Error: {error}</div>;
  }

  if (!currentCompany) {
    return <div>No data found for the specified company.</div>;
  }

  return (
    <>
      <h3>{currentCompany.fantasy_name} -  Detalhes</h3>
      <form className="boxShowCompany">

        <div className="boxTop">
          <div className="silhuette">
            <img id="avatar" className="cadphoto" src={currentCompany.file_path || "/assets/uploads/img/default_avatar.png"} alt="Person" />
          </div>
          <div className="boxTopB">
            <div className="box-line">
              <div className="boxCol1">
                <label htmlFor="first_name">Razão Social:</label>
                <span className='spanData'>{currentCompany.company_name}</span>
              </div>
            </div>
            <div className="box-line">
              <div className="boxCol1">
                <label htmlFor="fantasy_name">Nome Fantasia:</label>
                <span className='spanData'>{currentCompany.fantasy_name}</span>
              </div>
            </div>
            <div className="box-line">
              <div className="boxCol1">
                <label htmlFor="birth_date">Data de Fundação:</label>
                <span className='spanData'>{formatDate(currentCompany.since_date)}</span>
              </div>
            </div>

            <div className="box-line">
              <div className="boxCol1">
                <label htmlFor="age">Período desde a abertura:</label>
                <span className='spanData'>{calculateAge(currentCompany.since_date)}</span>
              </div>
            </div>

            <div className="box-line">
              <div className="boxCol1">
                <label htmlFor="segment">Segmento Principal:</label>
                <span className='spanData'>{formatCompanySegment(currentCompany.segment)}</span>
              </div>
              <div className="boxCol1">
                <label htmlFor="status">Status:</label>
                <span className='spanData'>{formatCompanyStatus(currentCompany.status)}</span>
              </div>
              <div className="boxCol1">
                <label htmlFor="status">Tipo de Empresa:</label>
                <span className='spanData'>{formatCompanyType(currentCompany.company_type)}</span>
              </div>
            </div>
          </div>
        </div>

        <h3>Documentos</h3>
        <div className="box-line">
          <div className="boxCol1">
            <label htmlFor="cnpj">CNPJ:</label>
            <span className='spanData'>{currentCompany.cnpj}</span>
          </div>
          <div className="boxCol1">
            <label htmlFor="insc_estadual">I.E:</label>
            <span className='spanData'>{currentCompany.insc_estadual}</span>
          </div>
          <div className="boxCol1">
            <label htmlFor="insc_municipal">I.M:</label>
            <span className='spanData'>{currentCompany.insc_municipal}</span>
          </div>
        </div>

        <h3>Contatos</h3>
        <div className="box-line">
          <div className="boxCol1">
            <label htmlFor="email2">Contato:</label>
            <span className='spanData'><a data-tip={emailDetails} className='spanData' href={`mailto:${currentCompany.contact}`}>{currentCompany.contact}</a></span>
          </div>
          <div className="boxCol1">
            <label htmlFor="email">Email:</label>
            <span className='spanData'><a data-tip={emailDetails} className='spanData' href={`mailto:${currentCompany.email}`}>{currentCompany.email}</a></span>
          </div>

          <div className="boxCol1">
            <label htmlFor="email2">Contato 2:</label>
            <span className='spanData'><a data-tip={emailDetails} className='spanData' href={`mailto:${currentCompany.contact2}`}>{currentCompany.contact2}</a></span>
          </div> <div className="boxCol1">
            <label htmlFor="email2">Email 2:</label>
            <span className='spanData'><a data-tip={emailDetails} className='spanData' href={`mailto:${currentCompany.email2}`}>{currentCompany.email2}</a></span>
          </div>
        </div>

        <div className="box-line contact">
          <div className="boxCol1">
            <label htmlFor="mobile">Celular:</label>
            <span className='spanData'>
              <a data-tip={mobileDetails} href={`https://api.whatsapp.com/send?phone=${formatMobileNumber(currentCompany.mobile)}`}
                target="_blank"
                rel="noopener noreferrer">{currentCompany.mobile}</a>
            </span>
          </div>
          <div className="boxCol1">
            <label htmlFor="mobile2">Celular 2:</label>
            <span className='spanData'>
              <a data-tip={mobileDetails} href={`https://api.whatsapp.com/send?phone=${formatMobileNumber(currentCompany.mobile2)}`}
                target="_blank"
                rel="noopener noreferrer">{currentCompany.mobile2}</a>
            </span>
          </div>
          <div className="boxCol1">
            <label htmlFor="phone">Telefone:</label>
            <span className='spanData'>{currentCompany.phone}</span>
          </div>
          <div className="boxCol1">
            <label htmlFor="phone2">Telefone 2:</label>
            <span className='spanData'>{currentCompany.phone2}</span>
          </div>
        </div>
        <div className="box-line contact">
          <div className="boxCol1">
            <label htmlFor="website">WebSite: </label>
            <span className='spanData'><a data-tip={socialDetails} href={`https://${currentCompany.website}`}
              target="_blank"
              rel="noopener noreferrer">{currentCompany.website}</a>
            </span>
          </div>
        </div>
        <div className="box-line contact">
          <div className="boxCol1">
            <label htmlFor="social_media1">Social Mídia: </label>
            <span className='spanData'><a data-tip={socialDetails} href={`https://${currentCompany.social_media1}`}
              target="_blank"
              rel="noopener noreferrer">{currentCompany.social_media1}</a>
            </span>
          </div>
          <div className="boxCol1">
            <label htmlFor="social_media2">Social Mídia 2: </label>
            <span className='spanData'><a data-tip={socialDetails} href={`https://${currentCompany.social_media2}`}
              target="_blank"
              rel="noopener noreferrer">{currentCompany.social_media2}</a>
            </span>
          </div>
          <div className="boxCol1">
            <label htmlFor="social_media3">Social Mídia 3: </label>
            <span className='spanData'><a data-tip={socialDetails} href={`https://${currentCompany.social_media3}`}
              target="_blank"
              rel="noopener noreferrer">{currentCompany.social_media3}</a>
            </span>
          </div>
        </div>

        <h3>Endereço</h3>
        <div className="">
          <a data-tip={addressDetails}
            href={`https://www.google.com/maps?q=${currentCompany.street} + ${currentCompany.number}`}
            target="_blank"
            rel="noopener noreferrer">
            <span className='spanData'>{currentCompany.street}, {currentCompany.number}</span> -
            <span className='spanData'>{currentCompany.complement}</span> - <span className='spanData'>{currentCompany.neighborhood}</span> -
            <span className='spanData'>{currentCompany.city}</span> -
            <span className='spanData'>{currentCompany.uf}</span> -
            <span className='spanData'>{currentCompany.zipcode}</span>
          </a>
        </div>

        <div className="boxButtons1">
          <button className='showBoxBtn' type="button" onClick={handleEmail}>
            <MdEmail className='sideIcoBtn' />
            <span className='sideBtnSpan'>Email</span>
          </button>
        </div>
        <div className="boxButtons2">
          <button className='showBoxBtn' type="button" onClick={handlePrint}>
            <MdPrint className='sideIcoBtn' />
            <span className='sideBtnSpan'>Imprimir</span>
          </button>
        </div>
        <div className="boxButtons3">
          {(hasRole('Manager') || hasRole('Admin')) && (
            <button className='showBoxBtn' type="button" onClick={() => handleUpdate(currentCompany.company_id)}>
              <MdEdit className='sideIcoBtn' />
              <span className='sideBtnSpan'>Editar</span>
            </button>
          )}
        </div>
        <div className="boxButtons4">
          <button className='showBoxBtn' type="button" onClick={handleClickBack}>
            <MdExitToApp className='sideIcoBtn' />
            <span className='sideBtnSpan'>Voltar</span>
          </button>
        </div>

      </form >
    </>
  );
}

export default ShowCompany;
