import jsPDF from 'jspdf';
import headerImg from '../../assets/img/headerImg.png';
import defaultAvatar from '../../assets/img/avatar.png';

import {
  calculateAge,
  pluralize,
  formatDate,
  formatTime,
  formatGender,
  formatPersonType,
  formatSpeciality,
  formatMobileNumber,
} from '../Functions/formatPersons';

const getBase64ImageFromURL = async (url) => {
  try {
    const response = await fetch(url, {
      mode: 'cors', // Importante para habilitar CORS
    });
    const blob = await response.blob();

    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.onloadend = () => {
        resolve(reader.result); // Retorna a imagem em Base64
      };
      reader.onerror = reject;
      reader.readAsDataURL(blob); // Lê o blob e converte para Base64
    });
  } catch (error) {
    console.error('Erro ao carregar a imagem via fetch:', error);
    throw error;
  }
}

const showPersonPdf = async (currentPerson, appointmentList = [], appointments = null) => {
  const pdf = new jsPDF({
    orientation: 'portrait',
    unit: 'mm',
    format: 'a4',
  });

  let currentPage = 1;

  const addHeader = async () => {
    try {
      const imgData = await getBase64ImageFromURL(headerImg);
      pdf.addImage(imgData, 'PNG', 0, 0, 210, 20); // Cabeçalho da página
    } catch (error) {
      console.error("Erro ao carregar imagem do cabeçalho", error);
    }

    pdf.setFontSize(8);
    pdf.setTextColor(5, 0, 0);
  };

  const addFooter = () => {
    pdf.setTextColor(5, 0, 0);
    const rodapeYPosition = pdf.internal.pageSize.height - 5;
    pdf.setFontSize(6);
    pdf.text('powered by', 75, rodapeYPosition - 3, 'center');
    pdf.setFontSize(7);
    pdf.text('apit solutions desenvolvimento de software ltda', 105, rodapeYPosition, 'center');
    pdf.text(`${currentPage}`, 205, rodapeYPosition, 'center');
  };

  const addSectionBox = (pdf, title, yPosition, contentCallback) => {
    const boxHeight = contentCallback(pdf, yPosition); // Retorna a altura do conteúdo
    // Define a cor da borda do box
    pdf.setDrawColor(139, 197, 64);
    pdf.setLineWidth(0.2);
    pdf.roundedRect(10, yPosition - 5, 190, boxHeight, 3, 3);
    // Define a cor do título
    pdf.setTextColor(139, 197, 64);
    pdf.setFontSize(8);
    pdf.text(title, 12, yPosition);
    // Define a cor padrão para o texto dentro da caixa
    pdf.setTextColor(3, 3, 3); // Cor padrão para o texto dentro da caixa
    pdf.setFontSize(8); // Tamanho da fonte padrão
    return yPosition + boxHeight + 10;
  };

  const addPageContent = async () => {
    const maxPageHeight = pdf.internal.pageSize.height - 20;
    let yPosition = 30;

    await addHeader();

    // Carregar a imagem do avatar antes de adicionar a seção de dados pessoais
    let avatarData;
    if (currentPerson.file_path) {
      try {
        // avatarData = await getBase64ImageFromURL('https://upload.wikimedia.org/wikipedia/commons/6/67/User_Avatar.png');
        avatarData = await getBase64ImageFromURL(currentPerson.file_path); // Carrega a imagem do avatar
        console.log(avatarData);
      } catch (error) {
        avatarData = defaultAvatar;
        console.error('Erro ao carregar a imagem do avatar:', error);
      }
    }

    // Dados Pessoais
    yPosition = addSectionBox(pdf, 'Dados Pessoais', yPosition, (pdf, yPosition) => {
      // Avatar (imagem do usuário)
      if (avatarData) {
        pdf.addImage(avatarData, 'PNG', 180, 30, 16, 16); // Avatar
      }

      let boxHeight = 25;
      pdf.text(`Nome: ${currentPerson.first_name} ${currentPerson.last_name}`, 12, yPosition + 5);
      pdf.text(`Data de Nascimento: ${formatDate(currentPerson.birth_date)}`, 12, yPosition + 10);
      pdf.text(`Idade: ${calculateAge(currentPerson.birth_date)}`, 62, yPosition + 10);
      pdf.text(`Gênero: ${formatGender(currentPerson.gender)}`, 12, yPosition + 15);
      pdf.text(`Tipo: ${formatPersonType(currentPerson.person_type)}`, 62, yPosition + 15);
      return boxHeight;
    });

    // Documentos
    yPosition = addSectionBox(pdf, 'Documentos', yPosition, (pdf, yPosition) => {
      let boxHeight = 25;

      pdf.text(`CPF: ${currentPerson.cpf || 'Não informado'}`, 12, yPosition + 5);
      pdf.text(`RG: ${currentPerson.rg || 'Não informado'}`, 62, yPosition + 5);
      pdf.text(`CNH: ${currentPerson.cnh || 'Não informado'} - Categoria: ${currentPerson.cnh_category || 'N/A'}`, 112, yPosition + 5);
      pdf.text(`Registro de Ordem Profissional: ${currentPerson.ro || 'Não informado'}`, 12, yPosition + 10);
      pdf.text(`R.O nº: ${currentPerson.ro_number || 'Não informado'}`, 112, yPosition + 10);
      pdf.text(`Especialidade: ${formatSpeciality(currentPerson.speciality || '')}`, 12, yPosition + 15);
      return boxHeight;
    });

    // Contatos
    yPosition = addSectionBox(pdf, 'Contatos', yPosition, (pdf, yPosition) => {
      let boxHeight = 25;

      pdf.text(`Celular: ${currentPerson.mobile || 'Não informado'}`, 12, yPosition + 5);
      pdf.text(`Celular: ${currentPerson.mobile2 || 'Não informado'}`, 62, yPosition + 5);
      pdf.text(`Telefone: ${currentPerson.phone || 'Não informado'}`, 112, yPosition + 5);
      pdf.text(`Telefone: ${currentPerson.phone2 || 'Não informado'}`, 162, yPosition + 5);
      pdf.text(`Email: ${currentPerson.email || 'Não informado'}`, 12, yPosition + 10);
      pdf.text(`Email: ${currentPerson.email2 || 'Não informado'}`, 112, yPosition + 10);
      return boxHeight;
    });

    // Endereço
    yPosition = addSectionBox(pdf, 'Endereço', yPosition, (pdf, yPosition) => {
      let boxHeight = 20;
      if (currentPerson.address) {
        const address = `${currentPerson.address.street || ''}, ${currentPerson.address.number || ''}, ${currentPerson.address.complement || ''}, ${currentPerson.address.neighborhood || ''}, ${currentPerson.address.city || ''} - ${currentPerson.address.uf || ''}, CEP: ${currentPerson.address.zipcode || ''}`;
        pdf.text(address, 12, yPosition + 5);
      } else {
        pdf.text('Endereço não informado', 12, yPosition + 5);
      }
      return boxHeight;
    });

    // Observações
    yPosition = addSectionBox(pdf, 'Observações', yPosition, (pdf, yPosition) => {
      let boxHeight = 25;
      pdf.text(`Observações: ${currentPerson.observations || 'Nenhuma observação'}`, 12, yPosition + 5);
      return boxHeight;
    });

    // Consultas
    yPosition = addSectionBox(pdf, 'Consultas', yPosition, (pdf, yPosition) => {
      let boxHeight = 25;
      if (appointmentList.length > 0) {
        appointmentList.forEach((appointment, index) => {
          pdf.text(`Conulta: ${index + 1}: ${formatDate(appointment.appointment_date)} - Hora: ${formatTime(appointment.appointment_time)}`, 12, yPosition + 5 + (index * 5));
        });
      } else {
        pdf.text('Nenhum agendamento encontrado', 12, yPosition + 5);
      }
      return boxHeight + (appointmentList.length * 5);
    });

    // Verifica se precisa adicionar nova página
    if (yPosition + 20 > maxPageHeight) {
      pdf.addPage();
      currentPage++;
      yPosition = 50;
      await addHeader();
      addFooter();
    }

    addFooter();
  };

  await addPageContent();

  // Salva o PDF ou exibe no navegador
  const pdfBlob = pdf.output('blob');
  const pdfUrl = URL.createObjectURL(pdfBlob);
  window.open(pdfUrl, '_blank');
};

export default showPersonPdf;
