//sideNavBar.js

import React, { useContext } from 'react';
import { useNavigate } from 'react-router-dom';

import { Dropdown } from 'react-bootstrap';
import { AuthContext } from '../../context/auth';
import { linksConfig } from './components/linksConfig';
import './styles/SideNavBar.css';
import '../../Mobile.css';

import {
  // MdHome,
  MdArrowDownward,
  // MdGroupAdd,
  // MdGroup,
  // MdDashboard,
  // MdExitToApp,
  // MdAllInclusive,
  // MdBlockFlipped,
  // MdSchedule,
  // MdCalculate,
  // MdCurrencyExchange,
  // MdOutlineCurrencyExchange,
  // MdAddChart,
  // MdAssignmentAdd,
} from 'react-icons/md';

const SideNavBar = () => {
  const { hasRole, logout } = useContext(AuthContext);

  const navigate = useNavigate();

  const handleNavigate = (navigateTo) => {
    navigate(navigateTo);
  };

  const handleLogout = () => {
    logout();
  }

  // Filtra os links com base nas roles do usuário
  const filteredLinks = linksConfig.filter(link =>
    link.requiredRoles.some(role => hasRole(role))
  );

  return (
    <div>
      {filteredLinks.map((link, index) => {
        if (link.type === 'link') {
          return (
            <Dropdown.Item
              key={index}
              className={'sidelink'}
              onClick={link.action === 'logout' ? handleLogout : () => handleNavigate(link.navigateTo)}
            >
              {link.icon} {link.label}
            </Dropdown.Item>
          );
        } else if (link.type === 'dropdown') {
          return (
            <Dropdown key={index}>
              <Dropdown.Toggle id="dropdown-basic" className='asideLink'>
                {link.icon} {link.label} <MdArrowDownward className='icon icoDown' />
              </Dropdown.Toggle>
              <Dropdown.Menu>
                {link.subItems.map((subItem, subIndex) => {
                  if (subItem.requiredRoles.some(role => hasRole(role))) {
                    return (
                      <Dropdown.Item key={subIndex} onClick={() => handleNavigate(subItem.navigateTo)}>
                        {subItem.icon} {subItem.label}
                      </Dropdown.Item>
                    );
                  }
                  return null;
                })}
              </Dropdown.Menu>
            </Dropdown>
          );
        }
        return null;
      })}
    </div>
  );
}

export default SideNavBar;
