import React, { useState, useEffect, useContext, useRef } from 'react';
import { AuthContext } from '../../context/auth';

import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';

import './styles/Login.css';
import './styles/FooterLogin.css';
import '../../Mobile.css';

import welcomeLogo from '../../assets/img/fruitLogo.png';
import bckgVideo from '../../assets/video/lemons.mp4';
import fallbackVideo from '../../assets/video/oranges.mp4';
import fallbackImg from '../../assets/img/fallback.png';
import fallbackMobileImg from '../../assets/img/ban1.png';

const Login = () => {
  const { login } = useContext(AuthContext);

  const [loginUser, setLoginUser] = useState({
    email: '',
    password: '',
    cbForgot: false,
  });

  const [loading, setLoading] = useState(false);
  const [message, setMessage] = useState('');
  const [ellipsis, setEllipsis] = useState('.....');
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);

  const [currentYear, setCurrentYear] = useState(new Date().getFullYear());

  const videoRef = useRef();
  const [videoError, setVideoError] = useState(false);

  const handleVideoError = () => {
    setVideoError(true);
  };

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 768);
    };
    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  // Função para validar o email
  const validateEmail = (email) => {
    const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
    return emailRegex.test(email) && email.indexOf('@') > 2;
  };

  // Função para validar a senha
  const validatePassword = (password) => {
    return password.length >= 6;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (loginUser.cbForgot) {
      window.location.href = '/forgot';
      return;
    }

    if (!validateEmail(loginUser.email)) {
      setMessage('O campo email deve ser preenchido corretamente');
      return;
    } else if (!validatePassword(loginUser.password)) {
      setMessage('O campo senha deve ter ao menos 6 caracteres');
      return;
    }

    setLoading(true);

    try {
      const isAuthenticated = await login(loginUser.email, loginUser.password);
      if (isAuthenticated) {
        setMessage('Bem-Vindo');
        setLoading(false);
        return;
      }
    } catch (error) {
      if (error && error.status === 401) {
        setMessage('Credenciais incorretas');
      } else {
        setMessage('Falha ao conectar');
      }
    } finally {
      setLoading(false);
      setLoginUser({ email: '', password: '', cbForgot: false });
    }
  };

  const handleChange = (e) => {
    const { name, value, type, checked } = e.target;
    setLoginUser(prevState => ({
      ...prevState,
      [name]: type === 'checkbox' ? checked : value,
    }));

    if (name === 'cbForgot' && checked) {
      setMessage("Clique em ENVIAR");
    } else if (name === 'cbForgot' && !checked) {
      setMessage("");
    }
  };

  const ressetMessage = () => {
    setTimeout(() => {
      setMessage("");
    }, 3000);
  };

  useEffect(() => {
    if (message) {
      ressetMessage();
    }
  }, [message]);

  useEffect(() => {
    let interval;
    if (loading) {
      let count = 5;
      interval = setInterval(() => {
        setEllipsis('.'.repeat(count));
        count = count === 1 ? 5 : count - 1;
      }, 500);
    } else {
      setEllipsis('.....');
    }
    return () => clearInterval(interval);
  }, [loading]);

  return (
    <>
      <div className="loginVideoBox">
        {isMobile ? (
          <video ref={videoRef} muted autoPlay loop onError={handleVideoError}>
            <source src={fallbackVideo} type="video/mp4" />
            <img src={fallbackMobileImg} className="loginFallbackImg" alt="Fallback Mobile Image" />
          </video>
        ) : !videoError ? (
          <video ref={videoRef} muted autoPlay loop onError={handleVideoError}>
            <source src={bckgVideo} type="video/mp4" onError={() => videoRef.current.src = fallbackVideo} />
            <source src={fallbackVideo} type="video/mp4" />
          </video>
        ) : (
          <img src={fallbackImg} className="loginFallbackImg" alt="Fallback Image" />
        )}
      </div>

      <div className='login-container'>
        <div className="title">
          <img src={welcomeLogo} alt="icone da maçã" />
          <span>Bem vindo ao Nutrição App</span>
        </div>
        <div className="loginBox">
          <span> Faça seu Login </span>
          {loading && <div className="message"> Aguarde{ellipsis}</div>}
          {message && <div className="message"> {message} </div>}
          <Form onSubmit={handleSubmit} className="">
            <Form.Group>
              <Form.Label className="form-label" htmlFor="email"> Usuário </Form.Label>
              <Form.Control
                size="sm"
                type="text"
                className="form-control"
                id="email"
                name="email"
                autoComplete="email"
                placeholder="Email"
                value={loginUser.email}
                onChange={handleChange}
                required
              />

              <Form.Label className="form-label" htmlFor="password"> Senha </Form.Label>
              <Form.Control
                size="sm"
                type="password"
                className="form-control"
                id="password"
                name="password"
                placeholder="Senha"
                autoComplete="current-password"
                value={loginUser.password}
                onChange={handleChange}
                required
              />
            </Form.Group>
            <Form.Group>
              <div className="forgot">
                <div>
                  <Form.Control
                    size="sm"
                    type="checkbox"
                    className="form-control"
                    id="cbForgot"
                    name="cbForgot"
                    checked={loginUser.cbForgot}
                    onChange={handleChange}
                  />
                </div>
                <Form.Group>
                  <div><span>Esqueci a senha</span></div>
                </Form.Group>
              </div>
            </Form.Group>
            <Form.Group>
              <a className='sign' href="/sign">ou registre-se aqui</a>
            </Form.Group>

            <Form.Group className="loginBtn">
              <Button className="form-btn btn" variant="primary" type="submit" onClick={handleSubmit}> Enviar </Button>
            </Form.Group>
          </Form>
        </div>
      </div>

      <div className="footerLogin">
        <div className='year'>2007 - <span>{currentYear}</span></div> {/* Usando o ano atual */}
        <div>&copy; todos os direitos reservados</div>
        <div>powered by <span className='brand'>apit solutions</span></div>
      </div>
    </>
  );
}

export default Login;
