import React from 'react';

import '../styles/ShowTacoFood.css';

const foodPropertyLabels = {
  kcal: "Calorias",
  protein: "Proteínas",
  lipids: "Lipídeos",
  carbohydrates: "Carboidratos",
  dietary_fiber: "Fibra Alimentar",
  saturated: "Gorduras Saturadas",
  monounsaturated: "Gorduras Monoinsaturadas",
  polyunsaturated: "Gorduras Poliinsaturadas",
  fatty_acid_12_0: "Ácido Graxo 12:0",
  fatty_acid_14_0: "Ácido Graxo 14:0",
  fatty_acid_16_0: "Ácido Graxo 16:0",
  fatty_acid_18_0: "Ácido Graxo 18:0",
  fatty_acid_20_0: "Ácido Graxo 20:0",
  fatty_acid_22_0: "Ácido Graxo 22:0",
  fatty_acid_24_0: "Ácido Graxo 24:0",
  fatty_acid_14_1: "Ácido Graxo 14:1",
  fatty_acid_16_1: "Ácido Graxo 16:1",
  fatty_acid_18_1: "Ácido Graxo 18:1",
  fatty_acid_20_1: "Ácido Graxo 20:1",
  fatty_acid_18_2n6: "Ácido Graxo 18:2n6",
  fatty_acid_18_3n3: "Ácido Graxo 18:3n3",
  fatty_acid_20_4: "Ácido Graxo 20:4",
  fatty_acid_20_5: "Ácido Graxo 20:5",
  fatty_acid_22_5: "Ácido Graxo 22:5",
  fatty_acid_22_6: "Ácido Graxo 22:6",
  tryptophan: "Triptofano",
  threonine: "Treonina",
  isoleucine: "Isoleucina",
  leucine: "Leucina",
  lysine: "Lisina",
  methionine: "Metionina",
  cystine: "Cistina",
  phenylalanine: "Fenilalanina",
  tyrosine: "Tirosina",
  valine: "Valina",
  arginine: "Arginina",
  histidine: "Histidina",
  alanine: "Alanina",
  aspartic_acid: "Ácido Aspártico",
  glutamic_acid: "Ácido Glutâmico",
  glycine: "Glicina",
  proline: "Prolina",
  serine: "Serina"
};

const formatValue = (key, value) => {
  if (key === 'kcal') {
    return value !== null && value !== undefined ? `${Math.round(value)} kcal` : "N/A";
  }
  return value !== null && value !== undefined ? `${value.toFixed(2)} gr` : "N/A";
};

const DynamicFoodDetails = ({ food }) => {
  const sortedKeys = Object.keys(foodPropertyLabels).sort((a, b) => {
    const valueA = food[a] ?? 0;
    const valueB = food[b] ?? 0;
    return valueB - valueA;
  });

  return (
    <div className="boxLineContainer">
      {sortedKeys.map((key) => (
        food[key] !== null && (
          <div className="boxLine" key={key}>
            <div className="boxLabel">{foodPropertyLabels[key]}</div>
            <div className="boxValue">
              {formatValue(key, food[key])}
            </div>
          </div>
        )
      ))}
    </div>
  );
};

export default DynamicFoodDetails;
