import React, { useContext } from 'react';
import { BrowserRouter as Router, Route, Routes, Navigate } from 'react-router-dom';
import { AuthProvider, AuthContext } from './context/auth';
import ProtectedRoute from './components/ProtectedRoute/ProtectedRoute';

import Sign from './pages/Sign/sign';
import Home from './pages/Home/home';
import About from './pages/About/about';
import Contact from './pages/Contact/contact';
import Welcome from './pages/Welcome/welcome';
import Forgot from './pages/Forgot/forgot';
import ResetPassword from './pages/ResetPassword/resetPassword';
import Dashboard from './pages/Dashboard/dashboard';

import User from './pages/User/user';
import GetUser from './pages/User/components/GetUser';
import CreateUser from './pages/User/components/CreateUser';
import UpdateUser from './pages/User/components/UpdateUser';
import DeleteUser from './pages/User/components/DeleteUser';
import ShowUser from './pages/User/components/ShowUser';

import Person from './pages/Person/person';
import GetPerson from './pages/Person/components/GetPerson';
import ShowPerson from './pages/Person/components/ShowPerson';
import CreatePerson from './pages/Person/components/CreatePerson';
import UpdatePerson from './pages/Person/components/UpdatePerson';

import Company from './pages/Company/company';
import GetCompany from './pages/Company/components/GetCompany';
import ShowCompany from './pages/Company/components/ShowCompany';
import CreateCompany from './pages/Company/components/CreateCompany';
import UpdateCompany from './pages/Company/components/UpdateCompany';

import Appointment from './pages/Appointment/appointment';
import GetAppointment from './pages/Appointment/components/GetAppointment';
import CreateAppointment from './pages/Appointment/components/CreateAppointment';
import ShowAppointment from './pages/Appointment/components/ShowAppointment';
import UpdateAppointment from './pages/Appointment/components/UpdateAppointment';

import GetPatient from './pages/Patient/components/GetPatient';
import GetPersonList from './pages/Person/components/GetPersonList';

import Nutridata from './pages/Nutridata/nutridata';
import GetAllTacoFoods from './pages/Nutridata/components/getAllTacoFoods';
import ShowTacoFood from './pages/Nutridata/components/showTacoFood';

import Anthropometry from './pages/Anthropometry/anthropometry';
import CreateAnthropometry from './pages/Anthropometry/components/CreateAnthropometry';


const AccessControl1 = ["Admin"];
const AccessControl2 = ["Admin", "Manager"];
const AccessControl3 = ["Admin", "Manager", "Coordinator"];
const AccessControl4 = ["Admin", "Manager", "Coordinator", "User"];




const AppRouter = () => {

  return (
    <Router>
      <AuthProvider>
        <Routes>
          {/* Public Routes */}
          <Route exact path="/" element={<Welcome />} />
          <Route exact path="/sign" element={<Sign />} />
          <Route path="/forgot" element={<Forgot />} />
          <Route path="/resetPassword" element={<ResetPassword />} />
          <Route path="about" element={<About />} />
          <Route path="contact" element={<Contact />} />

          {/* Protected Routes */}
          <Route path="/home" element={<ProtectedRoute><Home /></ProtectedRoute>}>
            <Route path="dashboard" element={<ProtectedRoute><Dashboard /></ProtectedRoute>} />

            {/* Person Routes */}
            <Route path="person" element={<ProtectedRoute><Person /></ProtectedRoute>}>
              <Route path="get" element={<ProtectedRoute><GetPerson /></ProtectedRoute>} />
              <Route path="show/:personIdToShow" element={<ProtectedRoute role={AccessControl4}><ShowPerson /></ProtectedRoute>} />
              <Route path="create" element={<ProtectedRoute role={AccessControl4}><CreatePerson /></ProtectedRoute>} />
              <Route path="update/:personIdToUpdate" element={<ProtectedRoute role={AccessControl3}><UpdatePerson /></ProtectedRoute>} />
              <Route path="list/get" element={<ProtectedRoute role={AccessControl4}><GetPersonList /></ProtectedRoute>} />
            </Route>

            {/* User Routes */}
            <Route path="user" element={<ProtectedRoute><User /></ProtectedRoute>}>
              <Route path="get" element={<ProtectedRoute><GetUser /></ProtectedRoute>} />
              <Route path="show/:user_id" element={<ProtectedRoute><ShowUser /></ProtectedRoute>} />
              <Route path="create" element={<ProtectedRoute role={AccessControl4}><CreateUser /></ProtectedRoute>} />
              <Route path="update/:user_id" element={<ProtectedRoute role={AccessControl2}><UpdateUser /></ProtectedRoute>} />
              <Route path="delete" element={<ProtectedRoute role={AccessControl1}><DeleteUser /></ProtectedRoute>} />
            </Route>

            {/* Company Routes */}
            <Route path="company" element={<ProtectedRoute><Company /></ProtectedRoute>}>
              <Route path="get" element={<ProtectedRoute><GetCompany /></ProtectedRoute>} />
              <Route path="show/:company_id" element={<ProtectedRoute><ShowCompany /></ProtectedRoute>} />
              <Route path="create" element={<ProtectedRoute role={AccessControl3}><CreateCompany /></ProtectedRoute>} />
              <Route path="update/:company_id" element={<ProtectedRoute role={AccessControl2}><UpdateCompany /></ProtectedRoute>} />
            </Route>

            {/* Appointment Routes */}
            <Route path="appointment" element={<ProtectedRoute><Appointment /></ProtectedRoute>} >
              <Route path="get" element={<ProtectedRoute> <GetAppointment /></ProtectedRoute>} />
              <Route path="create" element={<ProtectedRoute role={AccessControl3}> <CreateAppointment /></ProtectedRoute>} />
              <Route path="show/:appointment_id" element={<ProtectedRoute role={AccessControl4}> <ShowAppointment /></ProtectedRoute>} />
              <Route path="update/:appointment_id" element={<ProtectedRoute role={AccessControl3}> <UpdateAppointment /></ProtectedRoute>} />
            </Route>

            {/* Patient Routes */}
            <Route path="patient" element={<ProtectedRoute><GetPatient /></ProtectedRoute>}>
              <Route path="get" element={<ProtectedRoute><GetPatient /></ProtectedRoute>} />
            </Route>

            {/* Anthropometry Routes */}
            <Route path="anthropometry" element={<ProtectedRoute><Anthropometry /></ProtectedRoute>}>
              <Route path="create" element={<ProtectedRoute role={AccessControl3}> <CreateAnthropometry /></ProtectedRoute>} />
            </Route>

            {/* TacoFoods */}
            <Route path="nutridata" element={<ProtectedRoute><Nutridata /></ProtectedRoute>} >
              <Route path="get" element={<ProtectedRoute> <GetAllTacoFoods /></ProtectedRoute>} />
              <Route path="get/atfd" element={<ProtectedRoute> <GetAllTacoFoods /></ProtectedRoute>} />
              <Route path="create" element={<ProtectedRoute role={AccessControl3}> <CreateAppointment /></ProtectedRoute>} />
              <Route path="show/:food_id" element={<ProtectedRoute role={AccessControl4}> <ShowTacoFood /></ProtectedRoute>} />
              <Route path="update/:food_id" element={<ProtectedRoute role={AccessControl3}> <UpdateAppointment /></ProtectedRoute>} />
            </Route>

          </Route>
          {/* Catch-All Route */}
          <Route path="*" element={<Navigate to="/home" />} />
        </Routes>
      </AuthProvider>
    </Router>
  );
};

export default AppRouter;
