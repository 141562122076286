import React, { useEffect, useContext } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { fetchAppointmentByPersonId } from '../../../redux/actions/appointmentsActions';
import { fetchPersonById } from '../../../redux/actions/personActions';
import { clearAppointment } from '../../../redux/slices/appointmentSlice';
import { useParams, useNavigate } from 'react-router-dom';
import { AuthContext } from '../../../context/auth';

import { MdEmail, MdPrint, MdExitToApp, MdEdit } from 'react-icons/md';
import '../styles/ShowPerson.css';
import '../styles/Avatar.css';
import showPersonPdf from '../../../utils/PDF/showPersonPdf';

const ShowPerson = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { personIdToShow } = useParams();

  const { appointments } = useSelector(state => state.appointments);
  const { loginUser } = useContext(AuthContext);

  const addressDetails = "Clique para ver no mapa";
  const emailDetails = "Clique para enviar mensagem";
  const mobileDetails = "Clique para enviar mensagem";
  const socialDetails = "Clique para seguir";
  const whatsappDetails = "Clique para whatsapp";

  const { currentPerson, loading, error } = useSelector(state => state.persons);

  useEffect(() => {
    if (personIdToShow) {
      dispatch(fetchPersonById(personIdToShow));
    }
  }, [dispatch, personIdToShow]);

  useEffect(() => {
    if (currentPerson && currentPerson.person_id && currentPerson.person_type) {
      dispatch(fetchAppointmentByPersonId({
        person_id: currentPerson.person_id,
        person_type: currentPerson.person_type
      }));
      dispatch(clearAppointment());
    }
  }, [dispatch, currentPerson]);

  if (loading) {
    return <div>Loading...</div>;
  }

  if (error) {
    return <div>Error: {error}</div>;
  }

  if (!currentPerson) {
    return <div>No data found for the specified person.</div>;
  }

  const handleUpdate = (e) => {
    e.preventDefault();
    navigate(`/home/person/update/${personIdToShow}`);
  };

  const handleEmail = (e) => {
    e.preventDefault();
    alert("Email");
  };

  const handlePrint = (currentPerson, appointmentList, appointments) => {
    showPersonPdf(currentPerson, appointmentList, appointments);

  };


  const handleClickBack = (e) => {
    e.preventDefault();
    navigate(`/home/person/get`);
  };

  const handleShowAppointmentByPerson = (appointment_id) => {
    navigate(`/home/appointment/show/${appointment_id}`);
  };

  const calculateAge = (birthDateString) => {
    const today = new Date();
    const birthDate = new Date(birthDateString);
    let years = today.getFullYear() - birthDate.getFullYear();
    let months = today.getMonth() - birthDate.getMonth();
    let days = today.getDate() - birthDate.getDate();

    if (days < 0) {
      months--;
      const lastMonth = new Date(today.getFullYear(), today.getMonth(), 0);
      days += lastMonth.getDate();
    }

    if (months < 0) {
      years--;
      months += 12;
    }

    return `${pluralize(years, 'Ano', 'Anos')} ${pluralize(months, 'mês', 'meses')} e ${pluralize(days, 'dia', 'dias')}`;
  };

  const pluralize = (count, singular, plural) => {
    return `${count} ${count === 1 ? singular : plural}`;
  };

  const formatDate = (dateString) => {
    if (!dateString) return 'Data inválida';
    const date = new Date(dateString);
    if (isNaN(date)) return 'Data inválida';
    const day = date.getDate().toString().padStart(2, '0');
    const month = (date.getMonth() + 1).toString().padStart(2, '0');
    const year = date.getFullYear();
    return `${day}/${month}/${year}`;
  };

  const formatTime = (timeString) => {
    if (!timeString) return 'Hora inválida';
    const timeParts = timeString.split(':');
    if (timeParts.length < 2) return 'Hora inválida';
    const [hour, minute] = timeParts;
    return `${hour.padStart(2, '0')}:${minute.padStart(2, '0')}`;
  };

  const formatGender = (gender) => {
    switch (gender) {
      case 'female':
        return 'Feminino';
      case 'male':
        return 'Masculino';
      case 'others':
        return 'Outros';
      default:
        return 'Não especificado';
    }
  };

  const formatPersonType = (person_type) => {
    switch (person_type) {
      case 'user':
        return 'Usuário';
      case 'nutritionist':
        return 'Nutricionista';
      case 'doctor':
        return 'Médico';
      case 'employee':
        return 'Colaborador';
      case 'supplier':
        return 'Fornecedor';
      case 'professional':
        return 'Profissional';
      case 'partner':
        return 'Parceiro';
      case 'owner':
        return 'Proprietário';
      case 'patient':
        return 'Paciente';
      case 'other':
        return 'Registro';
      default:
        return '';
    }
  };

  const formatSpeciality = (speciality) => {
    switch (speciality) {
      case 'nutritionist':
        return 'Nutrição';
      case 'general_doctor':
        return 'Clínico Geral';
      case 'physiotherapist':
        return 'Fisioterapia';
      default:
        return '';
    }
  };

  const formatMobileNumber = (mobile) => {
    if (!mobile) return '';
    return mobile.replace(/\D/g, '');
  };

  const hasRole = (role) => loginUser.roles?.includes(role);

  const personData = currentPerson;
  const appointmentList = appointments.data || [];

  return (
    <>
      <h3>{formatPersonType(personData?.person_type)} -  Detalhes</h3>
      <form className="boxShowPerson">
        <div className="boxTop">
          <div className="silhuette">
            <img id="avatar" className="cadphoto" src={personData?.file_path || "/assets/uploads/img/default_avatar.png"} alt="Person" />
          </div>
          <div className="boxTopB">
            <div className="box-line">
              <div className="boxCol1">
                <label htmlFor="first_name">Nome Completo:</label>
                <span className='spanData'>{personData?.first_name} {personData?.last_name}</span>
              </div>
            </div>
            <div className="box-line">
              <div className="boxCol1">
                <label htmlFor="birth_date">Data de Nascimento:</label>
                <span className='spanData'>{formatDate(personData?.birth_date)}</span>
              </div>
              <div className="boxCol1">
                <label htmlFor="age">Idade:</label>
                <span className='spanData'>{calculateAge(personData?.birth_date)}</span>
              </div>
              <div className="boxCol1">
                <label htmlFor="gender">Gênero:</label>
                <span className='spanData'>{formatGender(personData?.gender)}</span>
              </div>
            </div>
          </div>
        </div>

        <h3>Documentos</h3>
        <div className="box-line">
          <div className="boxCol1">
            <label htmlFor="ro">Registro de Ordem Profissional:</label>
            <span className='spanData'>{personData?.ro}</span>
          </div>
          <div className="boxCol1">
            <label htmlFor="ro_number">R.O nº:</label>
            <span className='spanData'>{personData?.ro_number}</span>
          </div>
          <div className="boxCol1">
            <label htmlFor="speciality">Especialidade:</label>
            <span className='spanData'>{formatSpeciality(personData?.speciality)}</span>
          </div>
        </div>
        <div className="box-line">
          <div className="boxCol1">
            <label htmlFor="rg">R.G:</label>
            <span className='spanData'>{personData?.rg}</span>
          </div>
          <div className="boxCol1">
            <label htmlFor="cpf">CPF:</label>
            <span className='spanData'>{personData?.cpf}</span>
          </div>
          <div className="boxCol1">
            <label htmlFor="rgi">R.G.I:</label>
            <span className='spanData'>{personData?.rgi}</span>
          </div>
        </div>
        <div className="box-line">
          <div className="boxCol1">
            <label htmlFor="cnh">CNH:</label>
            <span className='spanData'>{personData?.cnh}</span>
          </div>
          <div className="boxCol1">
            <label htmlFor="cnh_category">CNH Categoria:</label>
            <span className='spanData'>{personData?.cnh_category}</span>
          </div>
          <div className="boxCol1">

          </div>
        </div>

        <h3>Contatos</h3>
        <div className="box-line">
          <div className="boxCol1">
            <label htmlFor="email">Email principal:</label>
            <span className='spanData'><a data-tip={emailDetails} className='spanData' href={`mailto:${personData?.email}`}>{personData?.email}</a></span>
          </div>
          <div className="boxCol1">
            <label htmlFor="email2">Email secundário:</label>
            <span className='spanData'><a data-tip={emailDetails} className='spanData' href={`mailto:${personData?.email2}`}>{personData?.email2}</a></span>
          </div>
        </div>

        <div className="box-line">
          <div className="boxCol1">
            <label htmlFor="mobile">Celular:</label>
            <span className='spanData'>
              <a data-tip={whatsappDetails} href={`https://api.whatsapp.com/send?phone=${formatMobileNumber(personData?.mobile)}`}
                target="_blank"
                rel="noopener noreferrer">{personData?.mobile}</a>
            </span>
          </div>
          <div className="boxCol1">
            <label htmlFor="mobile2">Celular:</label>
            <span className='spanData'>
              <a data-tip={whatsappDetails} href={`https://api.whatsapp.com/send?phone=${formatMobileNumber(personData?.mobile2)}`}
                target="_blank"
                rel="noopener noreferrer">{personData?.mobile2}</a>
            </span>
          </div>
          <div className="boxCol1">
            <label htmlFor="phone">Telefone:</label>
            <span className='spanData'>{personData?.phone}</span>
          </div>
          <div className="boxCol1">
            <label htmlFor="phone2">Telefone:</label>
            <span className='spanData'>{personData?.phone2}</span>
          </div>
        </div>

        <div className="box-line">
          <div className="boxCol1">
            <label htmlFor="social_media1">Social Mídia: </label>
            <span className='spanData'><a data-tip={socialDetails} href={`https://${personData?.social_media1}`}
              target="_blank"
              rel="noopener noreferrer">{personData?.social_media1}</a>
            </span>
          </div>
          <div className="boxCol1">
            <label htmlFor="social_media2">Social Mídia 2: </label>
            <span className='spanData'><a data-tip={socialDetails} href={`https://${personData?.social_media2}`}
              target="_blank"
              rel="noopener noreferrer">{personData?.social_media2}</a>
            </span>
          </div>
          <div className="boxCol1">
            <label htmlFor="social_media3">Social Mídia 3: </label>
            <span className='spanData'><a data-tip={socialDetails} href={`https://${personData?.social_media3}`}
              target="_blank"
              rel="noopener noreferrer">{personData?.social_media3}</a>
            </span>
          </div>
        </div>

        <h3>Endereço</h3>
        <div className="boxCol1">
          {/* <label className='spanData' htmlFor="street">Logradouro:</label> */}
          <span>
            <a
              data-tip={addressDetails}
              href={`https://www.google.com/maps?q=${personData?.address?.street} + ${personData?.address?.number}`}
              target="_blank"
              rel="noopener noreferrer">
              {personData?.address?.street}, {personData?.address?.number} - {personData?.address?.complement || ""} - {personData?.address?.neighborhood} - {personData?.address?.city} - {personData?.address?.uf} - {personData?.address?.zipcode}
            </a>
          </span>
        </div>

        <h3>Agendamentos / Consultas</h3>
        <div className="box-line">
          {appointmentList.length > 0 ? (
            <>
              <div className="boxCol1">
                <label htmlFor="last_appointment">Data da última consulta:</label>
                <span className='spanData'>{formatDate(appointmentList[0].appointment_date)}</span>
              </div>
            </>
          ) : (
            <>
              <div className="boxCol1">
                <label htmlFor="last_appointment">Data da última consulta:</label>
                <span className='spanData' id="last-appointment">Nenhuma consulta encontrada</span>
              </div>
            </>
          )}
        </div>
        <div className="box-line">
          {appointmentList.map((appointment) => (
            <a onClick={() => handleShowAppointmentByPerson(appointment.appointment_id)} key={appointment.appointment_id}>
              <div className="boxAppointment" id="last-appointment">
                <p><span>Data: </span><label>{formatDate(appointment.appointment_date)}</label></p>
                <p>Hora: {formatTime(appointment.appointment_time)}</p>
                <p>Profissional: {appointment.professional_name}</p>
                <p>Status: {appointment.status}</p>
              </div>
            </a>
          ))}
        </div>

        <div className="boxButtons1">
          <button className='showBoxBtn' type="button" onClick={handleEmail}>
            <MdEmail className='sideIcoBtn' />
            <span className='sideBtnSpan'>Email</span>
          </button>
        </div>
        <div className="boxButtons2">
          <button className='showBoxBtn' type="button" onClick={() => handlePrint(currentPerson, appointmentList, appointments)}>
            <MdPrint className='sideIcoBtn' />
            <span className='sideBtnSpan'>Imprimir</span>
          </button>
        </div>
        <div className="boxButtons3">
          {(hasRole('Manager') || hasRole('Admin')) && (
            <button className='showBoxBtn' type="button" onClick={handleUpdate}>
              <MdEdit className='sideIcoBtn' />
              <span className='sideBtnSpan'>Editar</span>
            </button>
          )}
        </div>
        <div className="boxButtons4">
          <button className='showBoxBtn' type="button" onClick={handleClickBack}>
            <MdExitToApp className='sideIcoBtn' />
            <span className='sideBtnSpan'>Voltar</span>
          </button>
        </div>
      </form>
    </>
  );
};

export default ShowPerson;
