// Pagination.js
import React from 'react';
import PropTypes from 'prop-types';
import './styles/Pagination.css';

const Pagination = ({ totalItems, itemsPerPage, currentPage, paginate }) => {
  const totalPages = Math.ceil(totalItems / itemsPerPage);
  const startPage = Math.max(1, currentPage - 2);
  const endPage = Math.min(totalPages, currentPage + 2);

  const pages = [];
  for (let i = startPage; i <= endPage; i++) {
    pages.push(i);
  }

  const getPageButtonClass = (index) => (currentPage === index + 1 ? 'active' : '');

  return (
    <div className="pagination">
      {currentPage > 3 && (
        <>
          <button type="button" onClick={() => paginate(1)} className={getPageButtonClass(0)}>1</button>
          <span className='paginationSeparator'>...</span>
        </>
      )}
      {pages.map(page => (
        <button type="button" key={page} onClick={() => paginate(page)} className={getPageButtonClass(page - 1)}>
          {page}
        </button>
      ))}
      {currentPage < totalPages - 2 && (
        <>
          <span className='paginationSeparator'>...</span>
          <button type="button" onClick={() => paginate(totalPages)} className={getPageButtonClass(totalPages - 1)}>{totalPages}</button>
        </>
      )}
    </div>
  );
};

Pagination.propTypes = {
  totalItems: PropTypes.number.isRequired,
  itemsPerPage: PropTypes.number.isRequired,
  currentPage: PropTypes.number.isRequired,
  paginate: PropTypes.func.isRequired,
};

export default Pagination;
