import React, { useContext } from 'react'
import { AuthContext } from '../../context/auth';
import { MdPlayCircle } from 'react-icons/md';
function Logout() {
  const { logout } = useContext(AuthContext);

  const handleLogout = () => {
    logout();
  }

  return (
    <button className='btnLogout' onClick={handleLogout}><div>logout</div> <MdPlayCircle className="icons" /></button>
  )
}

export default Logout