import React from 'react';
import { Outlet } from 'react-router-dom';

import './styles/Home.css';
import '../../Mobile.css';

import Nav from '../../components/NavBar/components/Nav';
import SideNavBar from '../../components/SideNavBar/sideNavBar';
import fallbackImg from '../../assets/img/fallback.png';



const Home = () => {

  return (
    <>
      <img className='fallbackImgHome' src={fallbackImg} alt="frutas" />
      <div className="fallbackFace" />
      <div className="main-container">
        <nav className="navbar"><Nav /></nav>
        <aside className='sidebar'><SideNavBar /></aside>
        <main className="content"><Outlet /></main>
        <footer className="footer" />
      </div>
    </>
  );
};

export default Home;
