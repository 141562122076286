import React, { useState, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import { fetchUserById, updateUserAsync } from '../../../redux/actions/userActions';
import ModalForms from '../../../components/ConfirmationModal/ModalForms';

import '../styles/CreateUser.css';

function UpdateUser() {
  const { user_id } = useParams();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [showModal, setShowModal] = useState(false);
  const [message, setMessage] = useState('');
  const [loading, setLoading] = useState(true);

  const { currentUser } = useSelector((state) => state.users);

  const [initialValues, setInitialValues] = useState({
    email: '',
    first_name: '',
    last_name: '',
    roles: ''  // This should be a string representing the role
  });

  useEffect(() => {
    if (user_id) {
      dispatch(fetchUserById(user_id)).then(() => setLoading(false));
    }
  }, [dispatch, user_id]);

  useEffect(() => {
    if (currentUser) {
      setInitialValues({
        email: currentUser.email || '',
        first_name: currentUser.first_name || '',
        last_name: currentUser.last_name || '',
        roles: currentUser.roles || ''  // Ensure this matches the role name in your DB
      });
    }
  }, [currentUser]);

  const validationSchema = Yup.object({
    first_name: Yup.string().required('Primeiro nome é necessário'),
    last_name: Yup.string().required('Sobrenome é necessário'),
    email: Yup.string().email('Email inválido').required('Email é necessário'),
    roles: Yup.string().required('Permissão é necessária'),
  });

  const handleSubmit = async (values, { setSubmitting }) => {
    try {
      const formattedValues = {
        email: values.email,
        first_name: values.first_name,
        last_name: values.last_name,
        role: values.roles
      };

      const resultAction = await dispatch(updateUserAsync({ user_id: user_id, userFormData: formattedValues }));

      if (updateUserAsync.fulfilled.match(resultAction)) {
        const message = resultAction.payload?.message || 'Usuário atualizado com sucesso!';
        setMessage(message);
        setShowModal(true);
        setTimeout(() => {
          setShowModal(false);
          navigate('/home/user/get');
        }, 2000);
      } else {
        const errorMsg = resultAction.payload?.message || 'Erro ao atualizar usuário. Tente novamente mais tarde.';
        setMessage(errorMsg);
        setShowModal(true);
        setTimeout(() => {
          setShowModal(false);
        }, 2500);
      }
    } catch (error) {
      const errorMsg = error.message || 'Erro ao atualizar usuário. Tente novamente mais tarde.';
      setMessage(errorMsg);
      setShowModal(true);
      setTimeout(() => {
        setShowModal(false);
      }, 2500);
    } finally {
      setSubmitting(false);
    }
  };



  const handleBack = (e) => {
    e.preventDefault();
    navigate('/home/user/get');
  };

  if (loading) {
    return <div>Carregando...</div>;
  }

  return (
    <>
      <h3>Atualizar Usuário</h3>
      <Formik
        initialValues={initialValues}
        enableReinitialize={true}
        validationSchema={validationSchema}
        onSubmit={handleSubmit}
      >
        {({ isSubmitting }) => (
          <Form className='userForm'>
            <div className="user-form-group">
              <label htmlFor="email">Email</label>
              <Field
                type="email"
                name="email"
                id="email"
                placeholder="Digite o email"
              />
              <ErrorMessage name="email" component="div" className="error-message" />
            </div>

            <div className="user-form-group">
              <label htmlFor="first_name">Primeiro Nome</label>
              <Field
                type="text"
                name="first_name"
                id="first_name"
                placeholder="Digite o primeiro nome"
              />
              <ErrorMessage name="first_name" component="div" className="error-message" />
            </div>

            <div className="user-form-group">
              <label htmlFor="last_name">Sobrenome</label>
              <Field
                type="text"
                name="last_name"
                id="last_name"
                placeholder="Digite o sobrenome"
              />
              <ErrorMessage name="last_name" component="div" className="error-message" />
            </div>

            <div className="user-form-group">
              <label htmlFor="roles">Permissão</label>
              <Field as="select" className="select" name="roles" id="roles">
                <option value="">Selecione uma permissão</option>
                <option value="Admin">Administrador</option>
                <option value="Manager">Gerente</option>
                <option value="Coordinator">Coordenador</option>
                <option value="User">Usuário</option>
              </Field>
              <ErrorMessage name="roles" component="div" className="error-message" />
            </div>

            <div className="userButtons">
              <button type="submit" className="sendBtn" disabled={isSubmitting}>Editar</button>
              <button type="button" className="backBtn" onClick={handleBack}>Cancelar</button>
            </div>
          </Form>
        )}
      </Formik>
      {showModal && (
        <ModalForms
          message={message}
          onConfirm={() => setShowModal(false)}
        />
      )}
    </>
  );
}

export default UpdateUser;
