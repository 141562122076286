import { createSlice } from '@reduxjs/toolkit';
import {
  fetchCompanies,
  fetchCompanyById,
  deleteCompanyById,
  updateCompanyById,
  createNewCompany
} from '../actions/companyActions';

const companySlice = createSlice({
  name: 'companies',
  initialState: {
    companies: [],
    currentCompany: null,
    loading: false,
    error: null,
  },
  reducers: {
    setCurrentCompany: (state, action) => {
      state.currentCompany = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchCompanies.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(fetchCompanies.fulfilled, (state, action) => {
        state.loading = false;
        state.companies = action.payload;
      })
      .addCase(fetchCompanies.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      })
      .addCase(fetchCompanyById.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(fetchCompanyById.fulfilled, (state, action) => {
        state.loading = false;
        console.log("Redux State Update - Company Data:", action.payload); // Add this line
        state.currentCompany = action.payload;
      })
      .addCase(fetchCompanyById.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      })
      .addCase(deleteCompanyById.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(deleteCompanyById.fulfilled, (state, action) => {
        state.loading = false;
        state.companies = state.companies.filter(company => company.id !== action.payload);
      })
      .addCase(deleteCompanyById.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      })
      .addCase(updateCompanyById.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(updateCompanyById.fulfilled, (state, action) => {
        state.loading = false;
        if (action.payload) {
          state.companies = state.companies.map(company =>
            company.id === action.payload.id ? action.payload : company);
          state.currentCompany = action.payload; // Update `currentCompany` as well
        }
      })
      .addCase(updateCompanyById.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      })
      .addCase(createNewCompany.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(createNewCompany.fulfilled, (state, action) => {
        state.loading = false;
        state.companies.push(action.payload);
      })
      .addCase(createNewCompany.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      });
  },
});

export const { setCurrentCompany } = companySlice.actions;
export default companySlice.reducer;
