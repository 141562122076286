import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { createNewAnthropometry } from '../../../redux/actions/anthropometryActions';
import { fetchPatients, fetchAllProfessionals } from '../../../redux/actions/relatedEntitiesActions';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import Select from 'react-select';
import Accordion from '../../../components/Accordion/Accordion';

import ModalForms from '../../../components/ConfirmationModal/ModalForms';

import '../styles/CreateAnthropometry.css';

const CreateAnthropometry = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [error, setError] = useState(null);
  const [message, setMessage] = useState('');
  const [showModal, setShowModal] = useState(false);
  const [selectedPatientGender, setSelectedPatientGender] = useState('');

  const { patients, professionals, loading, error: loadError } = useSelector((state) => state.relatedEntities);

  useEffect(() => {
    dispatch(fetchPatients());
    dispatch(fetchAllProfessionals());
  }, [dispatch]);



  const initialValues = {
    patient_id: '',
    nutritionist_id: '',
    height: '',
    current_weight: '',
    desired_weight: '',
    measurement_date: '',
    bmi_classification_code: '',
  };

  const validationSchema = Yup.object({
    patient_id: Yup.number().required('Paciente é necessário'),
    nutritionist_id: Yup.number().required('Nutricionista é necessário'),
    height: Yup.number().required('Altura é necessária').positive('Altura deve ser positiva'),
    current_weight: Yup.number().required('Peso atual é necessário').positive('Peso deve ser positivo'),
    desired_weight: Yup.number().required('Peso desejado é necessário').positive('Peso deve ser positivo'),
    measurement_date: Yup.date().required('Data da medição é necessária'),
    bmi_classification_code: Yup.string().required('Classificação IMC é necessária'),
    patient_group_id: Yup.string().required('Grupo do Paciente é necessário'),
  });

  // Função para capturar o paciente selecionado e seu gênero
  const handlePatientChange = (option, setFieldValue) => {
    setFieldValue('patient_id', option ? option.value : '');

    // Acessando corretamente a propriedade person_gender
    const selectedPatient = patients.find(p => p.person_id === option.value);
    setSelectedPatientGender(selectedPatient ? selectedPatient.gender : null);

    // Exibindo o gênero correto no log
    console.log(`Paciente Gênero: ${selectedPatient ? selectedPatient.person_gender : 'Não encontrado'}`);
  };

  useEffect(() => {
    console.log(`Paciente Gênero: ${selectedPatientGender}`);
  }, [selectedPatientGender]);

  const handleSubmit = async (values, { setSubmitting, resetForm }) => {
    try {
      const response = await dispatch(createNewAnthropometry(values)).unwrap();
      setMessage("Antropometria criada com sucesso!");
      setShowModal(true);
      setTimeout(() => {
        setShowModal(false);
        resetForm();
        navigate('/home/anthropometry/get');
      }, 2000);
    } catch (error) {
      console.error("Erro ao enviar formulário:", error);
      const errorMsg = (error.response?.data?.message)
        ? error.response.data.message
        : 'Erro ao enviar formulário. Tente novamente mais tarde.';
      setMessage(errorMsg);
      setShowModal(true);
      setTimeout(() => {
        setShowModal(false);
      }, 2500);
    }
    setSubmitting(false);
  };

  const selectOptions = (items, labelKey = 'name') => items.map(item => ({
    value: item.person_id,
    label: item[labelKey],
  }));

  if (loading) {
    return <div>Carregando...</div>;
  }

  if (loadError) {
    return <div>Erro ao carregar dados: {loadError}</div>;
  }

  function onlyNumbers(e, setFieldValue, fieldName) {
    const { value } = e.target;

    // Se o usuário digitar uma vírgula, substituímos por um ponto
    if (e.key === ',') {
      e.preventDefault(); // Evita que a vírgula seja inserida
      setFieldValue(fieldName, `${value}.`); // Adiciona o ponto no lugar da vírgula
      return;
    }

    // Permitir números, backspace, delete, setas, ponto e tab
    if (
      !(e.key >= '0' && e.key <= '9') && // Números
      e.key !== 'Backspace' &&
      e.key !== 'Delete' &&
      e.key !== 'ArrowLeft' &&
      e.key !== 'ArrowRight' &&
      e.key !== '.' &&
      e.key !== 'Tab'
    ) {
      e.preventDefault();
      return;
    }

    // Verificar se já existe um ponto e evitar outro
    if (e.key === '.' && value.includes('.')) {
      e.preventDefault();
      return;
    }

    // Limitar a duas casas decimais
    if (value.includes('.')) {
      const [integerPart, decimalPart] = value.split('.');
      if (decimalPart.length >= 2 && e.key !== 'Backspace' && e.key !== 'Delete') {
        e.preventDefault(); // Bloquear mais de duas casas decimais
      }
    }
  }



  function handleKeyDown(e) {
    if (e.key === 'Enter') {
      e.preventDefault();
    }
  }

  return (
    <>
      <div>
        <h3>Nova Antropometria</h3>
        <Formik
          initialValues={initialValues}
          validationSchema={validationSchema}
          onSubmit={handleSubmit}
          onKeyDown={handleKeyDown}
        >
          {({ setFieldValue, isSubmitting }) => (
            <Form className='anthropometryForm'>
              <div className="anthropometryMain">
                <div className="anthropometry-colA">
                  <div className="anthropometry-form-group">
                    <label htmlFor="patient_id">Paciente:</label>
                    <Select
                      name="patient_id"
                      options={patients.map(patient => ({
                        value: patient.person_id,
                        label: patient.name,
                      }))}
                      onChange={option => handlePatientChange(option, setFieldValue)}
                      classNamePrefix="custom-select"
                      placeholder="Selecione o paciente"
                    />
                    <ErrorMessage name="patient_id" component="div" className="error-message" />
                  </div>

                  <div className="anthropometry-form-group">
                    <label htmlFor="nutritionist_id">Nutricionista:</label>
                    <Select
                      name="nutritionist_id"
                      options={selectOptions(professionals, 'name')}
                      onChange={option => setFieldValue('nutritionist_id', option ? option.value : '')}
                      classNamePrefix="custom-select"
                      placeholder="Selecione o nutricionista"
                    />
                    <ErrorMessage name="nutritionist_id" component="div" className="error-message" />
                  </div>
                  <div className="anthropometry-form-group">
                    <label htmlFor="patient_group_id">Grupo do Paciente:</label>
                    <Field as="select" name="patient_group_id" id="patient_group_id">
                      <option value="">Selecione</option>
                      <option value="1">Lactente</option>
                      <option value="2">Criança</option>
                      <option value="3">Adulto</option>
                      <option value="4">Idoso</option>
                      <option value="5">Gestante</option>
                      <option value="6">Lactante</option>
                    </Field>
                    <ErrorMessage name="patient_group_id" component="div" className="error-message" />
                  </div>
                  <div className="anthropometry-form-group">
                    <label htmlFor="height">Altura (cm):</label>
                    <Field type="number" name="height" id="height" onKeyDown={(e) => onlyNumbers(e, setFieldValue, 'height')} />
                    <ErrorMessage name="height" component="div" className="error-message" />
                  </div>
                  <div className="anthropometry-form-group">
                    <label htmlFor="estimated_height">Altura Estimada (cm):</label>
                    <Field type="number" name="estimated_height" id="estimated_height" onKeyDown={(e) => onlyNumbers(e, setFieldValue, 'estimated_height')} />
                    <ErrorMessage name="estimated_height" component="div" className="error-message" />
                  </div>
                  <div className="anthropometry-form-group">
                    <label htmlFor="current_weight">Peso Atual (kg):</label>
                    <Field type="number" name="current_weight" id="current_weight" onKeyDown={(e) => onlyNumbers(e, setFieldValue, 'current_weight')} />
                    <ErrorMessage name="current_weight" component="div" className="error-message" />
                  </div>

                  <div className="anthropometry-form-group">
                    <label htmlFor="desired_weight">Peso Desejado (kg):</label>
                    <Field type="number" name="desired_weight" id="desired_weight" onKeyDown={(e) => onlyNumbers(e, setFieldValue, 'desired_weight')} />
                    <ErrorMessage name="desired_weight" component="div" className="error-message" />
                  </div>

                  <div className="anthropometry-form-group">
                    <label htmlFor="estimated_weight">Peso Estimado (kg):</label>
                    <Field type="number" name="estimated_weight" id="estimated_weight" onKeyDown={(e) => onlyNumbers(e, setFieldValue, 'estimated_weight')} />
                    <ErrorMessage name="estimated_weight" component="div" className="error-message" />
                  </div>

                  <div className="anthropometry-form-group">
                    <label htmlFor="estimated_current_weight">Peso Atual Estimado (kg):</label>
                    <Field type="number" name="estimated_current_weight" id="estimated_current_weight" onKeyDown={(e) => onlyNumbers(e, setFieldValue, 'estimated_current_weight')} />
                    <ErrorMessage name="estimated_current_weight" component="div" className="error-message" />
                  </div>

                  <div className="anthropometry-form-group">
                    <label htmlFor="usual_weight">Peso Habitual (kg):</label>
                    <Field type="number" name="usual_weight" id="usual_weight" onKeyDown={(e) => onlyNumbers(e, setFieldValue, 'usual_weight')} />
                    <ErrorMessage name="usual_weight" component="div" className="error-message" />
                  </div>

                  <div className="anthropometry-form-group">
                    <label htmlFor="measurement_date">Data da Medição:</label>
                    <Field type="date" name="measurement_date" id="measurement_date" />
                    <ErrorMessage name="measurement_date" component="div" className="error-message" />
                  </div>

                  <div className="anthropometry-form-group">
                    <label htmlFor="overweight_duration">Tempo de Sobrepeso: (ano/meses)</label>
                    <Field type="number" name="overweight_duration" id="overweight_duration" onKeyDown={(e) => onlyNumbers(e, setFieldValue, 'overwight_duration')} />
                    <ErrorMessage name="overweight_duration" component="div" className="error-message" />
                  </div>

                  {/* Campos Condicionais - Paciente Feminino */}
                  {selectedPatientGender === 'female' && (
                    <>
                      <div className="anthropometry-form-group">
                        <label htmlFor="last_period_date">Data da Última Menstruação:</label>
                        <Field type="date" name="last_period_date" id="last_period_date" />
                        <ErrorMessage name="last_period_date" component="div" className="error-message" />
                      </div>

                      <div className="anthropometry-form-group">
                        <label htmlFor="ppg_weight">Peso Pré Gestacional - PPG (kg):</label>
                        <Field type="number" name="ppg_weight" id="ppg_weight" onKeyDown={(e) => onlyNumbers(e, setFieldValue, 'ppg_weight')} />
                        <ErrorMessage name="ppg_weight" component="div" className="error-message" />
                      </div>
                    </>
                  )}


                  <div className="anthropometry-form-group">
                    <label htmlFor="bmi_classification_code">Classificação IMC:</label>
                    <Field as="select" name="bmi_classification_code" id="bmi_classification_code">
                      <option value="">Selecione</option>
                      <option value="underweight">Abaixo do Peso</option>
                      <option value="normal">Normal</option>
                      <option value="overweight">Sobrepeso</option>
                      <option value="obese">Obesidade</option>
                    </Field>
                    <ErrorMessage name="bmi_classification_code" component="div" className="error-message" />
                  </div>

                </div>

                <div className="anthropometry-colB">
                  <Accordion title={"Circunferências"} className="accordion" >
                    <div className="anthropometry-form-group">
                      <label htmlFor="head_circumference">Circunferência da Cabeça (cm):</label>
                      <Field type="number" name="head_circumference" id="head_circumference" onKeyDown={(e) => onlyNumbers(e, setFieldValue, 'head_circumference')} />
                      <ErrorMessage name="head_circumference" component="div" className="error-message" />
                    </div>
                    <div className="anthropometry-form-group">
                      <label htmlFor="head_circumference">Circunferência do Braço (cm):</label>
                      <Field type="number" name="arm_circumference" id="arm_circumference" onKeyDown={(e) => onlyNumbers(e, setFieldValue, 'arm_circumference')} />
                      <ErrorMessage name="arm_circumference" component="div" className="error-message" />
                    </div>
                    <div className="anthropometry-form-group">
                      <label htmlFor="waist_circumference">Circunferência da Cintura (cm):</label>
                      <Field type="number" name="waist_circumference" id="waist_circumference" onKeyDown={(e) => onlyNumbers(e, setFieldValue, 'waist_circumference')} />
                      <ErrorMessage name="waist_circumference" component="div" className="error-message" />
                    </div>
                    <div className="anthropometry-form-group">
                      <label htmlFor="chest_circumference">Circunferência do Peito (cm):</label>
                      <Field type="number" name="chest_circumference" id="chest_circumference" onKeyDown={(e) => onlyNumbers(e, setFieldValue, 'chest_circumference')} />
                      <ErrorMessage name="chest_circumference" component="div" className="error-message" />
                    </div>
                    <div className="anthropometry-form-group">
                      <label htmlFor="hip_circumference">Circunferência do Quadril (cm):</label>
                      <Field type="number" name="hip_circumference" id="hip_circumference" onKeyDown={(e) => onlyNumbers(e, setFieldValue, 'hip_circumference')} />
                      <ErrorMessage name="hip_circumference" component="div" className="error-message" />
                    </div>
                    <div className="anthropometry-form-group">
                      <label htmlFor="gluteal_max_circumference">Circunferência Máxima do Glúteo (cm):</label>
                      <Field type="number" name="gluteal_max_circumference" id="gluteal_max_circumference" onKeyDown={(e) => onlyNumbers(e, setFieldValue, 'gluteal_max_circumference')} />
                      <ErrorMessage name="gluteal_max_circumference" component="div" className="error-message" />
                    </div>
                    <div className="anthropometry-form-group">
                      <label htmlFor="thigh_max_circumference">Circunferência Máxima da Coxa (cm):</label>
                      <Field type="number" name="thigh_max_circumference" id="thigh_max_circumference" onKeyDown={(e) => onlyNumbers(e, setFieldValue, 'thigh_max_circumference')} />
                      <ErrorMessage name="thigh_max_circumference" component="div" className="error-message" />
                    </div>
                    <div className="anthropometry-form-group">
                      <label htmlFor="forearm_circumference">Circunferência Máxima do Antebraço (cm):</label>
                      <Field type="number" name="forearm_circumference" id="forearm_circumference" onKeyDown={(e) => onlyNumbers(e, setFieldValue, 'forearm_circumference')} />
                      <ErrorMessage name="forearm_circumference" component="div" className="error-message" />
                    </div>
                    <div className="anthropometry-form-group">
                      <label htmlFor="wrist_circumference">Circunferência do Pulso (cm):</label>
                      <Field type="number" name="wrist_circumference" id="wrist_circumference" onKeyDown={(e) => onlyNumbers(e, setFieldValue, 'wrist_circumference')} />
                      <ErrorMessage name="wrist_circumference" component="div" className="error-message" />
                    </div>
                    <div className="anthropometry-form-group">
                      <label htmlFor="calf_circumference">Circunferência da Panturrilha (cm):</label>
                      <Field type="number" name="calf_circumference" id="calf_circumference" onKeyDown={(e) => onlyNumbers(e, setFieldValue, 'calf_circumference')} />
                      <ErrorMessage name="calf_circumference" component="div" className="error-message" />
                    </div>
                    <div className="anthropometry-form-group">
                      <label htmlFor="ankle_circumference">Circunferência do Tornozelo (cm):</label>
                      <Field type="number" name="ankle_circumference" id="ankle_circumference" onKeyDown={(e) => onlyNumbers(e, setFieldValue, 'ankle_circumference')} />
                      <ErrorMessage name="ankle_circumference" component="div" className="error-message" />
                    </div>
                    <div className="anthropometry-form-group">
                      <label htmlFor="abdominal_circumference">Circunferência Abdominal (cm):</label>
                      <Field type="number" name="abdominal_circumference" id="abdominal_circumference" onKeyDown={(e) => onlyNumbers(e, setFieldValue, 'abdominal_circumference')} />
                      <ErrorMessage name="abdominal_circumference" component="div" className="error-message" />
                    </div>
                  </Accordion>
                  <Accordion title={"Braços"} className="accordion" >
                    <div className="anthropometry-form-group">
                      <label htmlFor="left_arm">Braço Esquerdo (cm):</label>
                      <Field type="number" name="left_arm" id="left_arm" onKeyDown={(e) => onlyNumbers(e, setFieldValue, 'left_arm')} />
                      <ErrorMessage name="left_arm" component="div" className="error-message" />
                    </div>
                    <div className="anthropometry-form-group">
                      <label htmlFor="right_arm">Braço Direito (cm):</label>
                      <Field type="number" name="right_arm" id="right_arm" onKeyDown={(e) => onlyNumbers(e, setFieldValue, 'right_arm')} />
                      <ErrorMessage name="right_arm" component="div" className="error-message" />
                    </div>
                    <div className="anthropometry-form-group">
                      <label htmlFor="left_forearm">Antebraço Esquerdo (cm):</label>
                      <Field type="number" name="left_forearm" id="left_forearm" onKeyDown={(e) => onlyNumbers(e, setFieldValue, 'left_forearm')} />
                      <ErrorMessage name="left_forearm" component="div" className="error-message" />
                    </div>
                    <div className="anthropometry-form-group">
                      <label htmlFor="right_forearm">Antebraço Direito (cm):</label>
                      <Field type="number" name="right_forearm" id="right_forearm" onKeyDown={(e) => onlyNumbers(e, setFieldValue, 'right_forearm')} />
                      <ErrorMessage name="right_forearm" component="div" className="error-message" />
                    </div>
                    <div className="anthropometry-form-group">
                      <label htmlFor="left_hand">Mão Esquerda (cm):</label>
                      <Field type="number" name="left_hand" id="left_hand" onKeyDown={(e) => onlyNumbers(e, setFieldValue, 'left_hand')} />
                      <ErrorMessage name="left_hand" component="div" className="error-message" />
                    </div>
                    <div className="anthropometry-form-group">
                      <label htmlFor="right_hand">Mão Direita (cm):</label>
                      <Field type="number" name="right_hand" id="right_hand" onKeyDown={(e) => onlyNumbers(e, setFieldValue, 'right_hand')} />
                      <ErrorMessage name="right_hand" component="div" className="error-message" />
                    </div>
                  </Accordion>
                  <Accordion title={"Pernas - Pés"} className="accordion" >
                    <div className="anthropometry-form-group">
                      <label htmlFor="left_leg">Perna Esquerda (cm):</label>
                      <Field type="number" name="left_leg" id="left_leg" onKeyDown={(e) => onlyNumbers(e, setFieldValue, 'left_leg')} />
                      <ErrorMessage name="left_leg" component="div" className="error-message" />
                    </div>
                    <div className="anthropometry-form-group">
                      <label htmlFor="right_leg">Perna Direita (cm):</label>
                      <Field type="number" name="right_leg" id="right_leg" onKeyDown={(e) => onlyNumbers(e, setFieldValue, 'right_leg')} />
                      <ErrorMessage name="right_leg" component="div" className="error-message" />
                    </div>
                    <div className="anthropometry-form-group">
                      <label htmlFor="left_lower_leg">Perna Inferior Esquerda (cm):</label>
                      <Field type="number" name="left_lower_leg" id="left_lower_leg" onKeyDown={(e) => onlyNumbers(e, setFieldValue, 'left_lower_leg')} />
                      <ErrorMessage name="left_lower_leg" component="div" className="error-message" />
                    </div>
                    <div className="anthropometry-form-group">
                      <label htmlFor="right_lower_leg">Perna Inferior Direita (cm):</label>
                      <Field type="number" name="right_lower_leg" id="right_lower_leg" onKeyDown={(e) => onlyNumbers(e, setFieldValue, 'right_lower_leg')} />
                      <ErrorMessage name="right_lower_leg" component="div" className="error-message" />
                    </div>
                    <div className="anthropometry-form-group">
                      <label htmlFor="right_foot">Pé Esquerdo (cm):</label>
                      <Field type="number" name="right_foot" id="right_foot" onKeyDown={(e) => onlyNumbers(e, setFieldValue, 'right_foot')} />
                      <ErrorMessage name="right_foot" component="div" className="error-message" />
                    </div>
                    <div className="anthropometry-form-group">
                      <label htmlFor="left_foot">Pé Direito (cm):</label>
                      <Field type="number" name="left_foot" id="left_foot" onKeyDown={(e) => onlyNumbers(e, setFieldValue, 'left_foot')} />
                      <ErrorMessage name="left_foot" component="div" className="error-message" />
                    </div>
                  </Accordion>
                  <Accordion title={"Diâmetros e Alturas Osseas"} className="accordion" >
                    <div className="anthropometry-form-group">
                      <label htmlFor="radial_diameter">Diâmetro do Radial (cm):</label>
                      <Field type="number" name="radial_diameter" id="radial_diameter" onKeyDown={(e) => onlyNumbers(e, setFieldValue, 'radial_diameter')} />
                      <ErrorMessage name="radial_diameter" component="div" className="error-message" />
                    </div>
                    <div className="anthropometry-form-group">
                      <label htmlFor="femur_diameter">Diâmetro do Fêmur (cm):</label>
                      <Field type="number" name="femur_diameter" id="femur_diameter" onKeyDown={(e) => onlyNumbers(e, setFieldValue, 'femur_diameter')} />
                      <ErrorMessage name="femur_diameter" component="div" className="error-message" />
                    </div>
                    <div className="anthropometry-form-group">
                      <label htmlFor="knee_height">Altura do Joelho (cm):</label>
                      <Field type="number" name="knee_height" id="knee_height" onKeyDown={(e) => onlyNumbers(e, setFieldValue, 'knee_height')} />
                      <ErrorMessage name="knee_height" component="div" className="error-message" />
                    </div>
                    <div className="anthropometry-form-group">
                      <label htmlFor="humeral_diameter">Diâmetro do Úmero (cm):</label>
                      <Field type="number" name="humeral_diameter" id="humeral_diameter" onKeyDown={(e) => onlyNumbers(e, setFieldValue, 'humeral_diameter')} />
                      <ErrorMessage name="humeral_diameter" component="div" className="error-message" />
                    </div>
                  </Accordion>
                  <Accordion title={"Dobras Cutâneas"} className="accordion" >
                    <div className="anthropometry-form-group">
                      <label htmlFor="measurement_type">Tipo de Medição:</label>
                      <Field type="text" name="measurement_type" id="measurement_type" onKeyDown={handleKeyDown} />
                      <ErrorMessage name="measurement_type" component="div" className="error-message" />
                    </div>
                    <div className="anthropometry-form-group">
                      <label htmlFor="suprailiac_skinfold">Dobra Suprailíaca (cm):</label>
                      <Field type="number" name="suprailiac_skinfold" id="suprailiac_skinfold" onKeyDown={(e) => onlyNumbers(e, setFieldValue, 'suprailiac_skinfold')} />
                      <ErrorMessage name="suprailiac_skinfold" component="div" className="error-message" />
                    </div>
                    <div className="anthropometry-form-group">
                      <label htmlFor="subscapular_skinfold">Dobra Subescapular (cm):</label>
                      <Field type="number" name="subscapular_skinfold" id="subscapular_skinfold" onKeyDown={(e) => onlyNumbers(e, setFieldValue, 'subscapular_skinfold')} />
                      <ErrorMessage name="subscapular_skinfold" component="div" className="error-message" />
                    </div>
                    <div className="anthropometry-form-group">
                      <label htmlFor="supraespinal_skinfold">Dobra Supraespinhal (cm):</label>
                      <Field type="number" name="supraespinal_skinfold" id="supraespinal_skinfold" onKeyDown={(e) => onlyNumbers(e, setFieldValue, 'supraespinal_skinfold')} />
                      <ErrorMessage name="supraespinal_skinfold" component="div" className="error-message" />
                    </div>
                    <div className="anthropometry-form-group">
                      <label htmlFor="chest_skinfold">Dobra Peitoral (cm):</label>
                      <Field type="number" name="chest_skinfold" id="chest_skinfold" onKeyDown={(e) => onlyNumbers(e, setFieldValue, 'chest_skinfold')} />
                      <ErrorMessage name="chest_skinfold" component="div" className="error-message" />
                    </div>
                    <div className="anthropometry-form-group">
                      <label htmlFor="triceps_skinfold">Dobra Trícepes (cm):</label>
                      <Field type="number" name="triceps_skinfold" id="triceps_skinfold" onKeyDown={(e) => onlyNumbers(e, setFieldValue, 'triceps_skinfold')} />
                      <ErrorMessage name="triceps_skinfold" component="div" className="error-message" />
                    </div>
                    <div className="anthropometry-form-group">
                      <label htmlFor="biceps_skinfold">Dobra Bícepes (cm):</label>
                      <Field type="number" name="biceps_skinfold" id="biceps_skinfold" onKeyDown={(e) => onlyNumbers(e, setFieldValue, 'biceps_skinfold')} />
                      <ErrorMessage name="biceps_skinfold" component="div" className="error-message" />
                    </div>
                    <div className="anthropometry-form-group">
                      <label htmlFor="thigh_skinfold">Dobra da Coxa (cm):</label>
                      <Field type="number" name="thigh_skinfold" id="thigh_skinfold" onKeyDown={(e) => onlyNumbers(e, setFieldValue, 'thigh_skinfold')} />
                      <ErrorMessage name="thigh_skinfold" component="div" className="error-message" />
                    </div>
                    <div className="anthropometry-form-group">
                      <label htmlFor="calf_skinfold">Dobra da Panturrilha (cm):</label>
                      <Field type="number" name="calf_skinfold" id="calf_skinfold" onKeyDown={(e) => onlyNumbers(e, setFieldValue, 'calf_skinfold')} />
                      <ErrorMessage name="calf_skinfold" component="div" className="error-message" />
                    </div>
                    <div className="anthropometry-form-group">
                      <label htmlFor="abdominal_skinfold">Dobra Abdominal (cm):</label>
                      <Field type="number" name="abdominal_skinfold" id="abdominal_skinfold" onKeyDown={(e) => onlyNumbers(e, setFieldValue, 'abdominal_skinfold')} />
                      <ErrorMessage name="abdominal_skinfold" component="div" className="error-message" />
                    </div>
                    <div className="anthropometry-form-group">
                      <label htmlFor="axillary_skinfold">Dobra Axilar (cm):</label>
                      <Field type="number" name="axillary_skinfold" id="axillary_skinfold" onKeyDown={(e) => onlyNumbers(e, setFieldValue, 'axillary_skinfold')} />
                      <ErrorMessage name="axillary_skinfold" component="div" className="error-message" />
                    </div>
                  </Accordion>
                  <Accordion title={"Fatores VET"} className="accordion" >
                    <div className="anthropometry-form-group">
                      <label htmlFor="vet_final">VET Final:</label>
                      <Field type="number" name="vet_final" id="vet_final" onKeyDown={(e) => onlyNumbers(e, setFieldValue, 'vet_final')} />
                      <ErrorMessage name="vet_final" component="div" className="error-message" />
                    </div>
                    <div className="anthropometry-form-group">
                      <label htmlFor="vet_final_equation">Equação Final VET:</label>
                      <Field type="number" name="vet_final_equation" id="vet_final_equation" onKeyDown={(e) => onlyNumbers(e, setFieldValue, 'vet_final_equation')} />
                      <ErrorMessage name="vet_final_equation" component="div" className="error-message" />
                    </div>
                    <div className="anthropometry-form-group">
                      <label htmlFor="vet_final_weight">VET Peso:</label>
                      <Field type="number" name="vet_final_weight" id="vet_final_weight" onKeyDown={(e) => onlyNumbers(e, setFieldValue, 'vet_final_weight')} />
                      <ErrorMessage name="vet_final_weight" component="div" className="error-message" />
                    </div>
                    <div className="anthropometry-form-group">
                      <label htmlFor="formula_vet_code">Código da Fórmula VET:</label>
                      <Field type="number" name="formula_vet_code" id="formula_vet_code" onKeyDown={(e) => onlyNumbers(e, setFieldValue, 'formula_vet_code')} />
                      <ErrorMessage name="formula_vet_code" component="div" className="error-message" />
                    </div>
                    <div className="anthropometry-form-group">
                      <label htmlFor="activity_factor_code">Código do Fator de Atividade:</label>
                      <Field type="number" name="activity_factor_code" id="activity_factor_code" onKeyDown={(e) => onlyNumbers(e, setFieldValue, 'activity_factor_code')} />
                      <ErrorMessage name="activity_factor_code" component="div" className="error-message" />
                    </div>
                    <div className="anthropometry-form-group">
                      <label htmlFor="formula_fat_percentage_code">Código da Fórmula do Percentual de Gordura:</label>
                      <Field type="number" name="formula_fat_percentage_code" id="formula_fat_percentage_code" onKeyDown={(e) => onlyNumbers(e, setFieldValue, 'formula_fat_percentage_code')} />
                      <ErrorMessage name="formula_fat_percentage_code" component="div" className="error-message" />
                    </div>
                  </Accordion>
                  <Accordion title={"Classificações e Diagnóstico"} className="accordion">
                    <div className="anthropometry-form-group">
                      <label htmlFor="pg_classification_code">Código de Classificação PG:</label>
                      <Field type="number" name="pg_classification_code" id="pg_classification_code" onKeyDown={(e) => onlyNumbers(e, setFieldValue, 'pg_classification_code')} />
                      <ErrorMessage name="pg_classification_code" component="div" className="error-message" />
                    </div>
                    <div className="anthropometry-form-group">
                      <label htmlFor="formula_pt_code">Código da Fórmula PT:</label>
                      <Field type="number" name="formula_pt_code" id="formula_pt_code" onKeyDown={(e) => onlyNumbers(e, setFieldValue, 'formula_pt_code')} />
                      <ErrorMessage name="formula_pt_code" component="div" className="error-message" />
                    </div>
                    <div className="anthropometry-form-group">
                      <label htmlFor="body_fat_percentage">Percentual de Gordura Corporal (%):</label>
                      <Field type="number" name="body_fat_percentage" id="body_fat_percentage" onKeyDown={(e) => onlyNumbers(e, setFieldValue, 'body_fat_percentage')} />
                      <ErrorMessage name="body_fat_percentage" component="div" className="error-message" />
                    </div>
                    <div className="anthropometry-form-group">
                      <label htmlFor="ideal_fat_percentage">Percentual Ideal de Gordura (%):</label>
                      <Field type="number" name="ideal_fat_percentage" id="ideal_fat_percentage" onKeyDown={(e) => onlyNumbers(e, setFieldValue, 'ideal_fat_percentage')} />
                      <ErrorMessage name="ideal_fat_percentage" component="div" className="error-message" />
                    </div>
                    <div className="anthropometry-form-group">
                      <label htmlFor="gi_percentage_type">Tipo de Percentual GI (%):</label>
                      <Field type="number" name="gi_percentage_type" id="gi_percentage_type" onKeyDown={(e) => onlyNumbers(e, setFieldValue, 'gi_percentage_type')} />
                      <ErrorMessage name="gi_percentage_type" component="div" className="error-message" />
                    </div>
                    <div className="anthropometry-form-group">
                      <label htmlFor="total_body_water">Total Corporal de Água  (ml):</label>
                      <Field type="number" name="total_body_water" id="total_body_water" onKeyDown={(e) => onlyNumbers(e, setFieldValue, 'total_body_water')} />
                      <ErrorMessage name="total_body_water" component="div" className="error-message" />
                    </div>
                    <div className="anthropometry-form-group">
                      <label htmlFor="lean_weight_type">Tipo de Peso Magro:</label>
                      <Field type="number" name="lean_weight_type" id="lean_weight_type" onKeyDown={(e) => onlyNumbers(e, setFieldValue, 'lean_weight_type')} />
                      <ErrorMessage name="lean_weight_type" component="div" className="error-message" />
                    </div>
                    <div className="anthropometry-form-group">
                      <label htmlFor="muscle_mass_weight">Peso da Massa Corporal:</label>
                      <Field type="number" name="muscle_mass_weight" id="muscle_mass_weight" onKeyDown={(e) => onlyNumbers(e, setFieldValue, 'muscle_mass_weight')} />
                      <ErrorMessage name="muscle_mass_weight" component="div" className="error-message" />
                    </div>
                    <div className="anthropometry-form-group">
                      <label htmlFor="physical_activity">Atividade Física:</label>
                      <Field type="text" name="physical_activity" id="physical_activity" onKeyDown={handleKeyDown} />
                      <ErrorMessage name="physical_activity" component="div" className="error-message" />
                    </div>
                    <div className="anthropometry-form-group">
                      <label htmlFor="physical_activity_level">Nível da Atividade Física:</label>
                      <Field type="text" name="physical_activity_level" id="physical_activity_level" onKeyDown={handleKeyDown} />
                      <ErrorMessage name="physical_activity_level" component="div" className="error-message" />
                    </div>
                    <div className="anthropometry-form-group">
                      <label htmlFor="diagnosis">Diagnóstico:</label>
                      <Field as="textarea" name="diagnosis" id="diagnosis" onKeyDown={handleKeyDown} />
                      <ErrorMessage name="diagnosis" component="div" className="error-message" />
                    </div>
                  </Accordion>
                  <Accordion title={"Outros Índices:"} className="accordion" >
                    <div className="anthropometry-form-group">
                      <label htmlFor="reference_estimated_weight">Peso de Referência Estimado (kg):</label>
                      <Field type="number" name="reference_estimated_weight" id="reference_estimated_weight" onKeyDown={(e) => onlyNumbers(e, setFieldValue, 'reference_estimated_weight')} />
                      <ErrorMessage name="reference_estimated_weight" component="div" className="error-message" />
                    </div>
                    <div className="anthropometry-form-group">
                      <label htmlFor="fat_mass_weight">Peso da Massa Gorda (kg):</label>
                      <Field type="number" name="fat_mass_weight" id="fat_mass_weight" onKeyDown={(e) => onlyNumbers(e, setFieldValue, 'fat_mass_weight')} />
                      <ErrorMessage name="fat_mass_weight" component="div" className="error-message" />
                    </div>
                    <div className="anthropometry-form-group">
                      <label htmlFor="ppr_time">Perda de Peso Recente - PPR:</label>
                      <Field type="number" name="ppr_time" id="ppr_time" onKeyDown={(e) => onlyNumbers(e, setFieldValue, 'ppr_time')} />
                      <ErrorMessage name="ppr_time" component="div" className="error-message" />
                    </div>
                    <div className="anthropometry-form-group">
                      <label htmlFor="vet_id">VET ID:</label>
                      <Field type="number" name="vet_id" id="vet_id" onKeyDown={(e) => onlyNumbers(e, setFieldValue, 'vet_id')} />
                      <ErrorMessage name="vet_id" component="div" className="error-message" />
                    </div>
                    <div className="anthropometry-form-group">
                      <label htmlFor="vet_reduction_id">VET Redução ID:</label>
                      <Field type="number" name="vet_reduction_id" id="vet_reduction_id" onKeyDown={(e) => onlyNumbers(e, setFieldValue, 'vet_reduction_id')} />
                      <ErrorMessage name="vet_reduction_id" component="div" className="error-message" />
                    </div>
                    {/* <div className="anthropometry-form-group">
                      <label htmlFor="bmi_classification_code">IMC Código de Classificação:</label>
                      <Field type="number" name="bmi_classification_code" id="bmi_classification_code" onKeyDown={(e) => onlyNumbers(e, setFieldValue, 'bmi_classification_code')} />
                      <ErrorMessage name="bmi_classification_code" component="div" className="error-message" />
                    </div> */}
                    <div className="anthropometry-form-group">
                      <label htmlFor="age_years">Idade Anos:</label>
                      <Field type="number" name="age_years" id="age_years" onKeyDown={(e) => onlyNumbers(e, setFieldValue, 'age_years')} />
                      <ErrorMessage name="age_years" component="div" className="error-message" />
                    </div>
                    <div className="anthropometry-form-group">
                      <label htmlFor="age_months">Idade Meses:</label>
                      <Field type="number" name="age_months" id="age_months" onKeyDown={(e) => onlyNumbers(e, setFieldValue, 'age_months')} />
                      <ErrorMessage name="age_months" component="div" className="error-message" />
                    </div>
                    <div className="anthropometry-form-group">
                      <label htmlFor="bray_classification">Código de Zunido:</label>
                      <Field type="number" name="bray_classification" id="bray_classification" onKeyDown={(e) => onlyNumbers(e, setFieldValue, 'bray_classification')} />
                      <ErrorMessage name="bray_classification" component="div" className="error-message" />
                    </div>
                    <div className="anthropometry-form-group">
                      <label htmlFor="mcm">MCM:</label>
                      <Field type="number" name="mcm" id="mcm" onKeyDown={(e) => onlyNumbers(e, setFieldValue, 'mcm')} />
                      <ErrorMessage name="mcm" component="div" className="error-message" />
                    </div>
                  </Accordion>

                </div>
                <div className="anthropometry-colC">
                  <div className="formButtons">
                    <button className='sendBtn' type="submit" disabled={isSubmitting}>Enviar</button>
                    <button className='backBtn' onClick={() => navigate('/home/anthropometry/get')}>Cancelar</button>
                  </div>
                </div>
              </div>
            </Form>
          )}
        </Formik>
        {showModal && (
          <ModalForms
            message={message}
            onConfirm={() => setShowModal(false)}
          />
        )}
      </div >
    </>
  );
};

export default CreateAnthropometry;
