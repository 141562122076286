// Slice
import { createSlice } from '@reduxjs/toolkit';
import {
  fetchUsers,
  fetchUserById,
  createUserAsync,
  updateUserAsync,
  deleteUserById,
  fetchCountNewUser,
  fetchUserInfo,
  fetchUserLogs
} from '../actions/userActions';

const userSlice = createSlice({
  name: 'users',
  initialState: {
    users: [],
    currentUser: null,
    loading: false,
    error: null,
  },
  reducers: {
    setCurrentUser: (state, action) => {
      state.currentUser = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchUsers.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(fetchUsers.fulfilled, (state, action) => {
        state.loading = false;
        state.users = action.payload;
      })
      .addCase(fetchUsers.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      })
      .addCase(fetchUserById.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(fetchUserById.fulfilled, (state, action) => {
        state.loading = false;
        state.currentUser = action.payload;
      })
      .addCase(fetchUserById.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      })
      .addCase(deleteUserById.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(deleteUserById.fulfilled, (state, action) => {
        state.loading = false;
        state.users = state.users.filter(user => user.id !== action.payload);
      })
      .addCase(deleteUserById.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      })
      .addCase(updateUserAsync.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(updateUserAsync.fulfilled, (state, action) => {
        state.loading = false;
        if (action.payload) {
          // Verifica se o payload é válido e contém o objeto `user`
          const updatedUser = action.payload.user || action.payload;
          state.users = state.users.map(user => user.id === updatedUser.id ? updatedUser : user);
        }
      })
      .addCase(updateUserAsync.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      })
      .addCase(createUserAsync.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(createUserAsync.fulfilled, (state, action) => {
        state.loading = false;
        state.users.push(action.payload);
      })
      .addCase(createUserAsync.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      })
      .addCase(fetchCountNewUser.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(fetchCountNewUser.fulfilled, (state, action) => {
        state.loading = false;
        state.newUserCount = action.payload.newUserCount;
        state.totalUserCount = action.payload.totalUserCount;
      })
      .addCase(fetchCountNewUser.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      })
      .addCase(fetchUserInfo.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(fetchUserInfo.fulfilled, (state, action) => {
        state.loading = false;
        state.currentUser = action.payload;
      })
      .addCase(fetchUserInfo.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })
      .addCase(fetchUserLogs.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(fetchUserLogs.fulfilled, (state, action) => {
        state.loading = false;
        state.userLogs = action.payload;
      })
      .addCase(fetchUserLogs.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })
      // Clear Messages
      .addCase('users/clearMessages', (state) => {
        state.successMessage = '';
        state.errorMessage = '';
      });
  },
});

export const { setCurrentUser } = userSlice.actions;
export default userSlice.reducer;