import React, { useState, useEffect } from 'react';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import moment from 'moment';
import { useSelector, useDispatch } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import { fetchAppointmentById, updateAppointmentById } from '../../../redux/actions/appointmentsActions';
import { fetchAllProfessionals, fetchPatients, fetchCompanies } from '../../../redux/actions/relatedEntitiesActions';
import ModalForms from '../../../components/ConfirmationModal/ModalForms';
import Select from 'react-select'; // Importando o React Select

import '../styles/CreateAppointment.css';

function UpdateAppointment() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { appointment_id } = useParams();

  const [message, setMessage] = useState('');
  const [showModal, setShowModal] = useState(false);
  const [loading, setLoading] = useState(true);

  const companies = useSelector(state => state.relatedEntities.companies || []);
  const patients = useSelector(state => state.relatedEntities.patients || []);
  const professionals = useSelector(state => state.relatedEntities.professionals || []);
  const currentAppointment = useSelector(state => state.appointments.currentAppointment || null);

  const [timeRange, setTimeRange] = useState({ start: '08:00', end: '18:00' });

  const [initialValues, setInitialValues] = useState({
    company_id: '',
    patient_id: '',
    professional_id: '',
    appointment_date: '',
    appointment_time: '',
    scheduling_date: '',
    status: 'scheduled',
    notes: ''
  });

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      try {
        await Promise.all([
          dispatch(fetchCompanies()),
          dispatch(fetchPatients()),
          dispatch(fetchAllProfessionals()),
          dispatch(fetchAppointmentById(appointment_id))
        ]);
      } catch (error) {
        console.error('Error fetching data:', error);
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, [dispatch, appointment_id]);

  useEffect(() => {
    if (currentAppointment) {
      const appointmentDate = moment(currentAppointment.appointment_date).format('YYYY-MM-DD');
      const schedulingDate = moment(currentAppointment.scheduling_date).format('YYYY-MM-DD');

      setInitialValues({
        company_id: currentAppointment.company_id || '',
        patient_id: currentAppointment.patient_id || '',
        professional_id: currentAppointment.professional_id || '',
        appointment_date: appointmentDate,
        appointment_time: currentAppointment.appointment_time || '',
        scheduling_date: schedulingDate,
        status: currentAppointment.status || '',
        notes: currentAppointment.notes || ''
      });
    }
  }, [currentAppointment]);

  const handleBack = (e) => {
    e.preventDefault();
    navigate('/home/appointment/get');
  };

  const formatSpeciality = (speciality) => {
    switch (speciality) {
      case 'general_clinic':
        return 'Clinica Geral';
      case 'physiotherapyst':
        return 'Fisioterapia';
      case 'nutritionist':
        return 'Nutrição';
      default:
        return '';
    }
  };

  const formatPersonType = (person_type) => {
    switch (person_type) {
      case 'nutritionist':
        return 'Nutricionista';
      case 'doctor':
        return 'Médico';
      case 'professional':
        return 'Profissional';
      default:
        return '';
    }
  };

  const validationSchema = Yup.object({
    company_id: Yup.object().required('Empresa é necessária'),
    patient_id: Yup.object().required('Paciente é necessário'),
    professional_id: Yup.object().required('Profissional é necessário'),
    appointment_date: Yup.date()
      .nullable()
      .required('Data do compromisso é necessária')
      .test('is-today-or-later', 'A data de agendamento não pode ser anterior a hoje.', (value) => {
        const today = moment().startOf('day');
        const appointmentDate = moment(value);
        return appointmentDate.isSameOrAfter(today);
      }),
    appointment_time: Yup.string()
      .required('Hora do compromisso é necessária')
      .test('is-within-range', `O horário deve estar entre ${timeRange.start} e ${timeRange.end}.`, (value) => {
        const [hour, minute] = value.split(':');
        const appointmentTime = moment().set({ hour, minute, second: 0, millisecond: 0 });
        const startTime = moment().set({ hour: timeRange.start.split(':')[0], minute: timeRange.start.split(':')[1], second: 0, millisecond: 0 });
        const endTime = moment().set({ hour: timeRange.end.split(':')[0], minute: timeRange.end.split(':')[1], second: 0, millisecond: 0 });
        return appointmentTime.isBetween(startTime, endTime, null, '[]');
      })
      .test('is-time-valid', 'O horário não pode ser anterior ao horário atual.', function (value) {
        const appointmentDate = moment(this.parent.appointment_date).format('YYYY-MM-DD');
        const today = moment().format('YYYY-MM-DD');

        if (appointmentDate === today) {
          const [hour, minute] = value.split(':');
          const appointmentTime = moment().set({ hour, minute, second: 0, millisecond: 0 });
          const currentTime = moment();
          return appointmentTime.isSameOrAfter(currentTime);
        }
        return true;
      }),
    scheduling_date: Yup.date().required('Data de agendamento é necessária'),
    status: Yup.string().required('Status é necessário')
  });

  const handleSubmit = async (values, { setSubmitting, resetForm }) => {
    try {
      await dispatch(updateAppointmentById({
        appointment_id, data: {
          ...values,
          company_id: values.company_id.value, // Aqui usamos apenas o ID
          patient_id: values.patient_id.value, // Aqui usamos apenas o ID
          professional_id: values.professional_id.value, // Aqui usamos apenas o ID
        }
      }));
      setMessage("Compromisso atualizado com sucesso!");
      setShowModal(true);
      setTimeout(() => {
        setShowModal(false);
        resetForm();
        navigate('/home/appointment/get');
      }, 2000);
    } catch (error) {
      console.error("Erro ao enviar formulário:", error);
      const errorMsg = error.message || 'Erro ao enviar formulário. Tente novamente mais tarde.';
      setMessage(errorMsg);
      setShowModal(true);
      setTimeout(() => {
        setShowModal(false);
      }, 2500);
    }
    setSubmitting(false);
  };

  const selectOptions = (items, labelKey = 'name') => items.map(item => ({
    value: item.person_id || item.company_id,
    label: item[labelKey],
  }));

  const statusOptions = [
    { value: '', label: 'Selecione' },
    { value: 'scheduled', label: 'Agendado' },
    { value: 'confirmed', label: 'Confirmado' },
    { value: 'changed', label: 'Alterado' },
    { value: 'cancelled', label: 'Cancelado' },
  ];


  const getOptionFromValue = (options, value) => options.find(option => option.value === value) || null;

  if (loading) {
    return <div>Carregando...</div>;
  }

  return (
    <div>
      <h3>{appointment_id ? "Atualizar Compromisso" : "Novo Compromisso"}</h3>
      <Formik
        initialValues={{
          ...initialValues,
          company_id: getOptionFromValue(selectOptions(companies, 'company_name'), initialValues.company_id),
          patient_id: getOptionFromValue(selectOptions(patients, 'name'), initialValues.patient_id),
          professional_id: getOptionFromValue(selectOptions(professionals, 'name'), initialValues.professional_id),
        }}
        enableReinitialize={true}
        validationSchema={validationSchema}
        onSubmit={handleSubmit}
      >
        {({ setFieldValue, values, isSubmitting }) => (
          <Form className='appointmentForm'>
            <div className="appointmentMain">
              <div className="appointment-form-group">
                <label htmlFor="company_id">Empresa:</label>
                <Select
                  name="company_id"
                  options={selectOptions(companies, 'company_name')}
                  onChange={option => setFieldValue('company_id', option)}
                  classNamePrefix="custom-select"
                  value={values.company_id}
                  placeholder="Selecione a empresa"
                  isClearable
                />
                <ErrorMessage name="company_id" component="div" className="error-message" />
              </div>

              <div className="appointment-form-group">
                <label htmlFor="professional_id">Profissional:</label>
                <Select
                  name="professional_id"
                  options={selectOptions(professionals, 'name')}
                  onChange={option => setFieldValue('professional_id', option)}
                  classNamePrefix="custom-select"
                  value={values.professional_id}
                  placeholder="Selecione o profissional"
                  formatOptionLabel={option => (
                    <div>
                      {option.label} - {option.person_type && formatPersonType(option.person_type)} - {option.speciality && formatSpeciality(option.speciality)}
                    </div>
                  )}
                  isClearable
                />
                <ErrorMessage name="professional_id" component="div" className="error-message" />
              </div>

              <div className="appointment-form-group">
                <label htmlFor="patient_id">Paciente:</label>
                <Select
                  name="patient_id"
                  options={selectOptions(patients)}
                  onChange={option => setFieldValue('patient_id', option)}
                  classNamePrefix="custom-select"
                  value={values.patient_id}
                  placeholder="Selecione o paciente"
                  isClearable
                />
                <ErrorMessage name="patient_id" component="div" className="error-message" />
              </div>

              <div className="appointment-form-group">
                <label htmlFor="appointment_date">Data do Compromisso:</label>
                <Field type="date" name="appointment_date" id="appointment_date" />
                <ErrorMessage name="appointment_date" component="div" className="error-message" />
              </div>

              <div className="appointment-form-group">
                <label htmlFor="appointment_time">Hora do Compromisso:</label>
                <Field type="time" name="appointment_time" id="appointment_time" />
                <ErrorMessage name="appointment_time" component="div" className="error-message" />
              </div>

              <div className="appointment-form-group">
                <label htmlFor="scheduling_date">Data de Agendamento:</label>
                <Field type="date" name="scheduling_date" id="scheduling_date" />
                <ErrorMessage name="scheduling_date" component="div" className="error-message" />
              </div>

              {/* <div className="appointment-form-group">
                <label htmlFor="status">Status:</label>
                <Field as="select" name="status" id="status">
                  <option value="scheduled">Agendado</option>
                  <option value="confirmed">Confirmado</option>
                  <option value="changed">Alterado</option>
                  <option value="cancelled">Cancelado</option>
                </Field>
                <ErrorMessage name="status" component="div" className="error-message" />
              </div> */}

              <div className="appointment-form-group">
                <label htmlFor="status">Status:</label>
                <Select
                  name="status"
                  id="status"
                  options={statusOptions}
                  onChange={option => setFieldValue('status', option ? option.value : '')}
                  classNamePrefix="custom-select"
                  placeholder="Selecione"
                />
                <ErrorMessage name="status" component="div" className="error-message" />
              </div>

              <div className="appointment-form-group">
                <label htmlFor="notes">Notas:</label>
                <Field as="textarea" name="notes" id="notes" />
                <ErrorMessage name="notes" component="div" className="error-message" />
              </div>

              <div className="formButtons">
                <button className='sendBtn' type="submit" disabled={isSubmitting}>{appointment_id ? "Atualizar" : "Enviar"}</button>
                <button className='backBtn' onClick={handleBack}>Cancelar</button>
              </div>
            </div>
          </Form>
        )}
      </Formik>
      {showModal && (
        <ModalForms
          message={message}
          onConfirm={() => setShowModal(false)}
        />
      )}
    </div>
  );
}

export default UpdateAppointment;
