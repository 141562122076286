import React from 'react';

import Nav from '../../components/NavBar/components/Nav';

const Contact = () => {

  return (
    <>
      <Nav />
      <div>
        <h1>Contact</h1>

      </div>
    </>

  );
};

export default Contact;
