import React, { useContext, useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { deleteAppointmentById, fetchAppointments } from '../../../redux/actions/appointmentsActions';
import { setCurrentAppointment } from '../../../redux/slices/appointmentSlice';
import { useNavigate } from 'react-router-dom';
import { AuthContext } from '../../../context/auth';
import ConfirmationModal from '../../../components/ConfirmationModal/ConfirmationModal';
import SearchBar from '../../../components/SearchBar/SearchBar';
import { MdDelete, MdCreate, MdHome } from 'react-icons/md';
import { FaUserMd, FaCalendar, FaHospitalAlt, FaUser } from "react-icons/fa";
import { FaUserClock, FaClipboardCheck, FaClock } from "react-icons/fa6";

import '../styles/GetAppointment.css';

function GetAppointment() {
  const { loginUser } = useContext(AuthContext);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [message, setMessage] = useState('');
  const [ellipsis, setEllipsis] = useState('.....');
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 10;
  const icoUpdate = <MdCreate className='icon' />;
  const icoTrash = <MdDelete className='icon' />;
  const icoHome = <MdCreate className='mobileIcon' />
  const icoDoctor = <FaUserMd className='mobileIcon' />
  const icoTime = <FaUserClock className='mobileIcon' />
  const icoStatus = <FaClock className='mobileIcon' />
  const icoCalendar = <FaCalendar className='mobileIcon' />
  const icoHospital = <FaHospitalAlt className='mobileIcon' />
  const icoPatient = <FaUser className='mobileIcon' />

  const [appointmentIdToDelete, setAppointmentIdToDelete] = useState('');
  const [filteredAppointment, setFilteredAppointment] = useState([]);

  const [searchText, setSearchText] = useState("");
  const [showModal, setShowModal] = useState(false);
  const [isDeleting, setIsDeleting] = useState(false);

  const updateBtn = "Editar";
  const deleteBtn = "Deletar";
  const detailsBtn = "Clique para detalhes";
  const { appointments = [], loading, error } = useSelector((state) => state.appointments);  // Garantir que appointments seja um array

  useEffect(() => {
    dispatch(fetchAppointments());
  }, [dispatch]);

  useEffect(() => {
    if (appointments && appointments.length) {
      setFilteredAppointment(appointments);
    }
  }, [appointments]);

  const handleDelete = async (appointment_id) => {
    setAppointmentIdToDelete(appointment_id);
    setMessage(`Tem certeza que deseja remover o registro de: ${appointment_id}`);
    setShowModal(true);
  };

  const handleConfirmDelete = async () => {
    setIsDeleting(true);
    try {
      await dispatch(deleteAppointmentById(appointmentIdToDelete));
      setShowModal(true);
      setMessage(`Removido o registro ID: ${appointmentIdToDelete} com sucesso :)`);
      setTimeout(() => {
        setShowModal(false);
        setIsDeleting(false);
      }, 2000);
    } catch (error) {
      setMessage(`Erro ao tentar deletar o registro: ${error}`);
      setIsDeleting(false);
    }
  }

  const handleCancelDelete = () => {
    setShowModal(false);
  };

  const handleUpdate = (appointment_id) => {
    navigate(`/home/appointment/update/${appointment_id}`);
  };

  const handleShowAppointment = (appointment_id) => {
    navigate(`/home/appointment/show/${appointment_id}`);
  };

  const formatStatus = (status) => {
    switch (status) {
      case 'scheduled':
        return 'Agendado';
      case 'confirmed':
        return 'Confirmado';
      case 'changed':
        return 'Alterado';
      case 'cancelled':
        return 'Cancelado';
      default:
        return 'Agendado';
    }
  }

  const handleSearch = (searchText) => {
    setSearchText(searchText);
    const filtered = appointments.filter(appointment =>
      `${appointment.company_name} ${appointment.patient_name} ${appointment.professional_name} ${formatStatus(appointment.status)}`.toLowerCase().includes(searchText.toLowerCase())
    );
    setFilteredAppointment(filtered);
  };

  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const paginate = (pageNumber) => setCurrentPage(pageNumber);
  const getPageButtonClass = (index) => (currentPage === index + 1 ? 'active' : '');

  useEffect(() => {
    let interval;
    if (loading) {
      let count = 5;
      interval = setInterval(() => {
        setEllipsis('.'.repeat(count));
        count = count === 1 ? 5 : count - 1;
      }, 500);
    } else {
      setEllipsis('.....');
    }

    return () => clearInterval(interval);
  }, [loading]);

  const formatDate = (date) => {
    const d = new Date(date);
    return `${String(d.getDate()).padStart(2, '0')}/${String(d.getMonth() + 1).padStart(2, '0')}/${d.getFullYear()}`;
  };


  function formatTime(time) {
    if (!time) return '';  // Verifica se 'time' é indefinido ou null e retorna uma string vazia
    return time.slice(0, 5);
  }

  const currentItems = filteredAppointment.slice(indexOfFirstItem, indexOfLastItem);

  const hasRole = (role) => loginUser.roles && loginUser.roles.includes(role);

  return (
    <>
      {showModal && (
        <ConfirmationModal
          message={message}
          onConfirm={handleConfirmDelete}
          onCancel={handleCancelDelete}
          isDeleting={isDeleting}
        />
      )}

      <div className="col-md-12">
        <h3>Agendamentos</h3>
        <SearchBar onSearch={handleSearch} />
      </div>

      <div className="boxGetAppointment">
        <table className='getAppointmentTable'>
          <thead>
            <tr>
              <td>Clínica</td>
              <td>Paciente</td>
              <td>Profissional</td>
              <td>Data do Agendamento</td>
              <td>Data da Consulta</td>
              <td>Hora da Consulta</td>
              <td>Status</td>
              {hasRole('Manager') && <td>Editar</td>}
              {hasRole('Admin') && <td>Ações</td>}
            </tr>
          </thead>
          <tbody>
            {loading ? (
              <tr>
                <td className="rowMessage" colSpan="7">Aguarde{ellipsis}</td>
              </tr>
            ) : currentItems.length === 0 ? (
              <tr>
                <td className="rowMessage" colSpan="7">Não localizado nenhum registro correspondente</td>
              </tr>
            ) : (
              currentItems.map((appointment) => (
                <tr key={appointment.appointment_id}
                  className={`tr ${appointment.status === 'cancelled' ? 'cancelled-line' : ''}`}
                >
                  <td><a onClick={() => handleShowAppointment(appointment.appointment_id)} data-tip={detailsBtn}>
                    {icoHospital}{appointment.company_name}</a></td>
                  <td><a onClick={() => handleShowAppointment(appointment.appointment_id)} data-tip={detailsBtn}>
                    {icoPatient}{appointment.patient_name}</a></td>
                  <td><a onClick={() => handleShowAppointment(appointment.appointment_id)} data-tip={detailsBtn}>
                    {icoDoctor} {appointment.professional_name}</a></td>
                  <td>{icoHome} {formatDate(appointment.scheduling_date)}</td>
                  <td>{icoCalendar} {formatDate(appointment.appointment_date)}</td>
                  <td>{icoTime} {formatTime(appointment.appointment_time)}</td>
                  <td>{icoStatus} {formatStatus(appointment.status)}</td>
                  {hasRole('Manager') && (
                    <td className="update">
                      <button className='icoBtn' onClick={() => handleUpdate(appointment.appointment_id)} data-tip={updateBtn}>{icoUpdate}</button>
                    </td>
                  )}
                  {hasRole('Admin') && (
                    <td className="delete">
                      <button className='icoBtn' onClick={() => handleUpdate(appointment.appointment_id)} data-tip={updateBtn}>{icoUpdate}</button>
                      <button className='icoBtn' onClick={() => handleDelete(appointment.appointment_id)} data-tip={deleteBtn}>{icoTrash}</button>
                    </td>
                  )}
                </tr>
              ))
            )}
          </tbody>
        </table>

        <div className="pagination">
          {Array.from({ length: Math.ceil((searchText === '' ? appointments.length : filteredAppointment.length) / itemsPerPage) }, (_, index) => (
            <button key={index + 1} onClick={() => paginate(index + 1)} className={getPageButtonClass(index)} >
              {index + 1}
            </button>
          ))}
        </div>

      </div>
    </>
  );
}

export default GetAppointment;
