// src/store/userSlice.js
import { createAsyncThunk } from '@reduxjs/toolkit';
import {
  getUser,
  getUserById,
  createUser,
  deleteUser,
  updateUser,
  countNewUser,
  getUserInfo,
  getUserLogs
} from '../../service/api';


// Thunks assíncronos para interagir com a API
export const fetchUsers = createAsyncThunk(
  'users/fetchUsers',
  async () => {
    const response = await getUser();
    // console.log("O FetchUser é:", JSON.stringify(response, null, 2));
    return response.data;
  }
);


export const fetchUserById = createAsyncThunk(
  'users/fetchUserById',
  async (user_id) => {
    const response = await getUserById(user_id);
    // console.log("O FetchUserById é:", JSON.stringify(response, null, 2));
    return response.data;
  }
);

export const createUserAsync = createAsyncThunk(
  'user/createUser',
  async (userFormData, { rejectWithValue }) => {
    try {
      const response = await createUser(userFormData);
      console.log(JSON.stringify(response))
      return response.data;
    } catch (error) {
      console.log(JSON.stringify(error.response.data))
      return rejectWithValue(error.response.data || 'Erro ao criar usuário');
    }
  });

export const updateUserAsync = createAsyncThunk(
  'users/updateUserAsync',
  async ({ user_id, userFormData }, { rejectWithValue }) => {
    try {
      const response = await updateUser(user_id, userFormData);
      return response.data; // This should include the `message` and updated `user` object
    } catch (error) {
      // Handle errors properly
      if (error.response && error.response.data) {
        return rejectWithValue(error.response.data);
      }
      return rejectWithValue('Erro ao atualizar usuário');
    }
  }
);

export const deleteUserById = createAsyncThunk(
  'users/deleteUserById',
  async (id, { rejectWithValue }) => {
    try {
      const response = await deleteUser(id);
      console.log(JSON.stringify(response.data))
      return response.data;
    } catch (error) {
      console.log(JSON.stringify(error.response.data))
      return rejectWithValue(error.response?.data || 'Erro ao deletar usuário');
    }
  }
);


export const clearMessages = () => (dispatch) => {
  dispatch({
    type: 'users/clearMessages',
  })
};

export const fetchCountNewUser = createAsyncThunk(
  'users/fetchCountNewUser',
  async () => {
    try {
      const response = await countNewUser();
      return response;  // Return the entire response with newUserCount and totalUserCount
    } catch (error) {
      throw error.response?.data || 'Erro ao buscar contagem de usuários';
    }
  }
);

export const fetchUserInfo = createAsyncThunk(
  'users/fetchUserInfo',
  async (user_id, { rejectWithValue }) => {
    try {
      const response = await getUserInfo(user_id);
      return response;
    } catch (error) {
      return rejectWithValue(error.response?.data || 'Erro ao buscar informações do usuário');
    }
  }
);

export const fetchUserLogs = createAsyncThunk(
  'users/fetchUserLogs',
  async (user_id, { rejectWithValue }) => {
    try {
      const response = await getUserLogs(user_id);
      return response;
    } catch (error) {
      return rejectWithValue(error.response?.data || 'Erro ao buscar logs do usuário');
    }
  }
);