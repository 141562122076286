import React, { useContext } from 'react';
import { Navigate } from 'react-router-dom';
import { AuthContext } from '../../context/auth';

const ProtectedRoute = ({ children, role }) => {
  const { authenticated, loginUser } = useContext(AuthContext);

  if (!authenticated) {
    return <Navigate to='/' />;
  }

  if (role && !role.some(r => loginUser?.roles?.includes(r))) {
    return <Navigate to='/home' state={{ message: 'Access Denied' }} />;
  }

  return children;
};

export default ProtectedRoute;
