// src/redux/reducers/anthropometryReducer.js
import { createSlice } from '@reduxjs/toolkit';
import { createNewAnthropometry } from '../actions/anthropometryActions';

const anthropometrySlice = createSlice({
  name: 'antrhopometry',
  initialState: {
    anthropometry: [],
    loading: false,
    error: null,
    currentAnthropometry: null,
  },
  reducers: {
    setCurrentAnthropometry: (state, action) => {
      state.currentAnthropometry = action.payload;
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(createNewAnthropometry.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(createNewAnthropometry.fulfilled, (state, action) => {
        state.loading = false;
        state.anthropometry.push(action.payload);
      })
      .addCase(createNewAnthropometry.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      });
  }

});
export const { setCurrentAnthropometry } = anthropometrySlice.actions;
export default anthropometrySlice.reducer;