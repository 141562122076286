import React, { useState, useEffect, useContext } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { fetchPersons, deletePersonById } from '../../../redux/actions/personActions';
import { setCurrentPerson } from '../../../redux/slices/personSlice';
import { useNavigate } from 'react-router-dom';
import { AuthContext } from '../../../context/auth';

import ConfirmationModal from '../../../components/ConfirmationModal/ConfirmationModal';
import SearchBar from '../../../components/SearchBar/SearchBar';

import { MdDelete, MdCreate } from 'react-icons/md';

import '../styles/GetPerson.css';
import fallbackAvatar from '../../../assets/img/avatar/avatar.png';

const GetPerson = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { loginUser } = useContext(AuthContext);

  const [message, setMessage] = useState('');
  const [ellipsis, setEllipsis] = useState('.....');
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 10;
  const [searchText, setSearchText] = useState("");
  const [showModal, setShowModal] = useState(false);
  const [isDeleting, setIsDeleting] = useState(false);
  const [personToDelete, setPersonToDelete] = useState(null);

  const { persons = [], loading, error } = useSelector((state) => state.persons);

  const icoUpdate = <MdCreate className='icon' key='icoUpdate' />;
  const icoTrash = <MdDelete className='icon' key='icoTrash' />;

  const updateBtn = "Editar";
  const deleteBtn = "Deletar";
  const detailsBtn = "Clique para detalhes";

  useEffect(() => {
    dispatch(fetchPersons());
  }, [dispatch]);

  const handleUpdatePerson = (person_id) => {
    dispatch(setCurrentPerson(person_id));
    navigate(`/home/person/update/${person_id}`);
  };

  const handleShowPerson = (person_id) => {
    dispatch(setCurrentPerson(person_id));
    navigate(`/home/person/show/${person_id}`);
  };

  const handleDeletePerson = (person_id) => {
    setPersonToDelete(person_id); // Definir a pessoa a ser excluída
    setMessage(`Tem certeza que deseja remover o registro de: ${person_id} `);
    setShowModal(true);
  };

  const handleConfirmDelete = async () => {
    setIsDeleting(true);
    try {
      await dispatch(deletePersonById(personToDelete)).unwrap();
      setShowModal(false);
      setMessage(`Removido o registro ID: ${personToDelete} com sucesso : )`);
      setIsDeleting(false);
    } catch (error) {
      setMessage(`Erro ao tentar deletar o registro: ${error.message} `);
      setIsDeleting(false);
    }
  };

  const handleCancelDelete = () => {
    setShowModal(false);
    setPersonToDelete(null);
  };

  const formatPersonType = (person_type) => {
    switch (person_type) {
      case 'patient':
        return 'Paciente';
      case 'nutritionist':
        return 'Nutricionista';
      case 'doctor':
        return 'Médico';
      case 'professional':
        return 'Profissional';
      case 'user':
        return 'Usuário';
      case 'employee':
        return 'Colaborador';
      case 'supplier':
        return 'Fornecedor';
      case 'partner':
        return 'Parceiro';
      case 'owner':
        return 'Proprietário';
      case 'other':
        return 'Outro';
      default:
        return '';
    }
  };

  // const formatSpeciality = (speciality) => {
  //   switch (speciality) {
  //     case 'nutritionist':
  //       return 'Nutrição';
  //     case 'general_doctor':
  //       return 'Clínico Geral';
  //     case 'physiotherapist':
  //       return 'Fisioterapia';
  //     default:
  //       return '';
  //   }
  // };

  const handleSearch = (searchText) => {
    setSearchText(searchText);
  };

  useEffect(() => {
    let interval;
    if (loading) {
      let count = 5;
      interval = setInterval(() => {
        setEllipsis('.'.repeat(count));
        count = count === 1 ? 5 : count - 1;
      }, 500);
    } else {
      setEllipsis('.....');
    }
    return () => clearInterval(interval);
  }, [loading]);

  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const paginate = (pageNumber) => setCurrentPage(pageNumber);
  const getPageButtonClass = (index) => (currentPage === index + 1 ? 'active' : '');

  const filteredPerson = persons.filter((person) => {
    const personType = formatPersonType(person.person_type);
    const personInfo = `${person.person_id} ${person.first_name} ${person.last_name} ${person.email} ${person.roles} ${personType} ${person.cpf}`;
    return personInfo.toLowerCase().includes(searchText.trim().toLowerCase());
  });

  const currentItems = filteredPerson.slice(indexOfFirstItem, indexOfLastItem);

  const hasRole = (role) => loginUser?.roles?.includes(role);

  if (loading) {
    return <div>Loading...</div>;
  }

  if (error) {
    return <div>Error: {error}</div>;
  }

  return (
    <>
      {showModal && (
        <ConfirmationModal
          message={message}
          onConfirm={handleConfirmDelete}
          onCancel={handleCancelDelete}
          isDeleting={isDeleting}
        />
      )}


      <div className="col-md-12">
        <h3>Pessoas do Sistema</h3>
        <SearchBar onSearch={handleSearch} />
      </div>

      <div className="boxGetPerson">
        <table className='getPersonTable'>
          <thead>
            <tr>
              <td>Avatar</td>
              <td>Nome</td>
              <td>Email</td>
              <td>Celular</td>
              <td>Telefone</td>
              <td>Tipo</td>
              {/* <td>Especialidade</td> */}
              {hasRole('Manager') && <td>Editar</td>}
              {hasRole('Admin') && <td>Ações</td>}
            </tr>
          </thead>
          <tbody>
            {loading ? (
              <tr>
                <td className="rowMessage"> Aguarde{ellipsis}</td>
              </tr>
            ) : currentItems.length === 0 ? (
              <tr>
                <td className="rowMessage" colSpan="8">Não localizado nenhum registro correspondente</td>
              </tr>
            ) : (
              currentItems.map((person) => (
                <tr key={person.person_id}>
                  <td><button className='tdBtn' onClick={() => handleShowPerson(person.person_id)} data-tip={detailsBtn}>
                    <img
                      className='avatarDefault'
                      src={person.file_path ? person.file_path : fallbackAvatar}
                      alt="PessoaPic" />
                  </button></td>
                  <td><button className='tdBtn' onClick={() => handleShowPerson(person.person_id)} data-tip={detailsBtn}>{person.first_name} {person.last_name}</button></td>
                  <td><button className='tdBtn' onClick={() => handleShowPerson(person.person_id)} data-tip={detailsBtn}>{person.email}</button></td>
                  <td>{person.mobile}</td>
                  <td>{person.phone}</td>
                  <td>{formatPersonType(person.person_type)}</td>
                  {/* <td>{formatSpeciality(person.speciality)}</td> */}
                  {hasRole('Manager') && (
                    <td className="update"><button className='icoBtn' onClick={() => handleUpdatePerson(person.person_id)} data-tip={updateBtn}>{icoUpdate}</button></td>
                  )}
                  {hasRole('Admin') && (
                    <td className="delete">
                      <button className='icoBtn' onClick={() => handleUpdatePerson(person.person_id)} data-tip={updateBtn}>{icoUpdate}</button>
                      <button className='icoBtn' onClick={() => handleDeletePerson(person.person_id)} data-tip={deleteBtn}>{icoTrash}</button>
                    </td>
                  )}
                </tr>
              ))
            )}
          </tbody>
        </table>
        <div className="pagination">
          {Array.from({ length: Math.ceil(filteredPerson.length / itemsPerPage) }, (_, index) => (
            <button key={index + 1} onClick={() => paginate(index + 1)} className={getPageButtonClass(index)}>
              {index + 1}
            </button>
          ))}
        </div>
      </div>

    </>
  );
};

export default GetPerson;
