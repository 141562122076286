import { createAsyncThunk } from '@reduxjs/toolkit';
import { getAllTacoFoods, getTacoFoodById } from '../../service/api';


// Fetch all Taco foods
export const fetchAllTacoFoods = createAsyncThunk(
  'nutridata/fetchAllTacoFoods',
  async (_, { rejectWithValue }) => {
    try {
      const response = await getAllTacoFoods();
      // console.log(JSON.stringify(response));
      return response;
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
)

// // Fetch a specific Taco food by ID
export const fetchTacoFoodById = createAsyncThunk(
  'nutridata/fetchTacoFoodById',
  async (food_id, { rejectWithValue }) => {
    try {
      const response = await getTacoFoodById(food_id);
      return response;
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);


// import { createAsyncThunk } from '@reduxjs/toolkit';
// import {
//   getAllTacoFoods,
//   getTacoFoodById,
//   deleteTacoFood,
//   updateTacoFood,
//   createTacoFood
// } from '../../service/api';

// // Fetch all Taco foods
// export const fetchAllTacoFoods = createAsyncThunk(
//   'nutridata/fetchAllTacoFoods',
//   async (_, { rejectWithValue }) => {
//     try {
//       const response = await getAllTacoFoods();
//       return response;  // Remover .data se já retornado pelo service
//     } catch (error) {
//       return rejectWithValue(error.message);
//     }
//   }
// );


// // Delete a specific Taco food by ID
// export const deleteTacoFoodById = createAsyncThunk(
//   'nutridata/deleteTacoFoodById',
//   async (id, { rejectWithValue }) => {
//     try {
//       await deleteTacoFood(id);
//       return id;  // Retorna o ID deletado para poder removê-lo da lista
//     } catch (error) {
//       return rejectWithValue(error.message);
//     }
//   }
// );

// // Update a specific Taco food by ID
// export const updateTacoFoodById = createAsyncThunk(
//   'nutridata/updateTacoFoodById',
//   async ({ id, data }, { rejectWithValue }) => {
//     try {
//       const response = await updateTacoFood(id, data);
//       return response;  // Retornar o dado atualizado para substituir na lista
//     } catch (error) {
//       return rejectWithValue(error.message);
//     }
//   }
// );

// // Create a new Taco food
// export const createNewTacoFood = createAsyncThunk(
//   'nutridata/createNewTacoFood',
//   async (data, { rejectWithValue }) => {
//     try {
//       const response = await createTacoFood(data);
//       return response;  // Retornar o novo alimento criado
//     } catch (error) {
//       return rejectWithValue(error.message);
//     }
//   }
// );
