import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useParams, useNavigate } from 'react-router-dom';
import { fetchTacoFoodById } from '../../../redux/actions/nutridataActions';


import '../styles/ShowTacoFood.css';
import '../../../Mobile.css';

import GraphicFood from '../../../components/GraphicFood/GraphicFood';
import DynamicFoodDetails from './DynamicFoodDetails';
import foodLogoCategory from '../../../assets/img/shortcut_icon/allFoods.png';
import Accordion from '../../../components/Accordion/Accordion';
import { setCurrentTacoFood } from '../../../redux/slices/nutridataSlice';

const ShowTacoFood = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { food_id } = useParams();

  const { currentTacoFood, loading, error } = useSelector(state => state.nutridata);

  useEffect(() => {
    if (food_id) {
      dispatch(fetchTacoFoodById(food_id));
    }
  }, [dispatch, food_id]);

  if (loading) {
    return <div>Loading...</div>;
  }

  if (error) {
    return <div>Error: {error}</div>;
  }

  if (!currentTacoFood) {
    return <div>No data found for the specified food.</div>;
  }

  const handleBack = () => {
    navigate('/home/nutridata/get/atfd');
  };

  const dataForChart = [
    { id: 'Proteínas', label: 'Proteínas', value: currentTacoFood.protein || 0 },
    { id: 'Gorduras', label: 'Gorduras', value: currentTacoFood.total_saturated || 0 },
    { id: 'Carboidratos', label: 'Carboidratos', value: currentTacoFood.carbohydrates || 0 },
    { id: 'Lipídeos', label: 'Lipídeos', value: currentTacoFood.lipids || 0 },
  ];

  const calculatePercentual = (value) => {
    const total = currentTacoFood.protein + currentTacoFood.lipids + currentTacoFood.cholesterol + currentTacoFood.carbohydrates || 1;
    return ((value / total) * 100).toFixed(2);
  };


  return (
    <>
      <div className="tacoFoodCard">
        <div className="tacoFoodColumn1">
          <div className="row1">
            <div className="taco-colA">
              <img src={foodLogoCategory} alt="imagem do alimento" />
            </div>
            <div className="taco-colB">
              <label className='lbl-fruit-name'>{currentTacoFood.food_name}</label>
              <label className='lbl-category-food'>{currentTacoFood.category_name}</label>
            </div>
          </div>
          <div className="row2">
            <div className='tag-total'>Total</div>
            <div className='tag-kcal'>{currentTacoFood.kcal}</div>
            <div className='tag-calories'>Kcal</div>
          </div>
          <div className="row3">
            <div className='r3-describe'>Quantidade de calorias em cada 100g</div>
          </div>
        </div>

        <div className="tacoFoodColumn2">
          <GraphicFood data={dataForChart} />
        </div>

        <div className="tacoFoodColumn3">

          <div className="row boxLine">
            <div className="boxLabel">Proteínas</div>
            {/* <div className="boxPercentual">
              {calculatePercentual(currentTacoFood.protein)}%
            </div> */}
            <div className="boxValue proteins">
              {currentTacoFood.protein ?? "N/A"}
            </div>
          </div>
          <div className="row boxLine">
            <div className="boxLabel">Lipídeos</div>
            {/* <div className="boxPercentual">
              {calculatePercentual(currentTacoFood.lipids)}%
            </div> */}
            <div className="boxValue lipids">
              {currentTacoFood.lipids ?? "N/A"}
            </div>
          </div>
          <div className="row boxLine">
            <div className="boxLabel">Saturados</div>
            {/* <div className="boxPercentual">
              {calculatePercentual(currentTacoFood.saturated)}%
            </div> */}
            <div className="boxValue saturated">
              {currentTacoFood.saturated ?? "N/A"}
            </div>
          </div>
          <div className="row boxLine">
            <div className="boxLabel">Carboidratos</div>
            {/* <div className="boxPercentual">
              {calculatePercentual(currentTacoFood.carbohydrates)}%
            </div> */}
            <div className="boxValue carbohydrates">
              {currentTacoFood.carbohydrates ?? "N/A"}
            </div>
          </div>
        </div>
      </div>

      <Accordion className="accordion" title={`${currentTacoFood.food_name} - Informações Completas`} >
        <span />
        <DynamicFoodDetails food={currentTacoFood} />
      </Accordion>

      <div className="tacoFoodActions">
        <button type="button" className='backBtn' onClick={handleBack}>Voltar</button>
      </div>
    </>
  );
};

export default ShowTacoFood;