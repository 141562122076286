// src/redux/actions/personActions.js
import { createAsyncThunk } from '@reduxjs/toolkit';
import { getPerson, getPersonById, deletePerson, updatePerson, createPerson, getPersonList } from '../../service/api';

export const fetchPersons = createAsyncThunk(
  'persons/fetchPersons',
  async () => {
    const response = await getPerson();
    // console.log("O FetchPerson é:", JSON.stringify(response, null, 2));
    return response.data;
  }
);

export const fetchPersonById = createAsyncThunk(
  'persons/fetchPersonById',
  async (personIdToShow) => {
    const response = await getPersonById(personIdToShow);
    // console.log("O FetchPersonById é:", JSON.stringify(response, null, 2));
    return response.data;
  }
);

export const deletePersonById = createAsyncThunk(
  'persons/deletePersonById',
  async (id) => {
    await deletePerson(id);
    return id;
  }
);

export const updatePersonById = createAsyncThunk(
  'persons/updatePersonById',
  async ({ person_id, formData }) => {
    const response = await updatePerson(person_id, formData);
    return response.data;
  }
);

export const createNewPerson = createAsyncThunk(
  'persons/createNewPerson',
  async (formData) => {
    const response = await createPerson(formData);
    return response.data;
  });

export const fetchPersonList = createAsyncThunk(
  'persons/fetchPersonList',
  async () => {
    const response = await getPersonList();
    // console.log("O FetchPerson é:", JSON.stringify(response, null, 2));
    return response.data;
  }
);