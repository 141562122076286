import React from 'react'
import { useNavigate } from 'react-router-dom';



import '../css/Dashboard.css';
import '../../../Mobile.css';

function CardMenu({ imgSrc, label, navigateTo }) {


  const navigate = useNavigate();

  const handleLink = () => {
    navigate(navigateTo);
  }



  return (
    <>

      <div className="groupBtn">
        <a className='shortcutBtn' onClick={handleLink}>
          <div>
            <img src={imgSrc} alt={`${label} icon`} />
          </div>
          <label>{label}</label>
        </a>
      </div>

      <div className="groupBtnMobile">
        <div className="card-mobile">
          <a className='shortcutBtn' onClick={handleLink}>
            <div>
              <img src={imgSrc} alt={`${label} icon`} />
            </div>
            <label>{label}</label>
          </a>
        </div>
        <div className="mobile-label">{label}</div>
      </div>
    </>

  );
}

export default CardMenu