import React, { useState } from 'react';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import { createPerson } from '../../../service/api';
import ModalForms from '../../../components/ConfirmationModal/ModalForms';
import { Tabs, Tab } from '../../../components/Tabs/Tabs';
import InputMask from 'react-input-mask';
import { useNavigate } from 'react-router-dom';

import '../styles/CreatePerson.css';
import '../styles/RBStyle.css';
import '../styles/Avatar.css';

function CreatePerson() {
  const navigate = useNavigate();
  const [selectedLogo, setSelectedLogo] = useState('/avatar.png');
  const [file, setFile] = useState(null);
  const [message, setMessage] = useState('');
  const [showModal, setShowModal] = useState(false);
  const [errors, setErrors] = useState({});
  let percentage = 0;

  const setImgFile = (e, setFieldValue) => {
    const file = e.target.files[0];
    setFile(file);
    setFieldValue(file);

    if (file) {
      const reader = new FileReader();
      reader.onloadend = () => {
        setSelectedLogo(reader.result);
      };
      reader.readAsDataURL(file);
    }
  };

  const initialValues = {
    first_name: '',
    last_name: '',
    email: '',
    email2: '',
    phone: '',
    phone2: '',
    mobile: '',
    mobile2: '',
    birth_date: '',
    gender: '',
    person_type: '',
    status: 'active',
    group_id: '1',
    cpf: '',
    rg: '',
    rgi: '',
    ro: '',
    ro_number: '',
    speciality: '',
    cnh: '',
    cnh_category: '',
    naturalness: '',
    marital_status: '',
    nationality: '',
    religion: '',
    social_media1: '',
    social_media2: '',
    social_media3: '',
    observations: '',
    address: {
      zipcode: '',
      street: '',
      number: '',
      neighborhood: '',
      city: '',
      uf: ''
    },
    avatar: null // Campo para o arquivo de avatar
  };

  const validationSchema = Yup.object({
    first_name: Yup.string().required('Primeiro nome é necessário'),
    last_name: Yup.string().required('Sobrenome é necessário'),
    email: Yup.string().email('Email inválido').required('Email é necessário'),
    birth_date: Yup.date()
      .max(new Date(), 'A data de nascimento não pode ser futura.')
      .required('Data de nascimento é necessária'),
    gender: Yup.string().required('Gênero é necessário'),
    person_type: Yup.string().required('Tipo de pessoa é necessário'),
  });

  const handleSubmit = async (values, { setSubmitting, resetForm }) => {
    try {
      const formData = new FormData();
      for (const key in values) {
        if (key === "address") {
          for (const addrKey in values[key]) {
            formData.append(`address[${addrKey}]`, values[key][addrKey]);
          }
        } else {
          formData.append(key, values[key]);
        }
      }
      if (file) {
        formData.append("avatar", file);
      }
      console.log("Form data:", formData);
      const response = await createPerson(formData);
      setMessage(response.message || "Pessoa criada com sucesso!");
      setShowModal(true);
      setTimeout(() => {
        setShowModal(false);
        resetForm();
        navigate('/home/person/get');
      }, 2000);
    } catch (error) {
      console.error("Erro ao enviar formulário:", error);
      const errorMsg = (error.response && error.response.data && error.response.data.message)
        ? error.response.data.message
        : 'Erro ao enviar formulário. Tente novamente mais tarde.';
      setMessage(errorMsg);
      setShowModal(true);
      setTimeout(() => {
        setShowModal(false);
      }, 2500);
      setErrors({ submit: errorMsg });
    }

    setSubmitting(false);
  };

  const handleKeyDown = (e) => {
    if (e.key === 'Enter') {
      e.preventDefault();
    }
  };
  const handleBack = (e) => {
    e.preventDefault();
    navigate('/home/person/get');
  }

  return (
    <div>
      <h3>Nova Pessoa</h3>
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={handleSubmit}
      >
        {({ setFieldValue, isSubmitting }) => (
          <Form className='personForm' encType="multipart/form-data" onKeyDown={handleKeyDown}>
            <div className="personMain">
              <div className="colA">
                <div className="silhuette">
                  <div className="row">
                    <div>
                      <input
                        id="avatar"
                        className="photo"
                        type="file"
                        name="avatar"
                        accept="image/*"
                        onChange={(e) => setImgFile(e, setFieldValue)}
                      />
                      <div>
                        <img id="avatarImg" className="cadphoto" src={selectedLogo} alt="Avatar" />
                      </div>
                    </div>
                    <div className="photo">
                      <label htmlFor="avatar" className="photo">
                        <img className="photo" src="/apple.ico" alt="Upload Avatar" />
                      </label>
                    </div>
                  </div>
                </div>
              </div>
              <div className="colB">
                <Tabs>
                  <Tab label="Pessoa Fisica">
                    {/* Inserir componentes de formulário aqui */}
                    <div className="person-form-group">
                      <div className="trSelects">
                        <div className="col1">
                          <label htmlFor="person_type">Tipo de Pessoa:</label>
                          <Field as="select"
                            name="person_type"
                            id="person_type"
                          >
                            <option value="">Selecione</option>
                            <option value="patient">Paciente</option>
                            <option value="nutritionist">Nutricionista</option>
                            <option value="doctor">Médico</option>
                            <option value="professional">Profissional</option>
                            <option value="user">Usuário</option>
                            <option value="employee">Funcionário</option>
                            <option value="supplier">Fornecedor</option>
                            <option value="partner">Parceiro</option>
                            <option value="owner">Proprietário</option>
                            <option value="other">Outro</option>
                          </Field>
                          <ErrorMessage name="person_type" component="div" className="error-message" />
                        </div>
                        <div className="col2">
                          <label htmlFor="status">Status:</label>
                          <Field as="select"
                            name="status"
                            id="status"
                            defaultValue="active"
                          >
                            <option value="">Selecione</option>
                            <option value="active">Ativo</option>
                            <option value="inactive">Inativo</option>
                          </Field>
                          <ErrorMessage name="status" component="div" className="error-message" />
                        </div>
                        <div className="col3">
                          <label htmlFor="group_id">Tipo de Grupo:</label>
                          <Field as="select"
                            name="group_id"
                            id="group_id"
                            defaultValue="1"
                          >
                            <option value="">Selecione</option>
                            <option value="1">1</option>
                            <option value="2">2</option>
                          </Field>
                          <ErrorMessage name="group_id" component="div" className="error-message" />
                        </div>
                      </div>
                    </div>

                    <div className="person-form-group">
                      <label htmlFor="first_name">Primeiro Nome:</label>
                      <Field
                        type="text"
                        name="first_name"
                        id="first_name"
                        placeholder="Digite o primeiro nome"
                      />
                      <ErrorMessage name="first_name" component="div" className="error-message" />
                    </div>


                    <div className="person-form-group">
                      <label htmlFor="last_name">Sobrenome:</label>
                      <Field
                        type="text"
                        name="last_name"
                        id="last_name"
                        placeholder="Digite o sobrenome"
                      />
                      <ErrorMessage name="last_name" component="div" className="error-message" />
                    </div>

                    <div className="person-form-group">
                      <label htmlFor="email">Email principal:</label>
                      <Field
                        type="text"
                        name="email"
                        id="email"
                        placeholder="Digite o email"
                      />
                      <ErrorMessage name="email" component="div" className="error-message" />
                    </div>

                    <div className="person-form-group">
                      <label htmlFor="date_birth">Data de Nascimento:</label>
                      <Field
                        type="date"
                        name="birth_date"
                        id="birth_date"
                      />
                      <ErrorMessage name="birth_date" component="div" className="error-message" />
                    </div>

                    <div className="person-form-group">
                      <label htmlFor="gender">Gênero:</label>

                      <div className="rbstyle">

                        <Field id="female" type="radio" name="gender" value="female" />
                        <label htmlFor="female">Feminino</label>

                        <Field id="male" type="radio" name="gender" value="male" />
                        <label htmlFor="male">Masculino</label>

                        <Field id="others" type="radio" name="gender" value="others" />
                        <label htmlFor="others">Outros</label>
                      </div>
                      <ErrorMessage name="gender" component="div" className="error-message" />
                    </div>

                    <div className="person-form-group">
                      <div className="trSelects">
                        <div className="pfgCol1">
                          <label htmlFor="cpf">CPF:</label>
                          <Field name="cpf">
                            {({ field }) => (
                              <InputMask
                                {...field}
                                mask="999.999.999-99"
                                placeholder="Digite o CPF"
                                onChange={(e) => {
                                  setFieldValue("cpf", e.target.value);
                                }}
                              />
                            )}
                          </Field>
                        </div>
                        <div className="pfgCol2">
                          <label htmlFor="rg">RG:</label>
                          <Field
                            type="text"
                            name="rg"
                            id="rg"
                            placeholder="Documento R.G"
                          />
                          <ErrorMessage name="rg" component="div" className="error-message" />
                        </div>
                        <div className="pfgCol3">
                          <label htmlFor="rgi">RGI</label>
                          <Field
                            type="text"
                            name="rgi"
                            id="rgi"
                            placeholder="Documento R.G.I"
                          />
                          <ErrorMessage name="rgi" component="div" className="error-message" />
                        </div>
                      </div>
                    </div>

                    <div className="person-form-group">
                      <div className="trSelects">
                        <div className="pfgCol1">
                          <label htmlFor="cnh">CNH nº:</label>
                          <Field
                            type="text"
                            name="cnh"
                            id="cnh"
                            placeholder="C.N.H"
                          />
                          <ErrorMessage name="cnh" component="div" className="error-message" />
                        </div>
                        <div className="pfgCol2">
                          <label htmlFor="cnh_category">CNH Categoria:</label>
                          <Field as="select"
                            name="cnh_category"
                            id="cnh_category"
                          >
                            <option value="">Selecione</option>
                            <option value="A">A</option>
                            <option value="B">B</option>
                            <option value="C">C</option>
                            <option value="D">D</option>
                            <option value="E">E</option>
                          </Field>
                          <ErrorMessage name="cnh_category" component="div" className="error-message" />
                        </div>
                        <div className="pfgCol3">
                        </div>
                      </div>
                    </div>

                    <div className="person-form-group">
                      <div className="trSelects">
                        <div className="pfgCol1">
                          <label htmlFor="ro_number">R.O nº:</label>
                          <Field
                            type="text"
                            name="ro_number"
                            id="ro_number"
                            placeholder="Registro de Ordem"
                          />
                          <ErrorMessage name="ro_number" component="div" className="error-message" />
                        </div>
                        <div className="pfgCol2">
                          <label htmlFor="ro">Registro Profissional da Ordem:</label>
                          <Field as="select"
                            name="ro"
                            id="ro"
                          >
                            <option value="">Selecione</option>
                            <option value="CRN">CRN - Conselho Regional de Nutricionistas</option>
                            <option value="CFN">CFN - Conselho Federal de Nutricionistas</option>
                            <option value="CRM">CRM - Conselho de Medicina</option>
                            <option value="CRE">Conselho de Enfermagem</option>
                            <option value="COFEN">COFEN - Conselho Federal de Enfermagem</option>
                            <option value="COREN-SP">COREN-SP</option>
                          </Field>
                          <ErrorMessage name="ro" component="div" className="error-message" />
                        </div>
                        <div className="pfgCol3">
                          <label htmlFor="speciality">Especialidade:</label>
                          <Field as="select"
                            name="speciality"
                            id="speciality"
                          >
                            <option value="">Selecione</option>
                            <option value="nutrition">Nutricionista</option>
                            <option value="general_doctor">Clínico Geral</option>
                            <option value="physiotherapist">Fisioterapeuta</option>
                          </Field>
                          <ErrorMessage name="ro" component="div" className="error-message" />
                        </div>
                      </div>
                    </div>
                  </Tab>

                  <Tab label="Contatos">
                    {/* Campos de contato */}
                    <div className="person-form-group">
                      <div className="trSelects">
                        <div className="pfgCol1">
                          <label htmlFor="email2">Email Secundário:</label>
                          <Field
                            type="email"
                            name="email2"
                            id="email2"
                            placeholder="Digite o email secundário"
                          />
                          <ErrorMessage name="email2" component="div" className="error-message" />
                        </div>
                        <div className="pfgCol2">
                        </div>
                        <div className="pfgCol3">
                        </div>
                      </div>
                    </div>

                    <div className="person-form-group">
                      <div className="trSelects">
                        <div className="pfgCol1">
                          <label htmlFor="phone">Telefone:</label>
                          <Field name="phone">
                            {({ field }) => (
                              <InputMask
                                {...field}
                                mask="(99) 99999-9999"
                                placeholder="Digite o telefone"
                                onChange={(e) => {
                                  setFieldValue("phone", e.target.value);
                                }}
                              />
                            )}
                          </Field>
                          <ErrorMessage name="phone" component="div" className="error-message" />
                        </div>
                        <div className="pfgCol2">
                          <label htmlFor="phone2">Telefone Secundário:</label>
                          <Field name="phone2">
                            {({ field }) => (
                              <InputMask
                                {...field}
                                mask="(99) 99999-9999"
                                placeholder="Digite o telefone secundário"
                                onChange={(e) => {
                                  setFieldValue("phone2", e.target.value);
                                }}
                              />
                            )}
                          </Field>
                          <ErrorMessage name="phone2" component="div" className="error-message" />
                        </div>
                        <div className="pfgCol3">
                        </div>
                      </div>
                    </div>

                    <div className="person-form-group">
                      <div className="trSelects">
                        <div className="pfgCol1">
                          <label htmlFor="mobile">Celular:</label>
                          <Field name="mobile">
                            {({ field }) => (
                              <InputMask
                                {...field}
                                mask="(99) 99999-9999"
                                placeholder="Digite o celular"
                                onChange={(e) => {
                                  setFieldValue("mobile", e.target.value);
                                }}
                              />
                            )}
                          </Field>
                          <ErrorMessage name="mobile" component="div" className="error-message" />
                        </div>
                        <div className="pfgCol2">
                          <label htmlFor="mobile2">Celular Secundário:</label>
                          <Field name="mobile2">
                            {({ field }) => (
                              <InputMask
                                {...field}
                                mask="(99) 99999-9999"
                                placeholder="Digite o celular secundário"
                                onChange={(e) => {
                                  setFieldValue("mobile2", e.target.value);
                                }}
                              />
                            )}
                          </Field>
                          <ErrorMessage name="mobile2" component="div" className="error-message" />
                        </div>
                        <div className="pfgCol3">
                        </div>
                      </div>
                    </div>

                    <div className="person-form-group">
                      <div className="trSelects">
                        <div className="pfgCol1">
                          <label htmlFor="social_media1">Facebook</label>
                          <Field
                            type="text"
                            name="social_media1"
                            id="social_media1"
                            placeholder="Facebook"
                          />
                          <ErrorMessage name="social_media1" component="div" className="error-message" />
                        </div>
                        <div className="pfgCol2">
                          <label htmlFor="social_media2">Instagram</label>
                          <Field
                            type="text"
                            name="social_media2"
                            id="social_media2"
                            placeholder="Instagram"
                          />
                          <ErrorMessage name="social_media2" component="div" className="error-message" />
                        </div>
                        <div className="pfgCol3">
                          <label htmlFor="social_media3">X (twitter)</label>
                          <Field
                            type="text"
                            name="social_media3"
                            id="social_media3"
                            placeholder="X"
                          />
                          <ErrorMessage name="social_media3" component="div" className="error-message" />
                        </div>
                      </div>
                    </div>

                    <div className="person-form-group">
                      <Field as="textarea"
                        name="observations"
                        id="observations"
                        placeholder="Obs:"
                        rows={4} // Defina o número de linhas inicial do textarea
                      />
                      <ErrorMessage name="observations" component="div" className="error-message" />
                    </div>
                  </Tab>

                  <Tab label="Endereço">
                    {/* Campos de endereço */}
                    <div className="person-form-group">
                      <label htmlFor="address.zipcode">CEP:</label>
                      <Field name="address.zipcode">
                        {({ field }) => (
                          <InputMask
                            {...field}
                            id="address.zipcode"
                            name="address.zipcode"
                            mask="99999-999"
                            placeholder="Digite o CEP"
                            onChange={(e) => {
                              setFieldValue("address.zipcode", e.target.value);
                            }}
                          />
                        )}
                      </Field>
                    </div>

                    <div className="person-form-group">
                      <label htmlFor="address.street">Rua:</label>
                      <Field
                        type="text"
                        name="address.street"
                        id="address.street"
                        placeholder="Digite a rua"
                      />
                      <ErrorMessage name="address.street" component="div" className="error-message" />
                    </div>

                    <div className="person-form-group">
                      <label htmlFor="address.number">Número</label>
                      <Field type="text"
                        name="address.number"
                        id="address.number"
                        placeholder="Digite o número"
                      />
                      <ErrorMessage name="address.number" component="div" className="error-message" />
                    </div>

                    <div className="person-form-group">
                      <label htmlFor="address.complement">Complemento:</label>
                      <Field
                        type="text"
                        name="address.complement"
                        id="address.complement"
                        placeholder="Digite o complemento"
                      />
                    </div>

                    <div className="person-form-group">
                      <label htmlFor="address.neighborhood">Bairro:</label>
                      <Field
                        type="text"
                        name="address.neighborhood"
                        id="address.neighborhood"
                        placeholder="Digite o bairro"
                      />
                    </div>

                    <div className="person-form-group">
                      <label htmlFor="address.city">Cidade:</label>
                      <Field
                        type="text"
                        name="address.city"
                        id="address.city"
                        placeholder="Digite a cidade"
                      />
                    </div>

                    <div className="person-form-group">
                      <label htmlFor="address.uf">UF:</label>
                      <Field as="select"
                        name="address.uf"
                        id="address.uf"
                      >
                        <option value="">Selecione</option>
                        <option value="AC">Acre - AC</option>
                        <option value="AL">Alagoas - AL</option>
                        <option value="AM">Amazonas - AM</option>
                        <option value="AP">Amapá - AP</option>
                        <option value="BA">Bahia - BA</option>
                        <option value="CE">Ceará - CE</option>
                        <option value="DF">Distrito Federal - DF</option>
                        <option value="ES">Espírito Santo - ES</option>
                        <option value="GO">Goiás - GO</option>
                        <option value="MA">Maranhão - MA</option>
                        <option value="MG">Minas Gerais - MG</option>
                        <option value="MS">Mato Grosso do Sul - MS</option>
                        <option value="MT">Mato Grosso - MT</option>
                        <option value="PA">Pará - PA</option>
                        <option value="PB">Paraíba - PB</option>
                        <option value="PE">Pernambuco - PE</option>
                        <option value="PI">Piauí - PI</option>
                        <option value="PR">Paraná - PR</option>
                        <option value="RJ">Rio de Janeiro - RJ</option>
                        <option value="RN">Rio Grande do Norte - RN</option>
                        <option value="RO">Rondônia - RO</option>
                        <option value="RR">Roraima - RR</option>
                        <option value="RS">Rio Grande do Sul - RS</option>
                        <option value="SC">Santa Catarina - SC</option>
                        <option value="SE">Sergipe - SE</option>
                        <option value="SP">São Paulo - SP</option>
                        <option value="TO">Tocantins - TO</option>
                      </Field>
                    </div>
                  </Tab>
                </Tabs>
              </div>

              <div className="colC">
                {/* <p>Força do perfil</p>
                <p>{percentage}</p> */}
                <ErrorMessage name="person_type" component="div" className="spanMessage" />
                <ErrorMessage name="first_name" component="div" className="spanMessage" />
                <ErrorMessage name="last_name" component="div" className="spanMessage" />
                <ErrorMessage name="email" component="div" className="spanMessage" />
                <ErrorMessage name="birth_date" component="div" className="spanMessage" />
                <ErrorMessage name="gender" component="div" className="spanMessage" />
                {errors.first_name && <div className="spanMessage">{errors.first_name}</div>}
                <div className="personButtons">

                  <div><button className='sendBtn' type='submit'>Enviar</button></div>
                  <div><button className='backBtn' onClick={handleBack}>Cancelar</button></div>

                </div>
              </div>
            </div>
          </Form>
        )}
      </Formik>
      {showModal && (
        <ModalForms
          message={message}
          onConfirm={() => setShowModal(false)}
        />
      )}
    </div>
  );
}

export default CreatePerson;
