import React, { useState } from 'react';

const Tabs = ({ children }) => {
  const [activeTab, setActiveTab] = useState(children[0].props.label);

  const handleClick = (label) => {
    setActiveTab(label);
  };

  return (
    <div>
      <ul className="tabs">
        {children.map((tab) => (
          <li
            key={tab.props.label}
            className={tab.props.label === activeTab ? 'active' : ''}
            onClick={() => handleClick(tab.props.label)}
          >
            {tab.props.label}
          </li>
        ))}
      </ul>
      <div className="tab-content">
        {children.map((content) => {
          if (content.props.label === activeTab) return <div key={content.props.label}>{content.props.children}</div>;
          else return null;
        })}
      </div>
    </div>
  );
};

const Tab = ({ children }) => {
  return <>{children}</>;
};

export { Tabs, Tab };
