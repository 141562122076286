// src/redux/reducers/appointmentReducer.js
import { createSlice } from '@reduxjs/toolkit';
import { fetchAppointments, fetchAppointmentById, fetchAppointmentByPersonId, deleteAppointmentById, updateAppointmentById, createNewAppointment } from '../actions/appointmentsActions';

const appointmentSlice = createSlice({
  name: 'appointments',
  initialState: {
    appointments: [],
    loading: false,
    error: null,
    currentAppointment: null,
  },
  reducers: {
    setCurrentAppointment: (state, action) => {
      state.currentAppointment = action.payload;
    },
    clearAppointment: (state) => {
      state.appointments = [];
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchAppointments.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(fetchAppointments.fulfilled, (state, action) => {
        state.loading = false;
        state.appointments = Array.isArray(action.payload) ? action.payload : [];
      })
      .addCase(fetchAppointments.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      })
      .addCase(fetchAppointmentById.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(fetchAppointmentById.fulfilled, (state, action) => {
        state.loading = false;
        state.currentAppointment = action.payload; // Atualiza currentAppointment, não appointments
      })
      .addCase(fetchAppointmentById.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      })
      .addCase(fetchAppointmentByPersonId.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(fetchAppointmentByPersonId.fulfilled, (state, action) => {
        state.loading = false;
        state.appointments = action.payload;
      })
      .addCase(fetchAppointmentByPersonId.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      })
      .addCase(deleteAppointmentById.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(deleteAppointmentById.fulfilled, (state, action) => {
        state.loading = false;
        state.appointments = state.appointments.filter(appointment => appointment.appointment_id !== action.payload);
      })
      .addCase(deleteAppointmentById.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      })
      .addCase(updateAppointmentById.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(updateAppointmentById.fulfilled, (state, action) => {
        state.loading = false;
        // Verifica se o payload existe e contém os dados do agendamento atualizado
        if (action.payload && action.payload.appointment_id) {
          state.appointments = state.appointments.map(appointment =>
            appointment.appointment_id === action.payload.appointment_id ? action.payload : appointment
          );
        } else {
          // Se não houver payload, apenas exibimos a mensagem de sucesso
          state.message = "Agendamento atualizado com sucesso!";
        }
      })
      .addCase(updateAppointmentById.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      })
      .addCase(createNewAppointment.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(createNewAppointment.fulfilled, (state, action) => {
        state.loading = false;
        state.appointments.push(action.payload);
      })
      .addCase(createNewAppointment.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      });
  },
});

export const { setCurrentAppointment, clearAppointment } = appointmentSlice.actions;
export default appointmentSlice.reducer;