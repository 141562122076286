import React, { useState, useEffect, useContext, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import { AuthContext } from '../../context/auth';

import './styles/ResetPassword.css';

import bckgVideo from '../../assets/video/lemons.mp4';
import fallbackVideo from '../../assets/video/water.mp4';
import fallbackImg from '../../assets/img/fallback.png';
import fallbackMobileImg from '../../assets/img/ban1.png';


const ResetPassword = () => {
  const { resetPassword } = useContext(AuthContext);

  const [email, setEmail] = useState('');
  const [tempPassword, setTempPassword] = useState('');
  const [newPassword, setNewPassword] = useState('');
  const [confirmNewPassword, setConfirmNewPassword] = useState('');
  const [message, setMessage] = useState('');
  const navigate = useNavigate();

  const [loading, setLoading] = useState(false);
  const [ellipsis, setEllipsis] = useState('.....');

  const videoRef = useRef();
  const [videoError, setVideoError] = useState(false);
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);

  const handleVideoError = () => {
    setVideoError(true);
  };


  const handleSubmit = async (e) => {
    e.preventDefault();

    if (newPassword !== confirmNewPassword) {
      setMessage('As novas senhas não coincidem');
      return;
    }

    setLoading(true);

    try {
      const response = await resetPassword(email, tempPassword, newPassword, confirmNewPassword);
      if (response && response.status === 200) {
        setMessage('Senha atualizada com sucesso!');
        setTimeout(() => {
          navigate('/');
        }, 3000); // Redireciona após 3 segundos
      } else {
        setMessage(response.data.message);
      }
    } catch (error) {
      if (error.response && error.response.data) {
        setMessage(error.response.data);
      } else {
        setEmail('');
        setTempPassword('');
        setNewPassword('');
        setConfirmNewPassword('');
        setMessage('Senha atualizada com sucesso!');
        setTimeout(() => {
          navigate('/');
        }, 3000);
      }
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    let interval;
    if (loading) {
      let count = 5;
      interval = setInterval(() => {
        setEllipsis('.'.repeat(count));
        count = count === 1 ? 5 : count - 1;
      }, 500);
    } else {
      setEllipsis('.....');
    }

    return () => clearInterval(interval);
  }, [loading]);

  useEffect(() => {
    if (message) {
      const timer = setTimeout(() => {
        setMessage('');
      }, 3000);
      return () => clearTimeout(timer);
    }
  }, [message]);

  return (
    <div className='resset-container'>
      <div className="ressetVideoBox">
        {isMobile ? (
          <img src={fallbackMobileImg} className="loginFallbackImg" alt="Fallback Mobile Image" />
        ) : !videoError ? (
          <video ref={videoRef} muted autoPlay loop onError={handleVideoError}>
            <source src={bckgVideo} type="video/mp4" onError={() => videoRef.current.src = fallbackVideo} />
            <source src={fallbackVideo} type="video/mp4" />
          </video>
        ) : (
          <img src={fallbackImg} className="loginFallbackImg" alt="Fallback Image" />
        )}
      </div>
      <div className="ressetBox">
        <span className='spanReset'>Redefinir Senha</span>

        {loading && <div className="message"> Aguarde{ellipsis}</div>}
        {message && <div className="message"> {message} </div>}

        <form onSubmit={handleSubmit}>
          <div className='ressetBoxBlock'>
            <label className='form-label'>Email</label>
            <input type="email" value={email} name='email' onChange={(e) => setEmail(e.target.value)} required />
          </div>
          <div className='ressetBoxBlock'>
            <label className='form-label'>Senha Provisória</label>
            <input type="password" value={tempPassword} name='tempPassword' onChange={(e) => setTempPassword(e.target.value)} required />
          </div>
          <div className='ressetBoxBlock'>
            <label className='form-label'>Nova Senha</label>
            <input type="password" value={newPassword} name='newPassword' onChange={(e) => setNewPassword(e.target.value)} required />
          </div>
          <div className='ressetBoxBlock'>
            <label className='form-label'>Confirmar Nova Senha</label>
            <input type="password" value={confirmNewPassword} name='confirmNewPassword' onChange={(e) => setConfirmNewPassword(e.target.value)} required />
          </div>
          <div className="ressetBtn">
            <button className="form-btn btn ressetBtn"
              variant="primary"
              type="submit">Redefinir Senha</button>
          </div>
          <div className="exitLink">
            <a href="/">sair</a>
          </div>
        </form>
        <div className="messageReset">
        </div>
      </div>
      <div className="footerLogin">
        <div className='year'>2007 - <span id="current-year"></span></div>
        <div> &copy; todos os direitos reservados </div>
        <div>powered by <span className='brand'>apit solutions</span></div>
      </div>
    </div>
  );
};

export default ResetPassword;
