import React from 'react';
import './styles/ConfirmationModal.css';

function ConfirmationModal({ message, onConfirm, onCancel, isDeleting }) {
  return (
    <div className="modal-overlay">
      <div className="modal">
        <div className="modal-content">
          <p>{message}</p>
          <div className="modalButtons">
            <button className='modalBtn' onClick={onConfirm} disabled={isDeleting}>
              {isDeleting ? 'Deletando...' : 'Sim'}
            </button>
            {!isDeleting && (
              <button className='modalBtnCancel' onClick={onCancel}>
                Não
              </button>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}

export default ConfirmationModal;
