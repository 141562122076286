import React from 'react';


import Login from '../Login/login';

const Welcome = () => {

  return (
    <>
      <Login />
    </>

  );
};

export default Welcome;
