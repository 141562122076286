import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import './styles/SearchBar.css';

import { MdHome } from 'react-icons/md';

function SearchBar({ onSearch }) {
  const [searchText, setSearchText] = useState('');
  const navigate = useNavigate();

  const returnBtn = "Retornar";

  const handleChange = (event) => {
    setSearchText(event.target.value);
    onSearch(event.target.value); // Chama onSearch sempre que o texto mudar
  };


  const handleBack = (e) => {
    e.preventDefault();
    navigate('/home/dashboard');
  }

  const handleSubmit = (event) => {
    event.preventDefault();
    onSearch(searchText);
  };

  return (
    <>
      <form onSubmit={handleSubmit} className="userSearchForm">
        <input
          type="text"
          name="search"
          className="userSearchInput"
          value={searchText}
          onChange={handleChange}
          placeholder="Digite aqui sua pesquisa"
        />

        <div className="formButtons">
          <button className="searchBtn" type="submit">Buscar</button>
          {/* <button className='backBtn' onClick={handleBack}>Cancelar</button> */}
          <button type="button" className='returnDashboard' onClick={handleBack}
            data-tip={returnBtn}><MdHome /></button>
        </div>
      </form>
    </>
  );
}

export default SearchBar;
